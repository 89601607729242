import React, { useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { recentDealModalState } from "../../../recoil/atoms/modal";
import ModalWrapper from "../ModalWrapper";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-icon.svg";
import useSWR from "swr";
import { useParams } from "react-router-dom";
import { useProductDetail } from "../../../providers/ProductDetailProvider";
import ProductSummaryInfo from "../../detail/ProductSummaryInfo";
import SelectTab from "../../detail/SelectTab";
import { convertListVariables } from "../../../utils/utils";
import { CARD_TYPE } from "../../../utils/variables";
import ProductRecentDeal from "../../detail/ProductRecentDeal";
import useCommonList from "../../../hooks/useCommonList";
import Target from "../../common/Target";

function RecentDealModal() {
  const { cardId } = useParams();
  const [selectedType, setSelectedType] = useState("all");

  const { data, target, isLoading } = useCommonList({
    url: `/card/recent/deal?card_id=${cardId}&is_detail=1&${selectedType != 'all' ? '&type=' + selectedType : ''}`,
    key: "orders",
  });

  const { card, card_images } = useProductDetail();

  const summaryImageUrl = useMemo(() => {
    if ((card_images || []).length < 1) return undefined;
    return card_images[0].image_url;
  }, [card_images]);

  const setModal = useSetRecoilState(recentDealModalState);

  const handleChangeType = (type: string) => {
    setSelectedType(type);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <ModalWrapper position={"bottom"} onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <div className="title">최근 거래</div>
          <div className="close-icon" onClick={handleClose}>
            <CloseIcon />
          </div>
        </div>

        <div className="container">
          <div className="card-info">
            <ProductSummaryInfo card={card} imageUrl={summaryImageUrl} />
          </div>
          <div className="select-tab-wrapper">
            <SelectTab
              selectedValue={selectedType}
              list={convertListVariables(CARD_TYPE)}
              onChange={handleChangeType}
            />
          </div>
          <ProductRecentDeal
            products={data?.orders?.data || []}
            isLoading={isLoading}
          />
          <Target target={target} />
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-height: 90%;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 1);

  border-radius: 20px 20px 0 0;

  min-height: 500px;

  & .header {
    position: relative;
    height: 54px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;

    color: rgba(30, 30, 30, 1);
  }

  & .close-icon {
    position: absolute;
    right: 20px;
  }

  & .card-info {
    padding: 16px 20px;
  }

  & .container {
    padding: 0 20px;
  }

  & .select-tab-wrapper {
    border-top: 1px solid rgba(231, 231, 231, 1);
  }
`;

export default RecentDealModal;
