import React, {useEffect} from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { refundModalState, RefundSubmitType } from "../../recoil/atoms/modal";
import { paymentPayloadState } from "../../recoil/atoms/payment";
import {convertListVariables, isAndroidNew, isiOSAppNew, isiOSNew} from "../../utils/utils";
import { BANK_VARIABLES, PAYMENT_METHOD } from "../../utils/variables";
import { ReactComponent as LogoTossPay } from "../../assets/icons/logo-toss-pay.svg";
import { isAndroid, isIOS } from "../../utils/utils";
import useUser from "../../hooks/useUser";
import {alertModalState} from "../../utils/atom";

interface SelectPaymentMethodProps {}

function SelectPaymentMethod({}: SelectPaymentMethodProps) {
  const [payload, setPayload] = useRecoilState(paymentPayloadState);
  const setRefundModal = useSetRecoilState(refundModalState);
  const setAlertModal = useSetRecoilState(alertModalState);

  const { user } = useUser();
  const handleChagneMethod = (value: string) => () => {
    setPayload((old) => ({
      ...old,
      method: value,
    }));
  };

  const handleClickRegisterRefund = () => {
    const onSubmit: RefundSubmitType = (values) => {
      setPayload((old) => ({
        ...old,
        refundInfo: values,
      }));
    };
    setRefundModal({
      onSubmit,
      defaultValue: payload.refundInfo && payload.refundInfo,
    });
  };

  const receiveAppVersion = (versionInfo : string) => {
    if(versionInfo == 'need'){
      setAlertModal({
        title: "업데이트 필요",
        text: "안정적인 서비스 사용을 위해 최신 버전으로 업데이트를 해주세요!",
        onConfirm: () =>
            window.location.href = "https://apps.apple.com/kr/app/%ED%8B%B0%ED%94%84/id6471943690",
        onClose: () =>
            window.location.href = "https://apps.apple.com/kr/app/%ED%8B%B0%ED%94%84/id6471943690",
      });
    }
  };

  useEffect(() => {
    if(isiOSNew()) {
      if (isiOSAppNew()) {
        try {
          // @ts-ignore
          window.webkit.messageHandlers.version2.postMessage(null);
          window.receiveAppVersion = receiveAppVersion;
        }catch (e){
          setAlertModal({
            title: "업데이트 필요",
            text: "안정적인 서비스 사용을 위해 최신 버전으로 업데이트를 해주세요!",
            onConfirm: () =>
                window.location.href = "https://apps.apple.com/kr/app/%ED%8B%B0%ED%94%84/id6471943690",
            onClose: () =>
                window.location.href = "https://apps.apple.com/kr/app/%ED%8B%B0%ED%94%84/id6471943690",
          });
        }
      }
    }
  }, []);

  return (
    <Wrapper>
      <h4 className="title">결제 수단 선택</h4>
      <div className="method-wrapper mt-14">
{/*        {(isiOSNew() && user?.user_id != 2) && (
            convertListVariables(PAYMENT_METHOD).filter(value => value.value != 'tosspay').map(({ value, label }) => (
                <div
                    className={`method-button ${
                        value === payload.method ? "active" : ""
                    }`}
                    key={value}
                    onClick={handleChagneMethod(value)}
                >
                  {value == 'tosspay' ? <LogoTossPay/> : label}
                </div>
            ))
        )
        }
        {(!isiOSNew() || user?.user_id == 2) && (
            convertListVariables(PAYMENT_METHOD).map(({ value, label }) => (
                <div
                    className={`method-button ${
                        value === payload.method ? "active" : ""
                    }`}
                    key={value}
                    onClick={handleChagneMethod(value)}
                >
                  {value == 'tosspay' ? <LogoTossPay/> : label}
                </div>
            ))
        )
        }*/}

        {convertListVariables(PAYMENT_METHOD).map(({ value, label }) => (
            <div
                className={`method-button ${
                    value === payload.method ? "active" : ""
                }`}
                key={value}
                onClick={handleChagneMethod(value)}
            >
              {value == 'tosspay' ? <LogoTossPay/> : label}
            </div>
        ))}
      </div>
      {payload.method === "VBANK" && (
        <div className="refind-wrapper mt-16">
          <span className="refund-label">환불계좌</span>
          <div className="refund-value-wrapper">
            {payload.refundInfo?.account &&
              payload.refundInfo?.bank &&
              payload.refundInfo?.owner && (
                <span className="refund-value">
                  <span>{BANK_VARIABLES[payload.refundInfo.bank]}</span>{" "}
                  <span>{payload.refundInfo.account}</span>{" "}
                  <span>({payload.refundInfo.owner})</span>
                </span>
              )}
          </div>
          <span
            className="refund-register-button"
            onClick={handleClickRegisterRefund}
          >
            {payload.refundInfo ? "변경" : "등록"}
          </span>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 26px;

  & .method-wrapper {
    display: flex;
    gap: 10px;
  }

  & .method-button {
    width: 100%;
    height: 42px;

    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid rgba(199, 199, 199, 1);
    color: rgba(131, 131, 131, 1);

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;

    cursor: pointer;
    & > svg {
        width: 50%;
        height: 50%;
    }
  }

  & .method-button.active {
    color: rgba(30, 30, 30, 1);
    font-weight: 700;
    border: 1px solid rgba(0, 0, 0, 1);
  }

  & .refind-wrapper {
    display: flex;
    gap: 6px;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
  }

  & .refund-label {
    color: rgba(93, 93, 93, 1);
    flex-shrink: 1;
  }

  & .refund-value-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  & .refund-value {
    color: rgba(93, 93, 93, 1);
    font-weight: 500;
  }

  & .refund-register-button {
    flex-shrink: 1;
    color: rgba(131, 131, 131, 1);
    text-decoration: underline;

    margin-left: 6px;

    cursor: pointer;
  }
`;

export default SelectPaymentMethod;
