import React, { useMemo } from "react";
import { useSetRecoilState } from "recoil";
import styled, { css } from "styled-components";
import { ReactComponent as BlackArrowIcon } from "../../assets/icons/black-arrow-icon.svg";
import { ebayModalState } from "../../recoil/atoms/modal";
import { ProdcutEbayDealType } from "../../types/product";
import { getThousandCommaPrice } from "../../utils/utils";

interface ProductEbayDealProps {
  ebayDeals: ProdcutEbayDealType[];
  isModal?: boolean;
}

function ProductEbayDeal({ ebayDeals, isModal = false }: ProductEbayDealProps) {
  const setEbayModal = useSetRecoilState(ebayModalState);

  const repDeal = useMemo(() => {
    if (ebayDeals.length < 1) return undefined;
    return ebayDeals[0];
  }, [ebayDeals]);

  const isAtLeastOne = useMemo(() => {
    return !!repDeal;
  }, [repDeal]);

  // 스위칭 이베일 UI 모달
  const toggleEbayModal = () => {
    if (!isAtLeastOne) return;
    setEbayModal(!isModal);
  };

  return (
    <ComponentWrapper onClick={toggleEbayModal}>
      <Wrapper isAtLeastOne={isAtLeastOne}>
        <label className="label">이베이 거래가</label>
        <div className="right">
          {!!repDeal ? (
            <h4 className="rep-price">
              {getThousandCommaPrice(repDeal.price)} KRW
            </h4>
          ) : (
            <h4 className="rep-price">-</h4>
          )}
          <div className="icon-wrapper">
            <BlackArrowIcon className="arrow-bottom" />
          </div>
        </div>
      </Wrapper>
    </ComponentWrapper>
  );
}

const ComponentWrapper = styled.div`
  margin-top: 20px;
`;

const Wrapper = styled.div<{ isAtLeastOne: boolean }>`
  border-radius: 10px;
  border: 1px solid rgba(231, 231, 231, 1);

  padding: 0 14px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;

  ${(props) =>
    props.isAtLeastOne
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: not-allowed;
          background: rgba(243, 243, 243, 0.5);
        `};

  & .label {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.2px;
    text-align: left;

    color: ${(props) =>
      props.isAtLeastOne ? "rgba(30, 30, 30, 1)" : "rgba(199, 199, 199, 1)"};
  }

  & .right {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .rep-price {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.4000000059604645px;
    text-align: right;

    /* color: rgba(30, 30, 30, 1); */

    color: ${(props) =>
      props.isAtLeastOne ? "rgba(131, 131, 131, 1)" : "rgba(199, 199, 199, 1)"};
  }

  & .icon-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);

    cursor: pointer;
  }

  & .arrow-bottom {
    width: 7px;
  }
`;

export default ProductEbayDeal;
