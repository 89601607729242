import React, {useEffect, useMemo, useState} from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { paymentPayloadState } from "../../recoil/atoms/payment";
import { onChangeStringType } from "../../utils/utils";
import Input from "../elements/Input";
import {toNumber} from "../../utils/CommonFunction";

interface RegisterAddressProps {}

function RegisterGift({}: RegisterAddressProps) {
    const [payload, setPayload] = useRecoilState(paymentPayloadState);
    const isValidName = (value: string) => {
        if (value.length > 20) return false;
        return true;
    };

    const isValidPhone = (value: string) => {
        if (value.length > 11) return false;
        return true;
    };

    const handleChangeInfo = (key: string) =>
        onChangeStringType((value) => {

            if (key === "name" && !isValidName(value)) return;
            if (key === "id" && !isValidPhone(value)) {
                return;
            }
            if (key === "id") {
                value = toNumber(value).slice(0, 11);
            }else {
                value = value.slice(0, 10);
            }

            setPayload((old) => ({
                ...old,
                giftInfo: {
                    ...old.giftInfo,
                    [key]: value,
                },
            }));
        });

    const handleChangeType = (value: string) => {
        setPayload((old) => ({
            ...old,
            giftInfo: {
                ...old.giftInfo,
                type: value,
            },
        }));
    }

    return (
        <Wrapper>
            <div className="header">
                <h4 className="title">선물 받는 사람</h4>
                <div className="check-box">
                    <RadioArea>
{/*                        <button
                            type="button"
                            className={payload.giftInfo?.type === "kakao" ? "active" : ""}
                            onClick={() => handleChangeType("kakao")}
                        >
                            카카오톡
                        </button>*/}
                        <button
                            type="button"
                            className={payload.giftInfo?.type === "sms" ? "active" : ""}
                            onClick={() => handleChangeType("sms")}
                        >
                            문자(SMS)
                        </button>
{/*                        <button
                            type="button"
                            className={payload.giftInfo?.type === "tif" ? "active" : ""}
                            onClick={() => handleChangeType("tif")}
                        >
                            티프
                        </button>*/}
                    </RadioArea>
                </div>
            </div>

            <div className="inputs mt-14">
                <Input
                    label={payload.giftInfo?.type == "tif" ? "받는 분 닉네임" : "받는 분 이름"}
                    placeholder={payload.giftInfo?.type == "tif" ? "받는 분 닉네임" : "받는 분 이름"}
                    className={payload.giftInfo?.type === "sms" ? "sms" : "type"}
                    value={payload.giftInfo?.name ?? ''}
                    onChange={handleChangeInfo("name")}
                />
                {payload.giftInfo?.type == 'sms' &&
                <Input
                    label={"받는 분 연락처"}
                    placeholder={"받는 분 연락처"}
                    borderRadius="0 0 10px 10px"
                    value={payload.giftInfo?.id ?? ''}
                    onChange={handleChangeInfo("id")}
                />
                }
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 26px;

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .check-box {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }
    
  & .type {
    border: 1px solid #E7E7E7;
    border-radius : 10px;
  }  
  
  & .sms {
    border: 1px solid #E7E7E7;
    border-bottom : 0;
    border-radius : 10px 10px 0 0;  
  }  
`;

const RadioArea = styled.div`
  padding: 16px 20px;

  & > button {
    padding: 8px 12px;
    border-radius: 46px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.color.gray4};
    margin-right: 6px;

    &.active {
      color: ${(props) => props.theme.color.white};
      border-color: ${(props) => props.theme.color.gray1};
      background: ${(props) => props.theme.color.gray1};
    }
  }
`;
export default RegisterGift;
