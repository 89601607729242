import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { confirmModalState } from "../../recoil/atoms/modal";
import Button from "../elements/Button";
import ModalWrapper from "./ModalWrapper";

function ConfirmModal() {
  const [modal, setModal] = useRecoilState(confirmModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handleConfirm = () => {
    if (modal?.onConfirm) {
      modal?.onConfirm();
      handleClose();
    }
  };
  const handleCancel = () => {
    if (modal?.onCancel) {
      modal.onCancel();
    }

    handleClose();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={modal?.isClickOutArea ? handleClose : undefined}>
      <Wrapper>
        <h3 className="title">{modal.title}</h3>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: modal?.description || "" }}
        />
        <div className="footer">
          <Button onClick={handleCancel}>
            {modal?.cancelText ? modal.cancelText : "아니오"}
          </Button>
          <Button styleType="sub" onClick={handleConfirm}>
            {modal?.confirmText ? modal.confirmText : "예"}
          </Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 310px;

  padding: 30px 20px 20px 20px;
  border-radius: 20px;

  background: #fff;

  & > .title {
    font-family: Noto Sans KR;
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.6000000238418579px;
    text-align: center;
  }

  & > .description {
    margin-top: 8px;

    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;

    color: rgba(93, 93, 93, 1);
  }

  & > .footer {
    margin-top: 30px;
    display: flex;
    gap: 8px;
  }
`;

export default ConfirmModal;
