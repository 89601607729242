import React from "react";
import styled from "styled-components";

interface FixedBottomLayoutProps {
  children?: React.ReactNode;
}

function FixedBottomLayout({ children }: FixedBottomLayoutProps) {
  return (
    <Wrapper>
      <div className="container">{children}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;

  & .container {
    width: 100%;
    max-width: 840px;
    margin: 0 auto;

    padding: 10px 20px;

    width: 100%;
    display: flex;
  }
`;

export default FixedBottomLayout;
