import { atom } from "recoil";
import { v1 } from "uuid";
import { CouponType } from "../../hooks/useCouponList";

interface PaymentPayloadType {
  method?: string;
  refundInfo?: {
    bank: string;
    owner: string;
    account: string;
  };

  deliveryInfo?: {
    name?: string;
    phone?: string;
    postCode?: string;
    address?: string;
    detailAddress?: string;
    etc?: string;
    isSave?: boolean;
  };

  terms?: {
    cancelTerm?: boolean;
    purchaseTerm?: boolean;
  };

  gift?: number;
  giftInfo?: {
    type?: string;
    name?: string;
    id?: string;
  }
}

export const paymentPayloadState = atom<PaymentPayloadType>({
  key: `paymentPayloadState/${v1()}`,
  default: {},
});

export const useCouponState = atom<Record<string, CouponType>>({
  key: `useCouponState/${v1()}`,
  default: {},
});

export const paymentPointState = atom({
  key: `paymentPointState/${v1()}`,
  default: 0,
});

export const paymentMoneyState = atom({
  key: `paymentMoneyState/${v1()}`,
  default: 0,
});