import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { alertModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { PopupText, PopupTitle } from "../elements/Typography";

import { ReactComponent as Apple } from "../../assets/icons/find1-icon.svg";
import { ReactComponent as Kakao } from "../../assets/icons/find2-icon.svg";

const AlertModal = () => {
  const [modal, setModal] = useRecoilState(alertModalState);

  const handleClose = () => {
    modal?.onClose && modal?.onClose();
    setModal(null);
  };

  const handleConfirm = () => {
    modal?.onConfirm && modal.onConfirm();
    setModal(null);
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper className={modal?.findId ? "findID" : ""}>
        <PopupTitle className="center">{modal.title}</PopupTitle>
        <PopupText
          className="center"
          dangerouslySetInnerHTML={{ __html: modal.text }}
        />
        {modal?.findId && (
          <h2>
            {modal?.findType && (
              <span>{modal?.findType === "apple" ? <Apple /> : <Kakao />}</span>
            )}
            {modal?.findId}
          </h2>
        )}
        <Button onClick={handleConfirm}>{modal?.confirmText || "확인"}</Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default AlertModal;

const Wrapper = styled.div`
  width: calc(100% - 80px);
  max-width: 310px;
  padding: 22px;
  background: #ffffff;
  border-radius: 10px;

  & > div {
    margin: 12px 0 26px;
    display: block;

    & > b {
      color: ${(props) => props.theme.color.gray2};
      display: block;
    }
  }

  &.findID {
    & > div {
      margin: 8px 0 24px;
    }

    & > h2 {
      position: relative;
      width: 100%;
      height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
      background: ${(props) => props.theme.color.gray8};

      font-weight: 500;
      font-size: 14px;
      color: ${(props) => props.theme.color.gray1};
      margin-bottom: 30px;

      & > span {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;
