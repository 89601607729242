import React, { useMemo } from "react";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import DigitalPackageDetail from "./DigitalPackageDetail";
import GoodsPackageDetail from "./GoodsPackageDetail";
import ProductDetail from "./ProductDetail";
import GachaPackageDetail from "./GachaPackageDetail";

function GachaDetailPage() {
    const { card } = useProductDetail();

    // 디지털패키지상세페이지, 실물카드상세페이지, 카드상세페이지 분기처리
    const DetailPage = useMemo(() => {
        return GachaPackageDetail;
    }, [card]);

    return <DetailPage />;
}

export default GachaDetailPage;
