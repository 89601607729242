import React from "react";
import styled from "styled-components";
import Title from "../../components/common/Title";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import ProductSale from "../../components/detail/ProductSale";
import { ReactComponent as Arrow } from "../../assets/icons/checkbox-button-icon.svg";
import {useNavigate} from "react-router-dom";

function ProductSaleContainer() {
    const data = useProductDetail();
    const navigate = useNavigate();
    const length = (data?.sale_cards || []).length;

    if (length === 0) return null;

    return (
        <Wrapper>
        <Title title="판매 목록" arrow={{onClick : () => {
                navigate("/buy/goods/" + data?.card?.id);
            }}}/>
            <div className="cards-wrapper">
                <ProductSale
                    item={(data?.sale_cards || []) as any}
                />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 40px;

  & .cards-wrapper {
    margin-top: 24px;
    display: flex;
    overflow: hidden;  
  }
`;

export default ProductSaleContainer;
