import React, {useEffect, useMemo, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import qs from "qs";
import ResultSuccess from "./ResultSuccess";
import ResultFail from "./ResultFail";
import {orderMutation, orderSaveMutation} from "../../../apis/payment";
import {convertCouponIds} from "../../../utils/utils";
import {useSetRecoilState} from "recoil";
import {alertModalState} from "../../../utils/atom";
import OpenImage from "../../../components/result/OpenImage";
import DigitalPackResultContainer from "../../../containers/result/DigitalPackResultContainer";
import useOrder from "../../../hooks/useOrder";
import styled from "styled-components";

function ResultReady() {
    const navigate = useNavigate();
    const setAlertModal = useSetRecoilState(alertModalState);
    const [payLoading, setPayLoading] = useState<boolean>(true);

    const location = useLocation();
    let cardInfo = location.state.cardInfo;
    let useCoupon = location.state.useCoupon;
    let paymentPoint = location.state.paymentPoint;
    let paymentMoney = location.state.paymentMoney;
    let payload = location.state.payload;
    let state = location.state.state;
    let isPackage = location.state.isPackage;
    let refundInfo = location.state.refundInfo;
    let giftInfo = location.state.giftInfo;
    let totalPaymentPrice = location.state.totalPaymentPrice;

    const handleSubmit = async () => {
        const {data} = await orderSaveMutation({
            ...cardInfo,
            user_coupon_ids: convertCouponIds(useCoupon),
            point: String(paymentPoint),
            money: String(paymentMoney),
            payment_method: payload?.method as any,
            is_promotion: 0,
            gift: state.gift,
            ...(isPackage && {is_digital: 1}),
            ...(refundInfo && {...refundInfo}),
            ...(giftInfo && {...giftInfo}),
        });

        if (!data.success) {
            setAlertModal({
                title: "상품 구매 불가",
                text: data.alert,
                onConfirm: () => history.back(),
                onClose: () => history.back()
            });
            setPayLoading(false);
            return;
        }

        if (totalPaymentPrice === 0) {
            setPayLoading(false);
            navigate(`/payment/result?success=true&id=${data?.data?.order_id}`);
            return;
        }

        orderMutation(data?.data?.order_id);
    }

    useEffect(() => {
        handleSubmit();
    }, []);

    return <OpenImage/>;
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
`;

export default ResultReady;
