import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { ReviewType } from "../../types/api";
import { timeForToday } from "../../utils/CommonFunction";
import { useSetRecoilState } from "recoil";
import {pictureDetailModalState, reviewMenuModalState} from "../../utils/atom";

import { ReactComponent as Menu } from "../../assets/icons/community-detail-menu-icon.svg";
import { ReactComponent as Star } from "../../assets/icons/star-icon.svg";
import { ReactComponent as StarOff } from "../../assets/icons/star-off-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import ProfileImage from "../common/ProfileImage";
import useUser from "../../hooks/useUser";
import {useNavigate} from "react-router-dom";

const ProductReviews = ({
                        item,
                    }: {
    item: ReviewType[];
}) => {
    const setMenuModal = useSetRecoilState(reviewMenuModalState);
    const navigate = useNavigate();

    const contentRef = useRef(null);

    const [open, setOpen] = useState<boolean>(false);

    const setPictureDetailModalState = useSetRecoilState(pictureDetailModalState);

    const { user } = useUser();

    return (
        <MainSwiper
            //@ts-ignore
            slidesPerView={1}
            spaceBetween={12}
            freeMode={true}
        >
            {item.map((item, i: number) => (
            <ReviewSwiper key={"card-swiper-review-" + i}>
            <div>
                <div style={{width: "100%"}}>
                <Nickname>
                    <ProfileImage
                        size={"md"}
                        profileUrl={item.profile_url}
                        onClick={() => {
                            if (item?.user_id === user?.user_id) {
                                navigate("/mypage");
                                return;
                            }
                            navigate("/profile/" + item.user_id);
                        }}
                    />
                    <span style={{marginRight: "2px"}}/>
                    <p
                        style={{cursor:"pointer"}}
                        onClick={() => {
                            if (item?.user_id === user?.user_id) {
                                navigate("/mypage");
                                return;
                            }
                            navigate("/profile/" + item.user_id);
                        }}
                    >{item.nickname.slice(0, 10)} <br/>
                        <span>판매자 : {item.seller_info.nickname}</span><br/>
                        <span>{timeForToday(item.created_at)}</span>
                    </p>
                </Nickname>
                    <StarBox>
                        <div>
                        {Array(item.grade)
                            .fill(0)
                            .map((i: number) => (
                                <Star />
                            ))}
                        {Array(5 - item.grade)
                            .fill(0)
                            .map((i: number) => (
                                <StarOff />
                            ))}
                    </div>
                </StarBox>
                <Content
                    ref={contentRef}
                    className={open ? " open" : ""}
                    dangerouslySetInnerHTML={{ __html: item.contents }}
                />
                </div>
                <div>
                <CardInfo>
                    {item?.review_detail_type == "exchange" ? <Exchange>교환</Exchange>
                        : (item?.review_detail_type == "digital" ? <Exchange>디지털</Exchange>
                            :  item?.review_detail_type == "goods" ? <Card>상품</Card> : <></>)
                    }
                    {item?.review_images.length > 0 &&
                    <CardImage>
                            <img src={item?.review_images[0]?.image_url} alt=""
                                 onClick={() => {
                                     setPictureDetailModalState({
                                         start: 1,
                                         picture: item?.review_images || [],
                                         disabledEncoding: true,
                                         onClick: (index) => {
                                         },
                                     });
                                 }}
                            />
                    </CardImage>
                    }
                </CardInfo>
                </div>
            </div>
            </ReviewSwiper>
            ))}
        </MainSwiper>
    );
};

export default ProductReviews;

const CardImage = styled.div`
    border-radius: 14px;
    background: ${(props) => props.theme.color.gray8};
    width: 90px;
    min-width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
`;

const CardInfo = styled.div`
  padding-bottom: 10px;  
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  margin: 6px 12px 8px 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.53;
  color: ${(props) => props.theme.color.gray2};
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &.open {
    height: auto;
    -webkit-line-clamp: 2000;
  }
`;

const Nickname = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.gray1};

  & > p {
      margin-left: 6px;
  }  
    
  & > p > span {
    font-size: 11px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray5};
  }
`;

const StarBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 7px 0 15px 0;
    

  & > div {
    display: flex;
    align-items: center;

    & > svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const MainSwiper = styled(Swiper)``;

const ReviewSwiper = styled(SwiperSlide)`
    margin-bottom: 16px;
    padding: 15px 20px 5px 20px;
    width: 100%;
    position: relative;
    border: 2px solid #F3F3F3;
    border-radius: 10px;
    & > div {
        display: flex;
        //border-bottom: solid 1px ${(props) => props.theme.color.gray8};
    }
`;

const Exchange = styled.div`
  position: absolute;
  top: 20px;
  right: 26px;
  padding: 4px 6px;
  border-radius: 6px;
  background: ${(props) => props.theme.color.sub};
  font-size: 11px;
  font-weight: 700;
  color: ${(props) => props.theme.color.black};
`;

const Card = styled.div`
  position: absolute;
  top: 20px;
  right: 26px;
  padding: 4px 6px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.color.gray6};
  background: ${(props) => props.theme.color.white};
  font-size: 11px;
  font-weight: 700;
  color: ${(props) => props.theme.color.gray4};
`;

const StyledSlide = styled(SwiperSlide)`
  width: fit-content;
`;