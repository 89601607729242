import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { CheckButton, CheckText } from "../../pages/sale/Product";
import { termModalState } from "../../utils/atom";
import CheckBox from "../elements/CheckBox";

interface TermsComponentProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  text?: string;
  isDetailView?: boolean;
  className?: string;
  title?: string;
}

function TermsComponent({
  checked,
  onChange,
  text,
  isDetailView,
  className,
  title,
}: TermsComponentProps) {
  const setTermModal = useSetRecoilState(termModalState);
  const handleViewTerm = () => {
    text && setTermModal({ type: text, title });
  };

  const handleChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <Wrapper className={className ? className : ``}>
      <CheckBox checked={checked} onChange={handleChangeTerm}>
        <CheckText>
          {title}
          {isDetailView && (
            <CheckButton type="button" onClick={handleViewTerm}>
              전체보기
            </CheckButton>
          )}
        </CheckText>
      </CheckBox>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

export default TermsComponent;
