import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import moment from "moment";
import { getThousandCommaPrice } from "../../utils/utils";

function ProductExplanation({
  explanation,
  releaseDate,
  releasePrice,
  model,
}: {
  explanation: string;
  releaseDate: string | null;
  releasePrice: number | null;
  model?: string;
}) {
  return (
    <Wrapper>
      <div
        className="explanation ql-editor"
        style={{padding: "12px 0px"}}
        dangerouslySetInnerHTML={{ __html: explanation }}
      />
      <Swiper
        // @ts-ignore
        freeMode={true}
        slidesPerView={"auto"}
        modules={[FreeMode]}
        spaceBetween={8}
        style={{ marginTop: "20px" }}
      >
        {model && (
          <SwiperSlide>
            <div className="item">모델명 {model}</div>
          </SwiperSlide>
        )}
{/*        {releaseDate && (
          <SwiperSlide>
            <div className="item">출시일 {releaseDate}</div>
          </SwiperSlide>
        )}
        {!!releasePrice && (
          <SwiperSlide>
            <div className="item">
              발매가 {getThousandCommaPrice(releasePrice)}원
            </div>
          </SwiperSlide>
        )}*/}
      </Swiper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 6px;

  & .explanation {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);

    & img {
      width: 100%;
    }
  }

  & .swiper-slide {
    max-width: fit-content;
  }

  & .item {
    color: rgba(131, 131, 131, 1);
    background-color: rgba(243, 243, 243, 1);

    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;

    height: 30px;
    padding: 6px 8px 7px 8px;
    border-radius: 10px;
  }
`;

export default ProductExplanation;
