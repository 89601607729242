import React from "react";
import styled from "styled-components";
import CommonSummaryInfo from "../card/CommonSummaryInfo";
import SummaryCardImage from "../card/SummaryCardImage";
import StockUserProfile from "../payment/StockUserProfile";

interface ResultCardProps {
  nickname: string;
  profileUrl?: string;
  categoryName: string;
  name: string;
  price: number;
  subName?: string;
  imageUrl: string;
  type: "goods" | "goodsPack";
  conditions?: string;
}

function ResultCard({
  categoryName,
  name,
  nickname,
  price,
  subName,
  profileUrl,
  imageUrl,
  type,
  conditions,
}: ResultCardProps) {
  return (
    <Wrapper>
      <StockUserProfile
        nickname={nickname}
        type={type}
        profileUrl={profileUrl}
      />

      <div className="card-stock-info-wrapper">
        <SummaryCardImage imageUrl={imageUrl} />
        <CommonSummaryInfo
          categoryName={categoryName}
          name={name}
          price={price}
          subName={subName}
          conditions={conditions}
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .card-stock-info-wrapper {
    padding: 14px 0;
    display: flex;
    gap: 12px;
  }
`;

export default ResultCard;
