import React, { useMemo } from "react";
import styled from "styled-components";
import useCouponList from "../../hooks/useCouponList";
import { getThousandCommaPrice } from "../../utils/utils";
import PaymentButton from "../payment/PaymentButton";
import useSWR from "swr";
import useConfig from "../../hooks/useConfig";

interface ApplyCouponComponentProps {
  price: number;
  couponPrice?: number;
  onApply: () => void;
  text: string;
  pointPrice?: number;
  moneyPrice?: number;
  paymentPrice?: number;
  isCouponView?: boolean;
  isTicket?: number;
}

function ApplyCouponComponent({
  couponPrice,
  onApply,
  price,
  text,
  pointPrice,
  moneyPrice,
  paymentPrice,
  isCouponView,
  isTicket,
}: ApplyCouponComponentProps) {
  const { data } = useCouponList();
  const { data : config } = useConfig('dc_coupon_available_min_money');
  const dcAvailableMinMoney = config?.config?.value;

  const isCoupon = useMemo(() => {
    if (!data) return false;
    if ((data?.user_coupons?.data || []).length === 0) return false;
    if (price < dcAvailableMinMoney) return false;
    return true;
  }, [data, config]);

  return (
    <Wrapper>
      {isTicket != 1 &&
      <div className="box">
        <div className="box-label">상품 구매 금액</div>
        <div className="box-value">{getThousandCommaPrice(price)}{isTicket == 1 ? "장" : "원"}</div>
      </div>
      }
      {isCouponView &&
      <div className="box">
        <div className="box-label">쿠폰</div>
        <div className="coupon-wrapper">
          {couponPrice && (
            <div className="box-value">
              {getThousandCommaPrice(Number(couponPrice))}원 할인
            </div>
          )}

          {!isCoupon && price < dcAvailableMinMoney  && <div className="box-value">{getThousandCommaPrice(dcAvailableMinMoney)}원부터 사용가능</div>}
          {!isCoupon && price >= dcAvailableMinMoney && <div className="box-value">보유 쿠폰이 없습니다.</div>}

          <PaymentButton disabled={!isCoupon} onClick={onApply}>
            {text}
          </PaymentButton>
        </div>
      </div>
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;

  & .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .box-label {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .box-value {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(93, 93, 93, 1);
  }

  & .coupon-wrapper {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

export default ApplyCouponComponent;
