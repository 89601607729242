import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Divide from "../../components/common/Divide";
import {useProductDetail, useProductDetailAction} from "../../providers/ProductDetailProvider";
import ProductExplanationContainer from "./ProductExplanationContainer";
import ProgressiveImage from "react-progressive-graceful-image";
import PlaceholderImage from "../../assets/images/shadow-image.png";
import GachaCountInfo from "../../components/detail/GachaCountInfo";
import { ReactComponent as HeartOn } from "../../assets/icons/community-heart-on-icon.svg";
import { ReactComponent as HeartOff } from "../../assets/icons/community-heart-off-icon.svg";
import ProductClassCountInfo from "../../components/detail/ProductClassCountInfo";
import {Dot} from "../../utils/CommonFunction";
import {useSetRecoilState} from "recoil";
import useUser from "../../hooks/useUser";
import {cardDetailModalState} from "../../recoil/atoms/modal";
import TestImage from "../../assets/images/test_image.png";

function GachaPackageContainer() {
    const product = useProductDetail() as any;
    const [like, setLike] = useState(0);
    const { checkLogin } = useUser();
    const { toggleLike } = useProductDetailAction();
    const setCardDetailModal = useSetRecoilState(cardDetailModalState);

    // 로그인 check 후 정상동작 시에 카드 관심상품 등록 및 해제
    const handleClickLike = checkLogin(() => {
        toggleLike(product?.card?.id);
    });

    const handleCardDetailModal = (card : any) => {
        setCardDetailModal({
            card
        });
    };

    return (
        <Wrapper>
            <div>
                <ListContent>
                    {product?.card_images[0]?.image_url && (
                        <div className={"thumbnail_image"}>
                            {/*
                            <ProgressiveImage src={product?.card_images[0]?.image_url} placeholder={PlaceholderImage}>
*/}
                            <ProgressiveImage src={product?.card_images[0]?.image_url} placeholder={PlaceholderImage}>

                                {(src, loading, placeholder) => (
                                    <img className={loading ? "loading" : "loaded"} src={src} alt=""/>
                                )}
                            </ProgressiveImage>
                            {product?.card?.my_like ? (
                                <HeartOn onClick={handleClickLike}/>
                            ) : (
                                <HeartOff onClick={handleClickLike}/>
                            )}
                        </div>
                    )}
                    <p>{product?.card?.category_name}</p>
                    <h1>{product?.card?.name}</h1>
                    <GachaCountInfo totalAmount={product.total_amount} remainAmount={product.remain_amount}/>
                    <div className={"price_div"}><span>판매가 {Dot(product?.card?.price)} 원</span></div>
                </ListContent>
            </div>
            <Divide margin={35}/>
            {product?.card_classes &&
                (
                    <>
                        <ProductClassCountInfo
                            cardClasses={product?.card_classes} type={"gacha"}
                        />
                        <Divide margin={20}/>
                    </>
                )
            }
            {product?.card_classes?.map((item: any) => (
                <>
                    <LastedTitle>
                        {item.card_class ?? 'ETC'}
                        <span>
                    {Dot(item.open_count)}/{Dot(item.total_count)}
                </span>
                    </LastedTitle>
                    <ListArea>
                        {product?.card_stock_info?.filter((stock_item: any) => stock_item.card_class == item.card_class).map((stock_item: any, i: number) => (
                            <>
                                <DigitalCards
                                    key={"digital_list_" + i}
                                    onClick={() => handleCardDetailModal(stock_item)}
                                >
                                    <div className={"stock-image-box"}>
                                        <img
                                            src={stock_item?.image_url}
                                            alt=""
                                        />
                                        <Cards>
                                            {Dot(stock_item.open_count)}/{Dot(stock_item.total_count)}
                                        </Cards>
                                        {stock_item.total_count - stock_item.open_count == 0 &&
                                            <StatusBox>SOLD OUT</StatusBox>
                                        }
                                    </div>
                                    <p className="name">{stock_item?.name}</p>
                                    <p className="sub_name">{stock_item?.sub_name}</p>
                                </DigitalCards>
                            </>
                        ))}
                    </ListArea>
                    <div style={{margin: "20px 0"}}></div>
                    {/*
            <Divide margin={20}/>
*/}
                </>
            ))}
            {product?.card_stock_last_one_info && (
                <>
                    <LastedTitle>
                        {'LAST ONE'}
                        <span>
                    {Dot(product?.card_stock_last_one_info?.open_count)}/{Dot(product?.card_stock_last_one_info?.total_count)}
                </span>
                    </LastedTitle>
                    <ListArea>
                            <>
                                <DigitalCards
                                    key={"digital_list_last"}
                                    onClick={() => handleCardDetailModal(product?.card_stock_last_one_info)}
                                >
                                    <div className={"stock-image-box"}>
                                        <img
                                            src={product?.card_stock_last_one_info?.image_url}
                                            alt=""
                                        />
                                        <Cards>
                                            {Dot(product?.card_stock_last_one_info?.open_count)}/{Dot(product?.card_stock_last_one_info?.total_count)}
                                        </Cards>
                                        {product?.card_stock_last_one_info.total_count - product?.card_stock_last_one_info.open_count == 0 &&
                                            <StatusBox>SOLD OUT</StatusBox>
                                        }
                                    </div>
                                    <p className="name">{product?.card_stock_last_one_info?.name}</p>
                                    <p className="sub_name">{product?.card_stock_last_one_info?.sub_name}</p>
                                </DigitalCards>
                            </>
                    </ListArea>
                    <div style={{margin: "20px 0"}}></div>
                    {/*
            <Divide margin={20}/>
*/}
                </>
            )}
            <ProductExplanationContainer/>
            <div style={{margin: "5px 0"}}></div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    padding: 0 20px;
    padding-bottom: 100px;
`;

const LastedTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 800;
  color: ${(props) => props.theme.color.gray1};

  & > span {
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const ListContent = styled.div`
      & > div {
        & > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          object-fit: fill;
        }
    
          & .loading {
              filter: blur(5px);
              clip-path: inset(0);
          }
    
          & .loaded {
              filter: blur(0px);
              transition: filter 0.1s linear;
          }
      }

    & .thumbnail_image{
        margin-bottom: 12px;
        position: relative;
        width: 100%;
        aspect-ratio: 640 / 420;
        border-radius: 10px;
        
        & > svg { 
            bottom: 12px;
            right: 12px;
            position: absolute;
        }
    }
    
    & .price_div{
        margin: 25px 0 1px 0;
        text-align: right;
        font-size: 16px;
        font-weight: 600;
        color: #3A3A3A;
    }
    
    
  & > h1 {
    font-size: 18px;
    font-weight: 600;
    color: #3A3A3A;
  }

  & > p {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    margin-top: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.54;
    color: #3A3A3A;

      & > svg {
          width: 24px;
          height: 24px;
      }
  }
`;

const ListArea = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-between;
`;

const DigitalCards = styled.div`
  position: relative;
  width: calc(50% - 6px);

    & .stock-image-box{
        position: relative;
        overflow: hidden;
    }
    
  & .price {
    margin-top: 10px;
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  & .label {
    margin-top: 6px;
    font-size: 10px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray4};
  }
  
  & > div {
    width: 100%;
    /* padding-top: 100%; */
    aspect-ratio: 1;
    cursor: pointer;
    border-radius: 14px;
    background: ${(props) => props.theme.color.gray8};

    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & .name {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 700;
      line-height: 18px;
    color: #5d5d5d;
  }

  & .sub_name {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
      line-height: 18px;
    color: #3a3a3a;
  }

  &:nth-of-type(n + 3) {
    margin-top: 12px;
  }
`;

const Cards = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 6px;
  border-radius: 6px;
  background: ${(props) => props.theme.color.gray3};
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.white};
`;

const StatusBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: ${(props) => props.theme.color.white};
  text-align: center;
`;

export default GachaPackageContainer;
