import React, { useMemo } from "react";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import DigitalPackageDetail from "./DigitalPackageDetail";
import GoodsPackageDetail from "./GoodsPackageDetail";
import ProductDetail from "./ProductDetail";
import GachaPackageDetail from "./GachaPackageDetail";

function ProductDetailPage() {
  const { card } = useProductDetail();

  // 디지털패키지상세페이지, 실물카드상세페이지, 카드상세페이지 분기처리
  const DetailPage = useMemo(() => {
    if (card.is_gacha === 1 && card.is_pack === 1 && card.is_digital === 1) return GachaPackageDetail;
    if (card.is_pack === 0) return ProductDetail;
    if (!card.is_digital) return GoodsPackageDetail;
    return DigitalPackageDetail;
  }, [card]);

  return <DetailPage />;
}

export default ProductDetailPage;
