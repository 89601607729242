import React, {useState} from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { ImageType } from "../../types/types";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../utils/atom";
import imageCompression from 'browser-image-compression';
import heic2any from "heic2any"
import { ReactComponent as Plus } from "../../assets/icons/file-plus-icon.svg";
import { ReactComponent as Remove } from "../../assets/icons/image-remove-icon.svg";
import { ReactComponent as ChangeBefore } from "../../assets/icons/change-before.svg";
import { ReactComponent as ChangeAfter } from "../../assets/icons/change-after.svg";
import { ReactComponent as OneBefore } from "../../assets/icons/one-before.svg";
import { ReactComponent as TwoBefore } from "../../assets/icons/two-before.svg";
import { ReactComponent as ThreeBefore } from "../../assets/icons/three-before.svg";
import { ReactComponent as FourBefore } from "../../assets/icons/four-before.svg";
import { ReactComponent as FiveBefore } from "../../assets/icons/five-before.svg";
import { ReactComponent as OneAfter } from "../../assets/icons/one-after.svg";
import { ReactComponent as TwoAfter } from "../../assets/icons/two-after.svg";
import { ReactComponent as ThreeAfter } from "../../assets/icons/three-after.svg";
import { ReactComponent as FourAfter } from "../../assets/icons/four-after.svg";
import { ReactComponent as FiveAfter } from "../../assets/icons/five-after.svg";
import PageLoadingComponent from "../common/PageLoadingComponent";
import LoadingComponent from "../common/LoadingComponent";

const ImageFile = ({
                     image,
                     setImage,
                     size,
                     max,
                     handleRemove,
                   }: {
  image: ImageType[];
  setImage: (value: ImageType[]) => void;
  size: number;
  max: number;
  handleRemove?: (item: ImageType) => void;
}) => {
  const setAlertModal = useSetRecoilState(alertModalState);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [id, setId] = useState<number[]>([]);

  const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const files: any = e.target.files;
    let loading_chk = 0;
    const maxSize = size * 1024 * 1024;
    let arr = [...image];

    const options = {
      maxSizeMB: size,
      maxWidthOrHeight: 1920
    }

    try {
      setIsFetchLoading(true);

      let i = 0;
      for (const file of files) {
        if (file.size > maxSize) {
          setAlertModal({
            title: "등록 불가",
            text: size + "MB 이하의 이미지를 입력해주세요.",
          });
        }else {
          if(file.name.split('.')[1] === 'heic'){
            loading_chk = 1;
            let blob = file;
            let file2 = file;
            let arr_id = [...id];
            arr_id.push(i);
            heic2any({blob : blob, toType : "image/jpeg"})
                .then(async function (resultBlob) {
                  setIsFetchLoading(true);
                  let heic_i = 0;
                  // @ts-ignore
                  file2 = new File([resultBlob], file.name.split('.')[0] + ".jpg", {
                    type: "image/jpeg",
                    lastModified: new Date().getTime()
                  });

                  const compressedFile = await imageCompression(file2, options);
                  const resizingFile = new File([compressedFile], file2.name, {type: file2.type});

                  if (arr.length < 5) {
                      arr.push({
                      file: resizingFile,
                      preview_url: URL.createObjectURL(resizingFile),
                      sort: arr_id[heic_i],
                      });
                  }
                  heic_i++;

                  return arr;
                }).then(function (arr){
                  setTimeout(() => {
                    arr.sort(function(a,b) {
                        // @ts-ignore
                        return a.sort-b.sort;
                    })
                    for(let i=0; i<arr.length; i++){
                        arr[i] = { ...arr[i], sort: i };
                    }
                    setImage(arr);
                    setIsFetchLoading(false);
                  }, 1000);
                })
                .catch(function (x){
                  console.log(x)
                })
          }else{
            const compressedFile = await imageCompression(file, options);
            const resizingFile = new File([compressedFile], file.name, {type: file.type});

            if (arr.length < 5) {
                arr.push({
                file: resizingFile,
                preview_url: URL.createObjectURL(resizingFile),
                sort: i,
              });
            }
          }
        }
        i++;
      }

      if(loading_chk == 0) {
        arr.sort(function(a,b) {
          // @ts-ignore
          return a.sort-b.sort;
        })

        for(let i=0; i<arr.length; i++){
            arr[i] = { ...arr[i], sort: i};
        }

        setImage(arr);
        setIsFetchLoading(false);
      }
    } catch (error) {
      console.log(error);
    }


    /*    for (const file of files) {
          if (file.size > maxSize) {
            setAlertModal({
              title: "등록 불가",
              text: size + "MB 이하의 이미지를 입력해주세요.",
            });
          } else {
            if (arr.length < 5) {
              arr.push({
                file: file,
                preview_url: URL.createObjectURL(file),
              });
            }
          }
        }

        setImage(arr);*/
  };

    const handleOrderChange = (i:number, status:number) => {
        let arr = [...image];
        arr[i] = { ...arr[i], order_change: status };
        let a = 0;
        let tmp_i = 0;
        let tmp_id = 0;

        // @ts-ignore
        for (let i = 0; i<arr.length; i++) {
            if(a == 0 && arr[i].order_change == 1) {
                a++;
                tmp_i = i;
            }else if(a == 1 && arr[i].order_change == 1) {
                // @ts-ignore
                tmp_id = arr[tmp_i].sort;
                arr[tmp_i] = { ...arr[tmp_i], sort: arr[i].sort, order_change: 0};
                // @ts-ignore
                arr[i] = { ...arr[i], sort: tmp_id, order_change: 0};
            }
        }
        arr.sort(function(a,b) {
            // @ts-ignore
            return a.sort-b.sort;
        })

        for(let i=0; i<arr.length; i++){
            arr[i] = {...arr[i], sort : i};
        }

        setImage(arr);
    }

  if (isFetchLoading) return <LoadingComponent />;

  return (
      <Swiper
          //@ts-ignore
          slidesPerView="auto"
          spaceBetween={8}
          freeMode={true}
      >
        {image.length < max && (
            <StyledSlide>
              <Label>
                <Plus />
                <input
                    type="file"
                    multiple
                    onChange={handleImage}
                    accept="image/*, .heic"
                />
              </Label>
            </StyledSlide>
        )}
        {image.map((item, i: number) => (
            <StyledSlide key={"image_swiper_" + i}>
              <ImageBox>
                <img src={item.preview_url} alt=""/>
                  {i == 0 &&
                      <>
                          {(item.order_change && item.order_change == 1) ?
                              <OneAfter
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 0);
                                  }}
                              />
                              :
                              <OneBefore
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 1);
                                  }}
                              />
                          }
                      </>
                  }
                  {i == 1 &&
                      <>
                          {(item.order_change && item.order_change == 1) ?
                              <TwoAfter
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 0);
                                  }}
                              />
                              :
                              <TwoBefore
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 1);
                                  }}
                              />
                          }
                      </>
                  }
                  {i == 2 &&
                      <>
                          {(item.order_change && item.order_change == 1) ?
                              <ThreeAfter
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 0);
                                  }}
                              />
                              :
                              <ThreeBefore
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 1);
                                  }}
                              />
                          }
                      </>
                  }
                  {i == 3 &&
                      <>
                          {(item.order_change && item.order_change == 1) ?
                              <FourAfter
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 0);
                                  }}
                              />
                              :
                              <FourBefore
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 1);
                                  }}
                              />
                          }
                      </>
                  }
                  {i == 4 &&
                      <>
                          {(item.order_change && item.order_change == 1) ?
                              <FiveAfter
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 0);
                                  }}
                              />
                              :
                              <FiveBefore
                                  className={"change"}
                                  onClick={() => {
                                      handleOrderChange(i, 1);
                                  }}
                              />
                          }
                      </>
                  }
                <Remove
                    className={"remove"}
                    onClick={() => {
                      handleRemove && handleRemove(item);
                      let arr = [...image];
                      arr.splice(i, 1);
                      setImage(arr);
                    }}
                />
              </ImageBox>
            </StyledSlide>
        ))}
      </Swiper>
  );
};

export default ImageFile;

const ImageBox = styled.div`
  position: relative;
  width: 76px;
  height: 76px;
  border-radius: 10px;
  overflow: hidden;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & .remove {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 16px;
    height: 16px;
  }

    & .change {
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 16px;
        height: 16px;
    }
`;

const Label = styled.label`
  width: 76px;
  height: 76px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.color.gray7};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    width: 24px;
    height: 24px;
  }

  & > input {
    display: none;
  }
`;

const StyledSlide = styled(SwiperSlide)`
  width: fit-content;
`;
