import React, { Component } from "react";
import { Helmet } from "react-helmet";

class HelmetComponent extends Component {
  render() {
    return (
      <Helmet>
        {/* 구글폰트  */}

        {/* font-family: 'Noto Sans KR';  */}
        {/* font-family: 'Poppins';  */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          //@ts-ignore
          crossorigin
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
    );
  }
}

export default HelmetComponent;
