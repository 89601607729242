import React from "react";
import styled from "styled-components";
import { ReactComponent as Released } from "../../assets/icons/released.svg";

interface ProductClassCountInfoProps {
    totalAmount: number;
    remainAmount: number;
}

function GachaCountInfo({totalAmount, remainAmount}: ProductClassCountInfoProps) {
    return (
        <Wrapper>
            <div className="box">
                <span className="label">잔여 수량 / 준비 수량</span><br/>
                <span className="label_count">{remainAmount}개 / {totalAmount}개</span>
            </div>
            <div className="count_box" style={{
                backgroundSize: (Number(totalAmount - remainAmount)/Number(totalAmount)*100).toFixed() + "% 100%",
                backgroundImage: "url(https://ttif.s3.ap-northeast-2.amazonaws.com/d4f648/Rectangle+104.png)"
            }}>
{/*                <div><Released/>
                </div>*/}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin-top: 15px;
    & .box {
        margin-bottom: 10px;
        & .label {
            width: 100%;
            font-size: 12px;
            font-weight: 500;
            line-height: 11px;
            letter-spacing: 0;
            text-align: left;

            color: #838383;
        }

        & .label_count {
            font-size: 14px;
            font-weight: 500;
            line-height: 25px;
            letter-spacing: 0;
            text-align: left;

            color: #838383;
        }
    }
    
    & .count_box {
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #E7E7E7;
        border-radius: 15px;
        width: 100%;
        //padding: 0 15px;
        height: 30px;
        //height: 100%;
        //padding: 0;
        text-align: right;
        background-repeat: no-repeat;
        //background-image: url("https://ttif.s3.ap-northeast-2.amazonaws.com/d4f648/Rectangle+104.png");
    }
`;

export default GachaCountInfo;
