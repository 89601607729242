import React from "react";
import { BeatLoader, FadeLoader } from "react-spinners";
import styled from "styled-components";
interface PageLoadingComponentProps {}

function PageLoadingComponent({}: PageLoadingComponentProps) {
  return (
    <Wrapper className="center">
      <FadeLoader color="rgb(212, 246, 72)" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
`;

export default PageLoadingComponent;
