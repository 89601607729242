import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ProfileIcon } from "../../assets/icons/profile-icon.svg";
import { ReactComponent as BadgeIcon } from "../../assets/icons/badge-icon.svg";

interface ProfileImageProps {
  size?: "xs" | "sm" | "md" | "lg";
  profileUrl?: string | null;
  isBadge?: boolean;
  className?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
}

function ProfileImage({
  size = "sm",
  profileUrl,
  isBadge,
  className,
  onClick,
  width,
  height,
}: ProfileImageProps) {
  const style = {
    width: width || "",
    height: height || "",
  };

  return (
    <Wrapper
      size={size}
      className={className ? className : ""}
      onClick={onClick}
      style={style}
    >
      {profileUrl ? (
        <img src={profileUrl} alt="profileImage" className="profile-image" />
      ) : (
        <ProfileIcon className="profile-image" />
      )}

      {isBadge && (
        <div className="badge-icon">
          <BadgeIcon />
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div<{
  size?: "xs" | "sm" | "md" | "lg";
}>`
  position: relative;
  border-radius: 50%;

  cursor: pointer;

  ${(props) =>
    props.size === "xs" &&
    css`
      width: 20px;
      height: 20px;
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      width: 32px;
      height: 32px;
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      width: 46px;
      height: 46px;
    `}

  & .profile-image {
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }

  & .badge-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export default ProfileImage;
