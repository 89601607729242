import React from "react";
import styled, { css } from "styled-components";

interface DivideProps {
  margin?: number;
  backgroundColor?: string;
}

function Divide({ margin = 0, backgroundColor }: DivideProps) {
  return <Wrapper margin={margin} backgroundColor={backgroundColor} />;
}

const Wrapper = styled.div<{ margin: number; backgroundColor?: string }>`
  width: 100%;
  height: 1px;

  ${(props) =>
    props.backgroundColor
      ? css`
          background-color: ${props.backgroundColor};
        `
      : css`
          background: rgba(231, 231, 231, 1);
        `}

  margin: ${(props) => `${props.margin}px auto`};
`;

export default Divide;
