import React from "react";
import styled from "styled-components";

interface TargetProps {
  target: any;
}

function Target({ target }: TargetProps) {
  return <Wrapper ref={target} />;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  bottom: 200px;
`;

export default Target;
