import { atom } from "recoil";
import { v1 } from "uuid";
import { UserShopCardType } from "../../components/usershop/UserShopCard";
import { SellerProfileProps } from "../../types/types";
import {
  CartCardType,
  levelCategoryCheckType,
  midCategoryCheckType,
  categoryCheckType, paybackFilterCategoryCheckType,
} from "../../types/api";
import CardDetailModal from "../../components/modal/card/CardDetailModal";

export const ebayModalState = atom<boolean>({
  key: `ebayModalState/${v1()}`,
  default: false,
});

export const recentDealModalState = atom<boolean>({
  key: `recentDealModalState/${v1()}`,
  default: false,
});

export const categoryCheckState = atom<categoryCheckType[]>({
  key: `categoryCheckState/${v1()}`,
  default: [],
});

export const midCategoryCheckState = atom<midCategoryCheckType[]>({
  key: `midCategoryCheckState/${v1()}`,
  default: [],
});

export const levelCategoryCheckState = atom<levelCategoryCheckType[]>({
  key: `levelCategoryCheckState/${v1()}`,
  default: [],
});

export const paybackFilterCategoryCheckState = atom<paybackFilterCategoryCheckType[]>({
  key: `paybackFilterCategoryCheckState/${v1()}`,
  default: [],
});

export const paybackFilterBoxCategoryCheckState = atom<number[]>({
  key: `paybackFilterBoxCategoryCheckState/${v1()}`,
  default: [],
});

export const minMoneyState = atom<number>({
  key: `minMoneyState/${v1()}`,
  default: -1,
});

export const maxMoneyState = atom<number>({
  key: `maxMoneyState/${v1()}`,
  default: -1,
});

interface ConfirmModalType {
  title: string;
  description?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  isClickOutArea?: boolean;
}

export const confirmModalState = atom<ConfirmModalType | null>({
  key: `confirmModalState/${v1()}`,
  default: null,
});

type SaleDigitalDetailModalType = boolean;

export const saleDigitalDetailModalState = atom<SaleDigitalDetailModalType>({
  key: `saleDigitalDetailState/${v1()}`,
  default: false,
});

export interface PaymentModalType {
  data: SellerProfileProps;
  onBuy: () => void;
  onBasket: () => void;
}

export const paymentModalState = atom<PaymentModalType | null>({
  key: `paymentModalState/${v1()}`,
  default: null,
});

export interface DigitalPackagePaymentModalType {
  title: string;
  price: number;
  gift?: number;
  userOrderAbleMax: number;
  amount: {
    total: number;
    open: number;
    remain: number;
  };
  stockId: string;
  isTicket: number;
  remainUserTicket: number;
}

export interface GachaPackagePaymentModalType {
  title: string;
  price: number;
  gift?: number;
  userOrderAbleMax: number;
  amount: {
    total: number;
    open: number;
    remain: number;
  };
  stockId: string;
}

export const digitalPackagePaymentModalState =
  atom<DigitalPackagePaymentModalType | null>({
    key: `paymentDigitalPackageModalState/${v1()}`,
    default: null,
  });

export const gachaPackagePaymentModalState =
    atom<GachaPackagePaymentModalType | null>({
      key: `paymentGachaPackageModalState/${v1()}`,
      default: null,
    });

export interface GoodsPackagePaymentModalType {
  stockId: string;
}

export const goodsPackagePaymentModalState =
  atom<GoodsPackagePaymentModalType | null>({
    key: `goodsPackagePaymentModalState/${v1()}`,
    default: null,
  });

export type RefundValueType = {
  bank: string;
  owner: string;
  account: string;
};

export type RefundSubmitType = (refundValues: RefundValueType) => void;

export interface RefundModalType {
  onSubmit: RefundSubmitType;
  defaultValue?: RefundValueType;
}

export const refundModalState = atom<RefundModalType | null>({
  key: `refundModalState/${v1()}`,
  default: null,
});

export interface CouponModalType {
  stockId: number;
  price: number;
}

export const couponModalState = atom<CouponModalType | null>({
  key: `couponModalState/${v1()}`,
  default: null,
});

interface WaybillModalType {
  orderId: string;
  onRegister?: () => void;
}

export const waybillModalState = atom<WaybillModalType | null>({
  key: `waybillModalState/${v1()}`,
  default: null,
});

type BuyCardModalType = {
  card: UserShopCardType;
  userId?: string | number;
};

export const buyGoodsModalState = atom<BuyCardModalType | null>({
  key: `buyGoodsModalState/${v1()}`,
  default: null,
});

type CardDetailModalType = {
  card: UserShopCardType;
};

export const cardDetailModalState = atom<CardDetailModalType | null>({
  key: `cardDetailModalState/${v1()}`,
  default: null,
});

export const buyDigitalModalState = atom<BuyCardModalType | null>({
  key: `buyDigitalModalState/${v1()}`,
  default: null,
});

export const buyDigitalPackModalState = atom<BuyCardModalType | null>({
  key: `buyDigitalPackModalState/${v1()}`,
  default: null,
});

type CategoryModalType = {
  categories: {
    id: string | number;
    image_url: string;
    name: string;
  }[];
  onClick?: (id: string | number) => void;
};

export const categoryModalState = atom<CategoryModalType | null>({
  key: `categoryModalState/${v1()}`,
  default: null,
});

export type ImageModalType = {
  images: string[];
  number?: number;
};

export const imageModalState = atom<ImageModalType | null>({
  key: `imageModalState/${v1}`,
  default: null,
});
