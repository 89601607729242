import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";

interface Props {
  title: string;
  onClose?: () => void;
}

function ModalHeader({ title, onClose }: Props) {
  return (
    <Wrapper>
      <div className="title">{title}</div>
      <div className="close-icon" onClick={onClose}>
        <CloseIcon />
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  height: 54px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  & .title {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;

    color: rgba(30, 30, 30, 1);
  }

  & .close-icon {
    position: absolute;
    right: 0px;
  }
`;

export default ModalHeader;
