import React, {useMemo, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import Title from "../../components/common/Title";
import ProductComment from "../../components/detail/ProductComment";
import RegisterCommentButton from "../../components/detail/RegisterCommentButton";
import useUser from "../../hooks/useUser";
import { useProductCommentValue } from "../../providers/ProductCommentProvider";
import { useProductDetailAction } from "../../providers/ProductDetailProvider";
import { ReactComponent as EmptyIcon } from "../../assets/icons/empty-cart.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/view-plus.svg";
import { ReactComponent as MinusIcon } from "../../assets/icons/view-minus.svg";

import Button from "../../components/elements/Button";

function ProductCommentsContainer() {
  const { comments } = useProductCommentValue();
  const navigate = useNavigate();
  const { checkLogin } = useUser();
  const { cardId } = useParams();
  const { data } = useSWR(`/card/comment?card_id=${cardId}`);
  const [open, setOpen] = useState<boolean>(false);

  const commentLength = useMemo(() => {
    return (data?.comments?.data || []).length;
  }, [data]);

  const handleClickArrow = checkLogin(() => {
    navigate("comments");
  });

  const handleClickRegisterBtn = checkLogin(() => {
    navigate("comment/register");
  });

  const { mutate } = useProductDetailAction();

  if ((comments || []).length === 0) {
    return (
      <Wrapper>
        <Title title="티프 유저 코멘트" />
        <Empty className="center column">
          <EmptyIcon />
          <span className="mt-16">작성된 코멘트 유저가 없습니다.</span>
          <p className="mt-10">첫 코멘트를 등록해보세요!</p>
        </Empty>
        <ButtonWrapper>
          <Button styleType="line" onClick={handleClickRegisterBtn}>
            코멘트 등록
          </Button>
        </ButtonWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title
        title="티프 유저 코멘트"
        arrow={commentLength >= 4 ? { onClick: handleClickArrow } : undefined}
      />
        {!open ?
            <div className="comments-wrapper">
                {data?.comments?.data?.slice(0, 3).map((comment: any) => (
                    <ProductComment comment={comment} key={comment.id} mutate={mutate}/>
                ))}
            </div>
            :
            <div className="comments-wrapper">
                {data?.comments?.data?.map((comment: any) => (
                    <ProductComment comment={comment} key={comment.id} mutate={mutate}/>
                ))}
            </div>
        }
        {!open ?
            (data?.comments?.data?.length > 3 ?
                    <></>
/*                    <div style={{textAlign:"center"}}>
                    <PlusIcon style={{marginTop: "16px"}} styleType="line" onClick={() => setOpen(true)}/>
                    </div>*/
                    :
                    <></>
            )
            :
            <div style={{textAlign:"center"}}>
        <MinusIcon style={{marginTop:"16px"}} styleType="line" onClick={() => setOpen(false)}/>
            </div>
        }
      <RegisterCommentButton onClick={handleClickRegisterBtn} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 40px;

  & .comments-wrapper {
    margin-top: 20px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
    
`;

const Empty = styled.div`
  margin-top: 64px;

  & > span {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(199, 199, 199, 1);
  }

  & > p {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(58, 58, 58, 1);
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  margin-top: 17px;
  width: 200px;
`;

export default ProductCommentsContainer;
