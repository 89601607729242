import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { addToCard } from "../../../apis/common";
import {buyGoodsModalState, cardDetailModalState} from "../../../recoil/atoms/modal";
import { api } from "../../../utils/api";
import { customToast, getThousandCommaPrice } from "../../../utils/utils";
import CardConditions from "../../card/CardConditions";
import SummaryCardImage from "../../card/SummaryCardImage";
import ModalHeader from "../../common/ModalHeader";
import Button from "../../elements/Button";
import {CommonBottomModalContainer, FixedBottomWrapper} from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { alertModalState } from "../../../utils/atom";
import BuyGoodsContainer from "../../../containers/transaction/BuyGoodsContainer";
import CardInfo from "../../card/CardInfo";
import CardDetailInfo from "../../card/CardDetailInfo";

function CardDetailModal() {
    const modal = useRecoilValue(cardDetailModalState);
    const reset = useResetRecoilState(cardDetailModalState);
    const setAlertModal = useSetRecoilState(alertModalState);
    const navigate = useNavigate();
    const ref = useRef(null);

    const [origin, setOrigin] = useState<string>("");

    const [touchStart, setTouchStart] = useState(0);
    const [touchEnd, setTouchEnd] = useState(0);
    const [touchMove, setTouchMove] = useState(0);

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            reset();
        }
    }, [location.pathname]);

    const handleDetail = () => {
        reset();
        navigate(`/detail/${modal?.card?.card_id}`);
    };

    useEffect(() => {
        let touchMoveDiff = touchStart - touchMove;
        if (ref.current !== null) {
            if(touchMoveDiff < 0) {
                // @ts-ignore
                ref.current.style.height = `calc(90% - ${-touchMoveDiff}px)`;
            }

        }
    }, [touchMove]);

    useEffect(() => {
        let touchDiff = touchStart - touchEnd;
        if (ref.current !== null) {
            if(touchDiff < -80){
                reset();
            }else{
                setTimeout(() => {
                    // @ts-ignore
                    ref.current.style.height = `calc(90%)`;
                }, 100)
            }
        }
    }, [touchEnd]);

    if (!modal) return null;


    return (
        <ModalWrapper position="bottom" onClose={reset}>
            <Wrapper ref={ref}>
                <div onTouchStart={(e) =>{
                         setTouchStart(e.changedTouches[0].pageY);
                     }}
                     onTouchEnd={(e) =>{
                         setTouchEnd(e.changedTouches[0].pageY);
                     }}
                     onTouchMove={(e) =>{
                         setTouchMove(e.changedTouches[0].pageY);
                     }}
                >
                <ModalHeader title="상품 정보"
                             onClose={reset} />
                </div>
                <ContentWrapper>
                <div className="mt-10">
                    <SummaryCardImage
                        size="full"
                        width={"100%"}
                        height={"100%"}
                        imageUrl={modal?.card?.image_url ?? ""}
                        card_stock_images={modal?.card?.card_stock_images || ""}
                    />
                </div>

                {/*        <div className="mt-20">
          <p className="label ">상품금액</p>
          <h4 className="price mt-6">
            {getThousandCommaPrice(modal.card.price)}원
          </h4>
          <div className="mt-16">
            <CardConditions condition={modal.card.card_condition} />
          </div>
          {modal.card.etc && <div className="etc mt-10">{modal.card.etc}</div>}
        </div>*/}

                <CardDetailInfo
                    className="card-info"
                    contents={modal.card.etc}
                    categoryName={modal.card.category_name}
                    name={modal.card.name}
                    subName={modal.card.sub_name}
                />

                <FixedBottomWrapper>
                    <div className="footer">
                        <Button styleType="line" onClick={handleDetail}>
                            상세 페이지 이동
                        </Button>
                    </div>
                </FixedBottomWrapper>
            </ContentWrapper>
            </Wrapper>
        </ModalWrapper>
    );
}

const Wrapper = styled.div`
    border-radius: 20px 20px 0px 0px;
    padding: 0 20px;
    width: 100%;
    background: #fff;
    height: 90%;
`;

const ContentWrapper = styled(CommonBottomModalContainer)`
  position: relative;
  height: 100%;
  overflow-y: auto;
  //width: 100%;
  //height: 100%;
  //background: #fff;
  //padding: 0 20px;
    
  & .label {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .price {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .etc {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .footer {
    display: flex;
    gap: 8px;
    padding: 15px 20px;
  }
  
  & .card-info {
    position: absolute;
    padding-bottom: 100px;
  }
`;

export default CardDetailModal;
