import React from "react";
import styled, { css } from "styled-components";

interface CommonInfoProps {
  mainInfo: {
    label?: string;
    value?: string;
  };
  infoList: {
    label?: string;
    value?: React.ReactNode;
  }[];
  isBoldTitle?: boolean;
}

function CommonInfo({ mainInfo, infoList, isBoldTitle }: CommonInfoProps) {
  return (
    <Wrapper isBoldTitle={isBoldTitle}>
      <div className="main-label-wrapper">
        <label className="main-label">{mainInfo?.label || ""}</label>
        <div className="main-right-wrapper">
          <h4 className="main-value">{mainInfo?.value || ""}</h4>
        </div>
      </div>

      <div className="detail-prices-wrapper">
        {infoList.map((info, index) => (
          <div className="detail-box" key={index}>
            <div className="detail-label">{info?.label || ""}</div>
            <div className="detail-value">{info?.value || ""}</div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isBoldTitle?: boolean }>`
  & .main-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .main-label {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);

    ${(props) =>
      props.isBoldTitle &&
      css`
        color: rgba(30, 30, 30, 1);
      `}
  }

  & .main-value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(30, 30, 30, 1);
  }

  & .detail-prices-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

  & .detail-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .detail-label {
    flex: 1;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }

  & .detail-value {
    flex: 1;
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(93, 93, 93, 1);
  }
`;

export default CommonInfo;
