import React, { useRef, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import ModalWrapper from "./ModalWrapper";
import { saleKindModalState, twoButtonModalState } from "../../utils/atom";
import { useNavigate } from "react-router-dom";
import { SALE_OR_BUY_VARIABLES } from "../../utils/variables";
import useSWR from "swr";

import { ReactComponent as Close } from "../../assets/icons/close-icon.svg";
import { api } from "../../utils/api";

const SaleKindModal = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useRecoilState(saleKindModalState);
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const { data } = useSWR("/delivery/fee", async (url) => {
    const { data } = await api.get(url);
    return data.data;
  });
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleConfirm = () => {
    //@ts-ignore
    wrapperRef.current.style.bottom = "-100%";

    setTimeout(() => {
      setModal(null);
    }, 200);
  };

  const handleClickGoods = () => {
    if (modal?.onClickGoods) {
      modal.onClickGoods();
    } else {
      if (data?.basic_delivery_fee) navigate("/sale/goods");
      else
        setTwoButtonModal({
          title: "배송비 미설정",
          text: "배송비 설정 후 실물 상품, 상품팩의 판매를<br/>등록할 수 있습니다.<br/>지금 등록하시겠습니까?",
          onConfirm: () => navigate("/setting/deliveryFee"),
        });
    }

    setModal(null);
  };

  const handleClickDigital = () => {
    if (modal?.onClickDigital) {
      modal.onClickDigital();
    } else {
      if (data?.basic_delivery_fee) {
        navigate("/sale/digital");
        window.location.reload();
      } else
        setTwoButtonModal({
          title: "배송비 미설정",
          text: "배송비 설정 후 실물 상품, 상품팩의 판매를<br/>등록할 수 있습니다.<br/>지금 등록하시겠습니까?",
          onConfirm: () => navigate("/setting/deliveryFee"),
        });
    }

    setModal(null);
  };

  const modalType = useMemo(() => {
    if (modal?.type === "buy") return "buy";
    return "sale";
  }, [modal?.type]);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleConfirm();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupTitle>
          {SALE_OR_BUY_VARIABLES[modalType].title}
          <Close onClick={handleConfirm} />
        </PopupTitle>
        <ButtonArea>
          <button
            type="button"
            onClick={handleClickGoods}
            disabled={modal.disabledGoods}
          >
            <h1>{SALE_OR_BUY_VARIABLES[modalType].goods.title}</h1>
            <p>{SALE_OR_BUY_VARIABLES[modalType].goods.description}</p>
          </button>
          <button
            type="button"
            disabled={modal.disabledDigital}
            onClick={handleClickDigital}
          >
            <h1>{SALE_OR_BUY_VARIABLES[modalType].digital.title}</h1>
            <p>{SALE_OR_BUY_VARIABLES[modalType].digital.description}</p>
          </button>
        </ButtonArea>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SaleKindModal;

const ButtonArea = styled.div`
  padding: 10px 20px 20px;
  width: 100%;

  & > button {
    width: 100%;
    border-radius: 10px;
    padding: 16px 14px;
    border: 1px solid ${(props) => props.theme.color.gray7};
    text-align: left;

    & > h1 {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: -0.2px;
      color: #000;
      margin-bottom: 10px;
    }

    & > p {
      font-size: 13px;
      font-weight: 400;
      line-height: 1.53;
      color: ${(props) => props.theme.color.gray4};
    }

    &:nth-of-type(n + 2) {
      margin-top: 10px;
    }

    &:disabled {
      background: #f3f3f3;
      cursor: not-allowed;

      & > h1 {
        color: ${(props) => props.theme.color.gray5};
      }

      & > p {
        color: ${(props) => props.theme.color.gray6};
      }
    }
  }
`;

const PopupTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 54px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.gray1};

  & > svg {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
`;

const Wrapper = styled.div`
  position: relative;
  bottom: -100%;
  width: 100%;
  max-height: 100%;
  background: #ffffff;
  border-radius: 0;
  transition: all 0.3s;
  border-radius: 20px 20px 0 0;
`;
