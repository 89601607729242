import useSWR from "swr";

function useOrder(chargeId: string) {
  const { data, isLoading } = useSWR(
    `/order/charge/detail?charge_id=${chargeId}`
  );
  return { data, isLoading };
}

export default useOrder;
