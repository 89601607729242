import React, {useEffect, useMemo, useRef, useState} from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Title from "../../components/common/Title";
import useUser from "../../hooks/useUser";
import { ReactComponent as EmptyIcon } from "../../assets/icons/empty-cart.svg";
import {ReviewType} from "../../types/api";
import useInfiniteScroll from "../../utils/useInfiniteScroll";
import {api} from "../../utils/api";
import useSWRInfinite from "swr/infinite";
import {mergeList} from "../../utils/CommonFunction";
import ProductReviews from "../../components/detail/ProductReviews";
import MainCardSwiper from "../../components/swiper/MainCardSwiper";
import Button from "../../components/elements/Button";

function ProductReviewsContainer() {
    const content = useRef(null);
    const ref = useRef(null);
    const { cardId } = useParams();
    const [userId, setUserId] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [listData, setListData] = useState<ReviewType[]>([]);

    const [intersecting, loadFinished, setLoadFinished] = useInfiniteScroll(ref);

    const getKey = (pageIndex: number, previousPageData: any[] | null) => {
        if (previousPageData && !previousPageData.length) {
            //@ts-ignore
            setLoadFinished(true);
            return null;
        }

        return `/review/card?page=${pageIndex + 1}&order=recent&card_id=${cardId}`;
    };

    const fetcher = async (url: string) => {
        const res = await api.get(url);
        setPage(res?.data?.data?.reviews?.last_page);
        setUserId(res?.data?.data?.user_id);

        return res.data?.data?.reviews?.data;
    };

    //@ts-ignore
    const { data: list, size, setSize, mutate } = useSWRInfinite(getKey, fetcher);

    useEffect(() => {
        if (list?.length) {
            setListData(mergeList(list));
        }
    }, [list]);

    useEffect(() => {
        if (content.current !== null) {
            if (
                window.sessionStorage.getItem("listPage") &&
                window.sessionStorage.getItem("listScroll")
            ) {
                for (
                    let i = 1;
                    i <= Number(window.sessionStorage.getItem("listPage"));
                    i++
                ) {
                    setSize(i);
                }
            }
        }
    }, [content.current]);

    useEffect(() => {
        if (
            window.sessionStorage.getItem("listPage") &&
            window.sessionStorage.getItem("listScroll")
        ) {
            if (
                size >= Number(window.sessionStorage.getItem("listPage")) &&
                listData?.length > (size - 1) * 10 + 1
            ) {
                //@ts-ignore
                content.current.scrollTop = Number(
                    window.sessionStorage.getItem("listScroll")
                );

                window.sessionStorage.removeItem("listPage");
                window.sessionStorage.removeItem("listScroll");
            }
        }
    }, [listData]);

    const infiniteScroll = (e: React.UIEvent<HTMLElement>) => {
        //@ts-ignore
        const scrollHeight = e.target.scrollHeight;
        //@ts-ignore
        let scrollTop = e.target.scrollTop;
        //@ts-ignore
        const clientHeight = e.target.clientHeight;

        if (scrollTop + clientHeight >= scrollHeight - scrollTop) {
            setSize((prev: number) => prev + 1);
        }
    };

    useEffect(() => {
        if (content.current !== null) {
            //@ts-ignore
            const el = content.current.parentNode;

            if (!loadFinished && page > size && listData?.length >= size * 10) {
                el.addEventListener("scroll", infiniteScroll, true);
            }
            return () => {
                try {
                    //@ts-ignore
                    el.removeEventListener("scroll", infiniteScroll, true);
                } catch (error) {}
            };
        }
    }, [content, listData, page, size, loadFinished]);

    const navigate = useNavigate();
    const { checkLogin } = useUser();

    const handleClickArrow = checkLogin(() => {
        navigate("reviews");
    });
    if ((listData || []).length === 0) {
        return (
            <Wrapper>
                <Title title="관련 리뷰" />
                <Empty className="center column">
                    <EmptyIcon />
                    <span className="mt-16">작성된 리뷰가 없습니다.</span>
                </Empty>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Title
                title="관련 리뷰"
                arrow={listData.length > 0 ? { onClick: handleClickArrow } : undefined}
            />
            <div className="comments-wrapper">
                <ProductReviews
                    item={(listData || []) as any}
                />
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  margin-top: 40px;

  & .comments-wrapper {
    margin-top: 20px;
  }
`;

const Empty = styled.div`
  margin-top: 64px;

  & > span {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(199, 199, 199, 1);
  }

  & > p {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(58, 58, 58, 1);
  }
`;

const ButtonWrapper = styled.div`
  margin: 0 auto;
  margin-top: 17px;
  width: 200px;
`;
export default ProductReviewsContainer;
