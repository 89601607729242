import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { giftModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";

import { ReactComponent as Coupon } from "../../assets/icons/giftAlert-coupon-icon.svg";
import { ReactComponent as Product } from "../../assets/icons/giftAlert-product-icon.svg";

const GiftModal = () => {
  const [modal, setModal] = useRecoilState(giftModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      if (modal?.item.type === "product")
        setTimeout(() => {
          //@ts-ignore
          wrapperRef.current.style.bottom = "0px";
        });
    }
  }, [wrapperRef, modal]);

  const handleClose = () => {
    if (modal?.item.type === "product") {
      //@ts-ignore
      wrapperRef.current.style.bottom = "-100%";

      setTimeout(() => {
        setModal(null);
      }, 200);
    } else {
      setModal(null);
    }
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleClose();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper
      position={modal?.item.type === "product" ? "bottom" : null}
      onClose={handleClose}
    >
      <Wrapper
        ref={wrapperRef}
        className={modal?.item.type === "product" ? "product" : ""}
      >
        {modal?.item.type === "coupon" ? (
          <CouponBox>
            <Coupon />
            <h1>쿠폰이 지급되었어요!</h1>
          </CouponBox>
        ) : modal?.item.type === "point" ? (
          <PointBox>
            <h1>{modal?.item.point}코인</h1>
            <h1>TIF COIN이 지급되었어요!</h1>
          </PointBox>
        ) : (
          <CardBox>
            <Product />
            <h1>
              {modal?.item.card_type === "digital" ? "디지털 " : ""}상품
              컬렉션이
              <br />
              추가되었어요!
            </h1>
            <CardImage>
              <img src={modal?.card?.image_url} alt="" />
            </CardImage>
            <CardInfo>
              <p>{modal?.card?.category_name}</p>
              <h1>{modal?.card?.name}</h1>
            </CardInfo>
          </CardBox>
        )}
        <Button onClick={handleClose}>확인</Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default GiftModal;

const CardInfo = styled.div`
  margin-bottom: 25px;
  text-align: center;
  text-align: center;

  & > p {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6px;
    color: ${(props) => props.theme.color.gray5};
  }

  & > h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.37;
    color: ${(props) => props.theme.color.gray2};
  }
`;

const CardImage = styled.div`
  width: 242px;
  height: 242px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px auto;

  & > img {
    width: 108px;
    height: 151px;
    object-fit: contain;
  }
`;

const CardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};
    margin-top: 10px;
    text-align: center;
  }
`;

const PointBox = styled.div`
  text-align: center;

  & > h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};

    &:nth-of-type(n + 2) {
      margin: 8px 0 30px;
    }
  }
`;

const CouponBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h1 {
    margin: 8px 0 30px;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};
  }
`;

const Wrapper = styled.div`
  max-width: calc(100% - 80px);
  width: 100%;
  padding: 30px 20px 20px;
  background: #ffffff;
  border-radius: 20px;

  &.product {
    position: relative;
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    bottom: -100%;
    padding: 38px 20px 15px;
    transition: all 0.3s;
  }
`;
