import React from "react";
import styled from "styled-components";
import { CommunityType } from "../../types/product";
import CommunityCard from "./CommunityCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar } from "swiper/modules";

import "swiper/css";
import "swiper/css/scrollbar";

interface CommunitySlidesProps {
  communiteis: CommunityType[];
}

function CommunitySlides({ communiteis }: CommunitySlidesProps) {
  return (
    <Wrapper
      // @ts-ignore
      // pagination={{
      //   type: "fraction",
      // }}
      slidesPerView={"auto"}
      spaceBetween={12}
      scrollbar={true}
      modules={[Scrollbar]}
    >
      {communiteis.map((community) => (
        <SwiperSlide key={community.id} className="slide-item">
          <CommunityCard community={community} />
        </SwiperSlide>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Swiper)`
  margin-top: 16px;
  padding-bottom: 28px;

  & .slide-item {
    width: 202px;
  }
`;

export default CommunitySlides;
