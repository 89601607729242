import React, {useEffect, useRef} from "react";
import {useResetRecoilState, useSetRecoilState} from "recoil";
import styled from "styled-components";
import GachaPackageFooter from "../../components/detail/GachaPackageFooter";
import ProductDetailHeader from "../../components/detail/ProductDetailHeader";
import Core from "../../containers/base/Core";
import GachaPackageContainer from "../../containers/detail/GachaPackageContainer";
import { gachaPackagePaymentModalState } from "../../recoil/atoms/modal";
import auth from "../../utils/auth";
import {useNavigate} from "react-router-dom";
import {twoButtonModalState} from "../../utils/atom";
import { ReactComponent as Float2 } from "../../assets/icons/float2-icon.svg";

function GachaPackageDetail() {
    const content = useRef(null);

    const navigate = useNavigate();

    const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

    const reset = useResetRecoilState(gachaPackagePaymentModalState);

    // 페이지 나갔을 때 digitalPackagePaymentModal 상태값 리셋
    useEffect(() => {
        return () => {
            reset();
        };
    });

    if (!auth.getToken()) {
        setTwoButtonModal({
            title: "로그인 필요",
            text: "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
            onConfirm: () => navigate("/login"),
            onClose: () => history.back()
        });
        return (<></>);
    }

    const handleScrollTop = () => {
        //@ts-ignore
        content.current.scrollTo({ top: 0 }, "smooth");
    };

    return (
        <>
            <Wrapper ref={content}>
                <ProductDetailHeader />
                <GachaPackageContainer />
                <GachaPackageFooter />
                <FloatButton>
                    <Float2 onClick={handleScrollTop} />
                </FloatButton>
            </Wrapper>
            <Core />
        </>
    );
}

const FloatButton = styled.div`
    position: absolute;
    bottom: 73px;
    right: 13px;
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
`;

export default GachaPackageDetail;
