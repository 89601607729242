import React from "react";
import styled from "styled-components";
import Divide from "../../components/common/Divide";
import ProductCommentsContainer from "./ProductCommentsContainer";
import ProductDetailInfoContainer from "./ProductDetailInfoContainer";
import ProductExplanationContainer from "./ProductExplanationContainer";
import ProductMarketPriceContainer from "./ProductMarketPriceContainer";
import ProductRecentContainer from "./ProductRecentContainer";
import ProductRelationContainer from "./ProductRelationContainer";
import ProductSaleContainer from "./ProductSaleContainer";
import ProductReviewsContainer from "./ProductReviewsContainer";

function ProductDetailContainer() {
  return (
    <Wrapper>
      <ProductDetailInfoContainer />
      <Divide margin={20} />
      <ProductMarketPriceContainer />
      <ProductRecentContainer />
      <ProductSaleContainer />
      <ProductExplanationContainer />
      <ProductCommentsContainer />
      <ProductReviewsContainer />
      <ProductRelationContainer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 70px;
`;

export default ProductDetailContainer;
