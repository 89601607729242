import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import useSWR from "swr";
import PageLoadingComponent from "../components/common/PageLoadingComponent";
import { CouponType } from "../hooks/useCouponList";
import { paymentPayloadState } from "../recoil/atoms/payment";
import { api } from "../utils/api";

interface PaymentResponseType {
  success: boolean;
  alert: string;
  data: PaymentValueType;
}

export interface PaymentStateType {
  stockIds?: string[];
  amounts?: Record<string, string>;
  cardId?: string;
  amount?: string;
  promotionId?: string;
  promotionCardId?: string;
  couponIds: Record<string, CouponType>;
  point: number;
  money: number;
  isDigital?: boolean;
  isPromotion?: boolean;
  isTimeDeal?: boolean;
  type: "goods" | "digital" | "digital-pack" | "time-deal" | "card-break" | "promotion-basic" | "digital_pack_ticket" | "gacha_pack";
}

interface PaymentProviderProps extends PaymentStateType {
  children: React.ReactNode;
}

export interface PaymentCardStockType {
  amount: number;
  card_id: number;
  category_name: string;
  coupon_price: number | null;
  hashtag: string;
  id: number;
  image_url: string;
  is_digital: 1 | 0;
  is_pack: 1 | 0;
  is_ticket: 1 | 0;
  name: string;
  payment_price: number;
  price: number;
  total_price: number;
  user_id: number;
  nickname: string;
  profile_url?: string;
  card_name: string;
}

export interface PaymentValueType {
  coupon_price: number;
  delivery_fee: number;
  payment_price: number;
  point: number | null;
  money: number;
  total_price: number;
  use_delivery: true;
  product_price: number;
  card_stocks: PaymentCardStockType[];
}

interface PaymentValueContextType {
  payment: PaymentValueType;
  totalPaymentPrice: number;
  totalCouponPrice: number;
}

const PaymentValueContext = createContext<PaymentValueContextType | undefined>(
  undefined
);
const PaymentActionContext = createContext(undefined);

// 결제페이지에서 공용으로  사용하는 Context api
function PaymentProvider({
  children,
  stockIds,
  amounts,
  cardId,
  amount,
  couponIds,
  point,
  money,
  isDigital,
  isPromotion,
  isTimeDeal,
  promotionId,
  promotionCardId,
  type,
}: PaymentProviderProps) {
  const { data, isLoading } = useSWR<{
    payment: PaymentResponseType;
    refund: any;
    address: any;
  }>(
    [
      `/order/item`,
      {
        ...(stockIds && { card_stock_ids: stockIds }),
        ...(amounts && { card_amounts: amounts }),
        ...(cardId && { card_id: cardId }),
        ...(amount && { card_amount: amount }),
        ...(isDigital && { is_digital: 1 }),
        ...(isTimeDeal && { is_promotion_time_deal: 1 }),
        ...(isPromotion && { is_promotion: 1 }),
        ...(promotionId && { promotion_id: promotionId }),
        ...(promotionCardId && { promotion_card_id: promotionCardId }),
      },
    ],
    async ([url, params]: any) => {
      const response = await api.get(url, {
        params,
      });

      const refundResponse = await api.get("/order/refund");

      let addressResponse;

      if (type === "goods" || type === "card-break") {
        addressResponse = await api.get("/address");
      }

      return {
        payment: response.data,
        refund: refundResponse.data,
        address: addressResponse?.data,
      };
    }
  );

  const setPaymentPayload = useSetRecoilState(paymentPayloadState);

  const totalCouponPrice = useMemo(() => {
    return Object.values(couponIds).reduce(
      (prev, curr) => prev + curr.price * Number(amount ?? 1),
      0
    );
  }, [couponIds]);

  const totalPaymentPrice = useMemo(() => {
    if (type === "time-deal") {
      const promotionPayment = data?.payment.data as any;
      return (
        (promotionPayment?.promotion_card?.total_price || 0) -
        money - point -
        totalCouponPrice

      );
    }

    if (type === "card-break") {
      const promotionPayment = data?.payment.data as any;
      return (
        (promotionPayment?.promotion?.price || 0) +
        (promotionPayment?.delivery_fee || 0) -
        money - point -
        totalCouponPrice
      );
    }

    if (type === "promotion-basic") {
      const promotionPayment = data?.payment.data as any;
      return (
          (promotionPayment?.promotion?.price || 0) +
          (promotionPayment?.delivery_fee || 0) -
          money - point -
          totalCouponPrice
      );
    }

    return (
      (data?.payment.data.product_price || 0) +
      (data?.payment.data.delivery_fee || 0) -
      money - point -
      totalCouponPrice
    );
  }, [point, data?.payment, totalCouponPrice, money]);

  const values = useMemo(() => {
    if (!data) return;

    return {
      payment: data.payment.data,
      totalPaymentPrice,
      totalCouponPrice,
    };
  }, [data, totalPaymentPrice, totalCouponPrice]);

  const actions = useMemo(() => {
    return undefined;
  }, []);

  // 계좌에 대한 정보가 있다면 default값으로 넣어주기
  useEffect(() => {
    if (!data?.refund || !data.refund.success) return;
    if (
      !data?.refund.data.refund_account ||
      !data?.refund.data.refund_bank ||
      !data?.refund.data.refund_name
    )
      return;

    setPaymentPayload((oldPayload) => ({
      ...oldPayload,
      refundInfo: {
        account: data?.refund.data.refund_account,
        bank: data?.refund.data.refund_bank,
        owner: data?.refund.data.refund_name,
      },
    }));
  }, [data?.refund]);

  // 주소정보가 있다면 default 값으로 넣어주기
  useEffect(() => {
    if (!data?.address || !data.address.success) return;

    setPaymentPayload((oldPayload) => ({
      ...oldPayload,
      deliveryInfo: {
        address: data.address.data.address,
        detailAddress: data.address.data.address_detail,
        name: data.address.data.address_name,
        phone: data.address.data.address_phone,
        postCode: data.address.data.postcode,
      },
    }));
  }, [data?.address]);

  if (isLoading) return <PageLoadingComponent />;
  if (!data || !data.payment.success) {
    return <div>{data?.payment.alert || "ERROR"}</div>;
  }
  return (
    <PaymentValueContext.Provider value={values}>
      <PaymentActionContext.Provider value={actions}>
        {children}
      </PaymentActionContext.Provider>
    </PaymentValueContext.Provider>
  );
}

export function usePaymentValue() {
  const values = useContext(PaymentValueContext);
  if (!values) throw new Error("no wrapper PaymentProvider");
  return values;
}

export function usePaymentAction() {
  const actions = useContext(PaymentActionContext);
  if (!actions) throw new Error("no wrapper PaymentProvider");
  return actions;
}

export default PaymentProvider;
