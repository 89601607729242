import React from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useProductDetail } from "../../../providers/ProductDetailProvider";
import { ebayModalState } from "../../../recoil/atoms/modal";
import ProductEbayDeal from "../../detail/ProductEbayDeal";
import ProductEbayDealList from "../../detail/ProductEbayDealList";
import ModalWrapper from "../ModalWrapper";

function EbayModal() {
  const { card_ebays } = useProductDetail();
  const setModal = useSetRecoilState(ebayModalState);

  const handleClose = () => setModal(false);

  return (
    <ModalWrapper position="bottom" onClose={handleClose}>
      <Wrapper>
        <div className="header">
          <ProductEbayDeal ebayDeals={card_ebays || []} isModal />
        </div>
        <div className="container">
          <ProductEbayDealList ebayDeals={card_ebays || []} />
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);

  border-radius: 20px 20px 0 0;

  & .header {
    padding: 0 20px;
  }
  & .container {
    padding: 20px;
  }
`;

export default EbayModal;
