import React, { createContext, useContext, useMemo } from "react";
import useSWR, { KeyedMutator } from "swr";
import PageLoadingComponent from "../components/common/PageLoadingComponent";
import useUser from "../hooks/useUser";
import { ProductDetailType } from "../types/product";
import { api } from "../utils/api";
import { customToast } from "../utils/utils";
import {NotFound} from "../pages";
import {useNavigate} from "react-router-dom";

const ProductDetailContext = createContext<ProductDetailType | undefined>(
  undefined
);
type ProdcutDetailAction = {
  mutate: KeyedMutator<any>;
  toggleLike: any;
};

const ProductDetailActionContext = createContext<
  ProdcutDetailAction | undefined
>(undefined);

export function useProductDetail() {
  const data = useContext(ProductDetailContext);

  if (!data) throw new Error("not wrapped with ProductDetailProvider");

  return data;
}

export function useProductDetailAction() {
  const actions = useContext(ProductDetailActionContext);
  if (!actions) throw new Error("not wrapped with ProductDetailProvider");
  return actions;
}

interface ProductDetailProviderProps {
  children: React.ReactNode;
  cardId: string;
  isPackage?: boolean;
  stockId?: string;
}
// 상품상세페이지에서 공용으로 사용하는 Context api
function ProductDetailProvider({
  children,
  cardId,
  isPackage,
  stockId
}: ProductDetailProviderProps) {
  const navigate = useNavigate();

  const { data, isLoading, mutate } = useSWR(
    `/card/detail?card_id=${cardId}&card_stock_id=${stockId ?? '0'}`,
    async (url) => {
      const response = await api.get(url);
      return response.data;
    }
  );

  // 좋아요 , 좋아요 해제
  const toggleLike = async (cardId: string) => {
    const { data: likeData } = await api.post("/card/like", {
      card_id: cardId,
    });

    if (!likeData.success) {
      window.alert(likeData.alert);
      return;
    }

    data?.data?.card?.my_like === 0 &&
      customToast("관심 상품 목록에 추가되었어요!");
    mutate();
  };

  const actions: ProdcutDetailAction = useMemo(() => {
    return { mutate, toggleLike };
  }, [mutate, toggleLike]);

  if (isLoading) return <PageLoadingComponent />;
  //if (!data || !data.success) return <div>{data?.alert || "ERROR"}</div>;
  if (!data || !data.success) {
    navigate(`/error?message=${data?.alert}`);
  }

  return (
    <ProductDetailContext.Provider value={data.data}>
      <ProductDetailActionContext.Provider value={actions}>
        {children}
      </ProductDetailActionContext.Provider>
    </ProductDetailContext.Provider>
  );
}

export default ProductDetailProvider;
