import React from "react";
import styled from "styled-components";

interface SelectTabProps {
  selectedValue: string;
  list: { label: string; value: string }[];
  onChange: (value: string) => void;
}

function SelectTab({ selectedValue, list, onChange }: SelectTabProps) {
  const selectedClassName = (value: string) => {
    return selectedValue === value ? "selected" : "";
  };

  const handleChange = (value: string) => () => {
    onChange(value);
  };

  return (
    <Wrapper>
      {list.map(({ label, value }) => (
        <button
          className={selectedClassName(value)}
          onClick={handleChange(value)}
          key={value}
        >
          {label}
        </button>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 6px;

  & button {
    height: 29px;
    padding: 4px 12px;
    border-radius: 46px;
    /* gap: 10px; */

    color: rgba(131, 131, 131, 1);
    border: 1px solid rgba(231, 231, 231, 1);

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .selected {
    background: rgba(30, 30, 30, 1);
    border: 1px solid rgba(30, 30, 30, 1);
    color: rgba(255, 255, 255, 1);
  }
`;

export default SelectTab;
