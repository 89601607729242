import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainCardType } from "../../types/api";
import { Dot } from "../../utils/CommonFunction";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import auth from "../../utils/auth";
import { useSetRecoilState } from "recoil";
import { twoButtonModalState } from "../../utils/atom";

import { ReactComponent as Heart } from "../../assets/icons/heart-empty-icon.svg";
import { ReactComponent as HeartActive } from "../../assets/icons/heart-active-icon.svg";
import { customToast } from "../../utils/utils";

const MainCardSwiper = ({
  item,
  keyValue,
  type,
}: {
  item: MainCardType[];
  keyValue: string;
  type?: "digital_pack";
}) => {
  const navigate = useNavigate();
  const setTwoButtonModal = useSetRecoilState(twoButtonModalState);

  const [like, setLike] = useState<number[]>([]);

  const handleLike = async (id: string, index: number) => {
    if (!auth.getToken())
      setTwoButtonModal({
        title: "로그인 필요",
        text: "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
        onConfirm: () => navigate("/login"),
      });
    else {
      const formData = new FormData();
      formData.append("card_id", id);

      const {
        data: { success, alert, data },
      } = await api.post("/card/like", formData);

      if (success) {
        let arr = [...like];

        if (arr[index] === 1) {
        } else {
          customToast(`관심 상품 목록에 추가되었어요!`);
        }

        arr[index] = arr[index] === 1 ? 0 : 1;
        setLike(arr);
      } else {
        window.alert(alert);
      }
    }
  };

  const handleRouteDetailPage = (card: any) => () => {
    navigate(`/detail/${card.id}`);
  };

  useEffect(() => {
    let arr = [];
    for (const it of item) {
      arr.push(it.my_like);
    }
    setLike(arr);
  }, [item]);

  return (
    <MainSwiper
      //@ts-ignore
      slidesPerView="auto"
      spaceBetween={12}
      freeMode={true}
    >
      {item.map((item, i: number) => (
        <CardSwiper key={"card-swiper-" + keyValue + "-" + i}>
          <CardImage>
            <div onClick={handleRouteDetailPage(item)}>
              <img src={item.image_url} alt="" />
            </div>
            {like[i] === 1 ? (
              <HeartActive onClick={() => handleLike(String(item.id), i)} />
            ) : (
              <Heart onClick={() => handleLike(String(item.id), i)} />
            )}
          </CardImage>
          <TextBox onClick={handleRouteDetailPage(item)}>
            <h2>{item.category_name}</h2>
            <p>{item.name}</p>
            {item.low_card_stock ? <h1>{Dot(item.low_card_stock)} 원</h1> : <h1>-</h1>}
{/*
            <span>{type === "digital_pack" ? "판매가" : "최근 거래가"}</span>
*/}
            <span>즉시 구매가</span>
          </TextBox>
        </CardSwiper>
      ))}
    </MainSwiper>
  );
};

export default MainCardSwiper;

const TextBox = styled.div`
  margin-top: 14px;
  cursor: pointer;
  width: 202px;

  & > h2 {
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray3};
  }

  & > p {
    font-size: 13px;
    color: ${(props) => props.theme.color.gray2};
    margin-top: 8px;
  }

  & > h1 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray1};
  }

  & > span {
    margin-top: 6px;
    font-size: 10px;
    color: ${(props) => props.theme.color.gray4};
  }
`;

const CardImage = styled.div`
  position: relative;
  width: 202px;
  height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: ${(props) => props.theme.color.gray8};

  & > div {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      width: 120px;
      height: 169.5px;
      object-fit: contain;
    }
  }

  & > svg {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    z-index: 1;
  }
`;

const CardSwiper = styled(SwiperSlide)`
  width: fit-content;
  position: relative;
`;

const MainSwiper = styled(Swiper)``;
