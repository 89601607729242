import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { selectModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import { OptionType } from "../../types/types";

import { ReactComponent as Check } from "../../assets/icons/select-icon.svg";

const SelectModal = () => {
  const [modal, setModal] = useRecoilState(selectModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null) {
      setTimeout(() => {
        //@ts-ignore
        wrapperRef.current.style.bottom = "0px";
      });
    }
  }, [wrapperRef, modal]);

  const handleSelect = (value: OptionType) => {
    modal?.onSelect && modal?.onSelect(value);
    handleConfirm();
  };

  const handleConfirm = () => {
    if (wrapperRef.current !== null) {
      //@ts-ignore
      wrapperRef.current.style.bottom = "-100%";

      setTimeout(() => {
        setModal(null);
      }, 200);
    }
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      if (wrapperRef.current !== null) {
        handleConfirm();
      }
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper position="bottom" onClose={handleConfirm}>
      <Wrapper ref={wrapperRef}>
        <PopupText>
          {modal.options?.map((option, index) => (
            <List
              key={index}
              onClick={() => handleSelect(option)}
              className={
                (option.text ? option.text : option.value) === modal.value
                  ? "select"
                  : ""
              }
            >
              <span>{option.text ? option.text : option.value}
              {option.text2 ?
                  (
                      <>
                        <br/><p style={{fontWeight: "500", fontSize: "12px", color: "#838383"}}>{option.text2}</p>
                      </>
                  )
                : ""}
              </span>
              {(option.text ? option.text : option.value) === modal.value && (
                <Check />
              )}
            </List>
          ))}
        </PopupText>
      </Wrapper>
    </ModalWrapper>
  );
};

export default SelectModal;

const List = styled.button`
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 16px;
  color: ${(props) => props.theme.color.gray2};
  text-align: left;
  &.select {
    font-weight: 700;
    background: #f9f9f9;
  }
`;

const PopupText = styled.div`
  width: 100%;
  max-height: calc(var(--vh, 1vh) * 100 - 20px);
  overflow: auto;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 20px 20px 0 0;
  bottom: -100%;
  transition: all 0.3s;
  padding-top: 20px;
  max-height: 100%;
`;
