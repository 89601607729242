import React, {useEffect, useMemo, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { orderMutation, orderSaveMutation } from "../../apis/payment";
import FixedButton from "../../components/common/FixedButton";
import PaymentCardStock from "../../components/payment/PaymentCardStock";
import PaymentTerms from "../../components/payment/PaymentTerms";
import RegisterPoint from "../../components/payment/RegisterPoint";
import SelectPaymentMethod from "../../components/payment/SelectPaymentMethod";
import TotalPaymentPrice from "../../components/payment/TotalPaymentPrice";
import usePoint from "../../hooks/usePoint";
import { usePaymentValue } from "../../providers/PaymentProvider";
import {
  paymentMoneyState,
  paymentPayloadState,
  paymentPointState,
  useCouponState,
} from "../../recoil/atoms/payment";
import { alertModalState } from "../../utils/atom";
import { convertCouponIds, getThousandCommaPrice } from "../../utils/utils";
import RegisterMoney from "../../components/payment/RegisterMoney";
import useMoney from "../../hooks/useMoney";
import LoadingComponent from "../../components/common/LoadingComponent";

interface TimeDealPaymentContainerProps {}

function TimeDealPaymentContainer({}: TimeDealPaymentContainerProps) {
  const { payment, totalCouponPrice, totalPaymentPrice }: any =
    usePaymentValue();
  const { data } = usePoint();
  const { data : money } = useMoney();
  const payload = useRecoilValue(paymentPayloadState);
  const resetPayload = useResetRecoilState(paymentPayloadState);
  const paymentPoint = useRecoilValue(paymentPointState);
  const paymentMoney = useRecoilValue(paymentMoneyState);
  const navigate = useNavigate();
  const setAlertModal = useSetRecoilState(alertModalState);
  const state = JSON.parse(localStorage.getItem("state") || "{}");
  const useCoupon = useRecoilValue(useCouponState);
  const [payLoading, setPayLoading] = useState<boolean>(false);

  const productPrice = useMemo(() => {
    let amount = Number(payment?.promotion_card?.amount ?? 1);
    return payment?.promotion_card?.price * amount || 0;
  }, [payment?.promotion_card?.price]);

  const handleSubmit = async () => {
    setPayLoading(true);
    if (payload.method === "CARD" && totalPaymentPrice > 0 && totalPaymentPrice < 100) {
      setAlertModal({
        title: "결제금액 미달",
        text: "카드 결제 시 100원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    if (payload.method === "TRANS" && totalPaymentPrice > 0 && totalPaymentPrice < 150) {
      setAlertModal({
        title: "결제금액 미달",
        text: "실시간 계좌이체 시 150원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    let cardInfo;
    let refundInfo;

    if (payment?.promotion_card?.is_pack === 1) {
      cardInfo = {
        card_id: state.cardId,
        card_amount: state.amount,
      };
    } else {
      cardInfo = {
        card_stock_ids: [state.cardId],
        card_amounts: { [state.cardId]: state.amount },
      };
    }

    if (payload.method === "VBANK") {
      refundInfo = {
        refund_bank: payload.refundInfo?.bank,
        refund_account: payload.refundInfo?.account,
        refund_name: payload.refundInfo?.owner,
      };
    }

    const { data } = await orderSaveMutation({
      user_coupon_ids: convertCouponIds(useCoupon),
      point: String(paymentPoint),
      money: String(paymentMoney),
      payment_method: payload?.method as any,
      is_promotion_time_deal: 1,
      promotion_card_id: state.cardId,
      card_amount: 1,
      ...(payment?.promotion_card?.is_pack === 1 && { is_digital: 1 }),
      ...(refundInfo && { ...refundInfo }),
    });

    if (!data.success) {
      window.alert(data.alert);
      setPayLoading(false);
      return;
    }

    if (totalPaymentPrice === 0) {
      navigate(`/payment/result?success=true&id=${data?.data?.order_id}`);
      return;
    }

    orderMutation(data?.data?.order_id);
  };

  const isValid = useMemo(() => {
    if (!payload?.terms?.cancelTerm) return false;
    if (!payload?.terms?.purchaseTerm) return false;
    if (totalPaymentPrice !== 0 && !payload.method) return false;
    if (payload?.method === "VBANK" && !payload.refundInfo) return false;
    return true;
  }, [payload, totalPaymentPrice]);

  useEffect(() => {
    return resetPayload;
  }, []);

  if(payLoading) {
    return <LoadingComponent/>
  }

  return (
    <Wrapper>
      <PaymentCardStock
        cardStock={payment.promotion_card as any}
        isCoupon={true}
        paymentPrice={totalPaymentPrice}
      />

{/*      <RegisterPoint
        moneyPrice={paymentMoney}
        myPoint={data?.total_point || 0}
        paymentPrice={totalPaymentPrice}
        couponPrice={totalCouponPrice}
        productPrice={productPrice}
      />*/}
      <RegisterMoney
          myMoney={money?.total_money || 0}
          pointPrice={paymentPoint}
          paymentPrice={totalPaymentPrice}
          couponPrice={totalCouponPrice}
          productPrice={productPrice}
      />
      <TotalPaymentPrice
        couponPrice={totalCouponPrice}
        paymentPrice={totalPaymentPrice}
        point={paymentPoint}
        money={paymentMoney}
        totalPrice={productPrice}
      />
      {totalPaymentPrice !== 0 && <SelectPaymentMethod />}
      <PaymentTerms />
      <FixedButton onClick={handleSubmit} disabled={!isValid}>
        {getThousandCommaPrice(totalPaymentPrice)}원 결제
      </FixedButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 90px;
`;

export default TimeDealPaymentContainer;
