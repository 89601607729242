import moment from "moment";
import "moment/locale/ko";
import toast, { ToastPosition } from "react-hot-toast";
import { CouponType } from "../hooks/useCouponList";
import {
  CARD_GRADING_VARIABLES,
  CARD_LEVEL_BASIC_VARIABLES,
  CARD_LEVEL_VARIABLES,
  CARD_STATUS_VARIABLES
} from "./variables";
export function getThousandCommaPrice(price: string | number): string {
  if (price === "0" || !price) return "0";
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// export function getPriceGapPercent(originPrice: number, currentPrice: number) {
//   const asd = (currentPrice + originPrice) / 100;
// }

export function convertListVariables(variables: { [key in string]: string }) {
  return Object.entries(variables).map(([value, label]) => ({ value, label }));
}

export function convertLevelListVariables(variables: { [key in number]: number }) {
  return Object.entries(variables).map(([value, label]) => ({ value, label }));
}

export function fromNow(date: Date | string) {
  return moment(date).fromNow();
}

export function customToast(
  message: string,
  position?: ToastPosition | undefined
) {
  toast(message, {
    style: {
      borderRadius: "41px",
      background: "rgba(58, 58, 58, 0.7)",
      color: "#fff",
      fontSize: "13px",
      fontWeight: 400,
    },
    position: position ? position : "bottom-center",
  });
}

export function initialCardStatus() {
  return Object.keys(CARD_STATUS_VARIABLES).reduce(
    (prev: { [key in string]: boolean }, curr) => {
      prev[curr] = false;
      return prev;
    },
    {}
  );
}

export function initialCardGrading() {
  return Object.keys(CARD_GRADING_VARIABLES).reduce(
      (prev: { [key in string]: boolean }, curr) => {
        prev[curr] = false;
        return prev;
      },
      {}
  );
}

export function initialCardLevel() {
  return Object.keys(CARD_LEVEL_BASIC_VARIABLES).concat(Object.keys(CARD_LEVEL_VARIABLES)).reduce(
      (prev: { [key in string]: boolean }, curr) => {
        prev[curr] = false;
        return prev;
      },
      {}
  );
}

export function renderPrice(price?: number, suffix?: string) {
  const renderSuffix = suffix || "원";

  return price ? getThousandCommaPrice(price) + renderSuffix : "-";
}

export function onChangeNumberType(
  fn: (value: string) => void,
  disableComma?: boolean
) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value;

    const removedCommaValue: number = Number(value.replaceAll(",", ""));
    if (isNaN(removedCommaValue)) return;

    const resultValue = disableComma
      ? removedCommaValue.toString()
      : removedCommaValue.toLocaleString();

    fn(resultValue);
  };
}

export function onChangeStringType(fn: (value: string) => void) {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    fn(value);
  };
}

export function sellerSubText({
  useFree,
  freeStandard,
}: {
  useFree: boolean;
  freeStandard: number;
}) {
  if (!useFree) return "";
  return `${getThousandCommaPrice(freeStandard)}원이상 무료배송`;
}

export function convertCouponIds(coupons: Record<string, CouponType>) {
  return Object.entries(coupons).reduce((prev: any, curr) => {
    const [key, value] = curr;

    prev[key] = value.id;
    return prev;
  }, {});
}

export function renderPhoneNumber(phone: string) {
  if(phone == ''){
    return "";
  }
  return phone.slice(0, 3) + "-" + phone.slice(3, 7) + "-" + phone.slice(7);
}

export function toggleLike({
  id,
  mutate,
  key,
}: {
  id: number;
  mutate: any;
  key: string;
}) {
  mutate((oldData: any) => {
    return {
      [key]: {
        ...oldData[key],
        data: oldData[key].data.map((card: any) => {
          if (card.id === id) {
            return {
              ...card,
              my_like: card.my_like === 1 ? 0 : 1,
            };
          }
          return card;
        }),
      },
    };
  }, false);
}

export const isAndroid = () => {
  return window.navigator.userAgent.match("tif/Android") !== null;
};

export const isIOS = () => {
  return window.navigator.userAgent.match("tif/iOS") !== null;
};

export const isAndroidNew = () => {
  return /Android/i.test(navigator.userAgent);
}

export const isiOSNew = () => {
  return /iphone|ipad/i.test(navigator.userAgent);
}

export const isiOSAppNew = () => {
  return /tif/i.test(navigator.userAgent);
}

export const getPercent = (
  recentValue: number | null,
  originalValue: number
) => {
  // ??는 0일 때 값으로 인식하기 때문에 0일 때 true
  // ||는 0일 때 false값으로 인식하기 때문에 false
  const price = recentValue ?? 0;
  const originPrice = originalValue;

  if (originPrice === 0) return price;
  if (price === 0) return -100;

  let percent;

  if (price >= originPrice) {
    percent = ((price - originPrice) / originPrice) * 100;
  } else {
    percent = -((originPrice - price) / originPrice) * 100;
  }

  return Number.isInteger(percent) ? percent : Math.floor(percent * 10) / 10;
};

export const getDiscountRate = (originPrice: number, price: number) => {
  if (originPrice === 0) return price;
  return Math.floor(((originPrice - price) / originPrice) * 100);
};

export const isMobile = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  );
};

export const getBuyStatusText = ({
  status,
  type,
}: {
  status?: 0 | 1 | 2 | 3 | 4 | 10;
  type?: "digital" | "goods" | "promotion" | null;
}) => {
  if (status === undefined) return "";
  if (status === 0) return "결제대기";
//  if (type === "digital") return "구매 확정";
  if (status === 1) return "배송전";
  if (status === 2) return "배송중";
  if (status === 3) return "배송완료";
  if (status === 4) return "구매확정";
  if (status === 10) return "결제취소";
  return "";
};
