import React from "react";
import styled from "styled-components";

interface PaymentButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
}

function PaymentButton({ children, ...rest }: PaymentButtonProps) {
  return <Wrapper {...rest}>{children}</Wrapper>;
}

const Wrapper = styled.button`
  background: rgba(58, 58, 58, 1);
  min-width: 65px;
  padding: 7px 6px;

  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  border-radius: 29px;

  &:disabled {
    cursor: not-allowed;
    background-color: rgba(199, 199, 199, 1);
  }
`;

export default PaymentButton;
