import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { gachaPackagePaymentModalState } from "../../../recoil/atoms/modal";
import { customToast, getThousandCommaPrice } from "../../../utils/utils";
import CommonHeader from "../../common/CommonHeader";
import Divide from "../../common/Divide";
import Button from "../../elements/Button";
import { CommonBottomModalContainer } from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import { ReactComponent as MinusIcon } from "../../../assets/icons/minus-icon.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/file-plus-icon.svg";
import { useNavigate } from "react-router-dom";

function GachaPackagePaymentModal() {
    const [modal, setModal] = useRecoilState(gachaPackagePaymentModalState);

    const [count, setCount] = useState(1);

    const navigate = useNavigate();

    const handleClickCount = (type: "plus" | "minus") => () => {
        if (!modal) return;
        if (type === "plus") {
            if (modal?.amount.remain <= count) {
                customToast("최대 재고 수량을 초과했어요.");
                return;
            }
            if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax <= count){
                customToast("계정 당 구매수를 초과했어요.");
                return;
            }
            setCount(count + 1);
        } else {
            if (count === 0) {
                customToast("수량은 1개 이상 선택할 수 있어요.");
                return;
            }

            setCount(count - 1);
        }
    };

    const handleButtonCount = (buttonCount : number) => () => {
        if (!modal) return;

        if (modal?.amount.remain < buttonCount) {
            customToast("최대 재고 수량을 초과했어요.");
            setCount(modal?.amount.remain);
            return;
        }else if(modal?.userOrderAbleMax != -1 && modal?.userOrderAbleMax < buttonCount){
            customToast("계정 당 구매수를 초과했어요.");
            return;
        }else{
            setCount(buttonCount);
            localStorage.setItem(
                "state",
                JSON.stringify({
                    cardId: modal?.stockId,
                    amount: buttonCount,
                    gift: modal?.gift,
                    type: "gacha_pack",
                })
            );
            navigate(`/payment`);
        }
    };

    const handleClose = () => {
        setModal(null);
    };

    const handleSubmit = () => {
        //구매하기 클릭시 [A-5. 결제(디지털 카드팩)] 이동
        localStorage.setItem(
            "state",
            JSON.stringify({
                cardId: modal?.stockId,
                amount: count,
                gift: modal?.gift,
                type: "gacha_pack",
            })
        );
        navigate(`/payment`);
    };

    const isValid = useMemo(() => {
        if (count === 0) return false;
        if ((modal?.amount.remain || 0) < count) return false;
        return true;
    }, [count, modal?.amount]);

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            handleClose();
        }
    }, [location.pathname]);

    if (modal === null) return null;

    return (
        <ModalWrapper position="bottom" onClose={handleClose}>
            <Wrapper>
                <CommonHeader
                    title={modal?.gift == 1 ? "가챠팩 선물" :"가챠팩 구매"}
                    explain={modal?.gift == 1 ?
                        {
                            title: "가챠팩 선물이란?",
                            text: "구매한 가챠 다른 사람에게 선물하는 방식입니다. ",
                        }   :
                        {
                            title: "가챠팩 구매란?",
                            text: "구매한 가챠를 MY COLLECTION에 추가하는 방식입니다. ",
                        }
                    }
                    isCloseIcon={{ onClickClose: handleClose }}
                />
                <div className="container">
                    <div className="payment-info-wrapper">
                        <div className="product-name">
                            {modal.title.length > 16
                                ? modal.title.slice(0, 16) + "..."
                                : modal.title}
                        </div>
                        <div className="count-wrapper">
                            <MinusIcon
                                width={12}
                                height={12}
                                onClick={handleClickCount("minus")}
                            />
                            <span>{count}</span>
                            <PlusIcon
                                width={12}
                                height={12}
                                onClick={handleClickCount("plus")}
                            />
                        </div>
                    </div>
                    <Divide/>
                    <div className="price-wrapper">
                        <div className="price-label">결제금액</div>
                        <div className="price-value">
                            {getThousandCommaPrice(modal.price * count)}원
                        </div>
                    </div>
                    {(modal?.userOrderAbleMax == -1) ? (
                            <>
                                <div className="count-button-wrapper">
                                    <div className="count-button" onClick={handleButtonCount(10)}>
                                        <Button styleType={"line2"}>10회 구매</Button>
                                    </div>
{/*                                    <div className="count-button" onClick={handleButtonCount(100)}>
                                        <Button styleType={"line2"}>100회 구매</Button>
                                    </div>*/}
                                </div>
                            </>
                        ) :
                        (
                            <>
                                <div className="count-button-wrapper">
                                    <div className="count-button" style={{width: '100%'}}
                                         onClick={handleButtonCount(modal?.userOrderAbleMax)}>
                                        <Button styleType={"line2"}>{modal?.userOrderAbleMax}회 구매</Button>
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
                <div className="footer">
{/*                    {modal?.gift == 1 ?
                        <Button disabled={!isValid} onClick={handleSubmit}>
                            선물하기
                        </Button>
                        :
                        <Button styleType="sub" disabled={!isValid} onClick={handleSubmit}>
                            구매하기
                        </Button>
                    }*/}
                    <Button styleType="sub" disabled={!isValid} onClick={handleSubmit}>
                        구매하기
                    </Button>
                </div>
            </Wrapper>
        </ModalWrapper>
    );
}

const Wrapper = styled(CommonBottomModalContainer)`
  & .footer {
    margin-top: 10px;
    padding: 15px 0;
  }

  & .payment-info-wrapper {
    margin-top: 20px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .product-name {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(112, 112, 112, 1);
  }

  & .count-wrapper {
    width: 100px;
    height: 36px;
    display: flex;

    justify-content: space-around;
    align-items: center;

    border-radius: 10px;
    border: 1px solid rgba(231, 231, 231, 1);
  }

  & .count-button {
    font-size: 12px;
  }

  & .price-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 16px;
  }

  & .count-button-wrapper{
    display: flex;
    text-align: center;
    justify-content: space-between;
    align-items: center;

    margin-top: 25px;
  }
  
  & .count-button{
    width: 100%;  
  }

  & .count-button-new{
    width: 100%;
  }
  
  & .price-label {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .price-value {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.20000000298023224px;
    text-align: right;
    color: rgba(30, 30, 30, 1);
  }
`;

export default GachaPackagePaymentModal;
