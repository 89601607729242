import React from "react";
import styled from "styled-components";
import { ReactComponent as EmptyCartIcon } from "../../assets/icons/empty-cart.svg";

interface EmptyComponentProps {
  text: string;
}

function EmptyComponent({ text }: EmptyComponentProps) {
  return (
    <Wrapper className="center column">
      <EmptyCartIcon />
      <p className="empty-text mt-16">{text}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 25%;

  & .empty-text {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }
`;

export default EmptyComponent;
