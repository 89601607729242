import React, { useEffect, useState } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import CouponCard from "../../../containers/payment/CouponCard";
import useCommonList from "../../../hooks/useCommonList";
import useCouponList from "../../../hooks/useCouponList";
import { couponModalState } from "../../../recoil/atoms/modal";
import { useCouponState } from "../../../recoil/atoms/payment";
import EmptyComponent from "../../common/EmptyComponent";
import LoadingComponent from "../../common/LoadingComponent";
import ModalHeader from "../../common/ModalHeader";
import Target from "../../common/Target";
import { CommonBottomModalContainer } from "../../layout/CommonStyle";
import ModalWrapper from "../ModalWrapper";
import {convertCouponIds} from "../../../utils/utils";

function CouponModal() {
  const modal = useRecoilValue(couponModalState);
  const reset = useResetRecoilState(couponModalState);
  const setUseCoupon = useSetRecoilState(useCouponState);
  const usedCoupon = useRecoilValue(useCouponState);

  const { data, isLoading, target } = useCommonList({
    url: "/user/coupon",
    key: "user_coupons",
  });

  const usedCoupons = convertCouponIds(usedCoupon);

  let arr : any[] = [];
  for(const id of Object.keys(usedCoupons)){
    arr.push(usedCoupons[id]);
  }

  const handleClose = () => {
    reset();
  };

  const handleSelect = (coupon: any) => () => {
    if (!modal) return;

    let price = 0;

    if (coupon.type === "price") {
      price = coupon.price;
    } else {
      price = Math.floor((modal.price * coupon.price) / 100);
    }

    if(coupon.max_price != null && price > coupon.max_price){
      price = coupon.max_price;
    }

    if (modal.price >= price) {
      setUseCoupon((old) => ({
        ...old,
        [modal.stockId]: {
          id: coupon.id,
          price,
        },
      }));
    }

    handleClose();
  };

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      reset();
    }
  }, [location.pathname]);

  if (!modal) return null;

  if (isLoading)
    return (
      <ModalWrapper position="full">
        <Wrapper>
          <ModalHeader title="쿠폰선택" onClose={handleClose} />
          <LoadingComponent />
        </Wrapper>
      </ModalWrapper>
    );

  if ((data?.user_coupons.data.filter((item:any)=> !arr.includes(item.id)) || []).length === 0) {
    return (
      <ModalWrapper position="full">
        <Wrapper>
          <ModalHeader title="쿠폰선택" onClose={handleClose} />
          <EmptyComponent text="보유 쿠폰이 없습니다." />
        </Wrapper>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper position="full">
      <Wrapper>
        <ModalHeader title="쿠폰선택" onClose={handleClose} />
        <div className="container mt-16">
          {data?.user_coupons.data.filter((item:any)=> !arr.includes(item.id)).map((coupon: any) => (
            <CouponCard
              coupon={coupon}
              key={coupon.id}
              onClick={handleSelect(coupon)}
            />
          ))}
          <Target target={target} />
        </div>
      </Wrapper>
    </ModalWrapper>
  );
}

const Wrapper = styled(CommonBottomModalContainer)`
  background: #fff;
  height: 100%;
  overflow-y: scroll;

  & .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export default CouponModal;
