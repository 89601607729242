import React, { useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { toastModalState } from "../utils/atom";

const ToastModal = () => {
  const [modal, setModal] = useRecoilState(toastModalState);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (wrapperRef.current !== null && modal) {
      setTimeout(() => {
        if (modal?.high) {
          //@ts-ignore
          wrapperRef.current.style.bottom = "121px";
        } else {
          //@ts-ignore
          wrapperRef.current.style.bottom = "78px";
        }
      });

      setTimeout(() => {
        try {
          //@ts-ignore
          wrapperRef.current.style.bottom = "-100px";

          setTimeout(() => {
            setModal(null);
          }, 200);
        } catch (error) {}
      }, 3000);
    }
  }, [wrapperRef, modal]);

  return (
    <Modal ref={wrapperRef}>
      <p>{modal?.text}</p>
    </Modal>
  );
};

export default ToastModal;

const Modal = styled.div`
  position: absolute;
  bottom: -100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  max-width: calc(100% - 40px);
  width: fit-content;
  padding: 10px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.3s;
  background: rgba(58, 58, 58, 0.7);
  border-radius: 41px;

  & > p {
    font-size: 13px;
    line-height: 1;
    color: ${(props) => props.theme.color.white};
    white-space: nowrap;
  }
`;
