import React, { useMemo } from "react";
import styled from "styled-components";
import { CouponType } from "../../hooks/useCouponList";
import { getThousandCommaPrice } from "../../utils/utils";
import { ReactComponent as CouponIcon } from "../../assets/icons/coupon-icon.svg";

interface CouponCardProps {
  coupon: CouponType;
  onClick?: () => void;
}

function CouponCard({ coupon, onClick }: CouponCardProps) {
  const renderDiscount = useMemo(() => {
    if (coupon.type === "percent") {
      return coupon.price + "%";
    }

    return getThousandCommaPrice(coupon.price) + "원";
  }, [coupon.type, coupon.price]);

  return (
    <Wrapper onClick={onClick}>
      <div className="header">
        <CouponIcon />
        <p className="discount">{renderDiscount}</p>
      </div>
      <div className="coupon-name">{coupon.name}</div>
      <div className="end-date">
        {coupon.end_date} 까지 ( 최대 할인금액 :{" "}
        {getThousandCommaPrice(coupon.max_price) + "원"} )
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid rgba(231, 231, 231, 1);
  border-radius: 10px;

  cursor: pointer;

  padding: 20px 18px;

  & .header {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  & .discount {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .coupon-name {
    margin-top: 8px;

    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .end-date {
    margin-top: 6px;
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }
`;

export default CouponCard;
