import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import ProductDetailFooter from "../../components/detail/ProductDetailFooter";
import ProductDetailHeader from "../../components/detail/ProductDetailHeader";
import GoodsPackageContainer from "../../containers/detail/GoodsPackageContainer";
import useUser from "../../hooks/useUser";

function GoodsPackageDetail() {
  const { cardId } = useParams();
  const { checkAuth, checkLogin } = useUser();
  const navigate = useNavigate();

  const handleBuy = checkLogin(() => {
    navigate(`/buy/goods-pack/${cardId}`);
  });

  const handleSale = checkAuth(() => {
    navigate(`/sale/goods-pack/${cardId}`);
  });

  return (
    <Wrapper>
      <ProductDetailHeader />
      <GoodsPackageContainer />
      <ProductDetailFooter onBuy={handleBuy} onSale={handleSale} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: scroll;
`;

export default GoodsPackageDetail;
