import React, { useMemo } from "react";
import styled from "styled-components";
import useOrder from "../../hooks/useOrder";
import { ReactComponent as BigCheckIcon } from "../../assets/icons/big-check-icon.svg";
import ResultCard from "../../components/result/ResultCard";
import TotalPaymentPrice from "../../components/payment/TotalPaymentPrice";
import Divide from "../../components/common/Divide";
import CommonInfo from "../../components/common/CommonInfo";
import { BANK_VARIABLES, PAYMENT_METHOD } from "../../utils/variables";
import moment from "moment";
import FixedBottomLayout from "../../components/layout/FixedBottomLayout";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import PromotionCard from "../../components/result/PromotionCard";
import {getThousandCommaPrice, renderPhoneNumber} from "../../utils/utils";
import useCharge from "../../hooks/useCharge";
import {Dot} from "../../utils/CommonFunction";
interface ChargeResultContainerProps {
  chargeId: string;
}

function ChargeResultContainer({ chargeId }: ChargeResultContainerProps) {
  const { data } = useCharge(chargeId);
  const title = useMemo(() => {
    return "코인 충전이 완료되었습니다";
  }, []);


  const paymentDate = useMemo(() => {
    if (!data?.charge?.payment_date) return "-";

    return moment(data?.charge?.payment_date).format("YYYY-MM-DD (dd) HH:mm");
  }, [data?.charge?.payment_date]);

    const chargePrice = useMemo(() => {
        if (!data?.charge?.charge_price) return "-";

        return Dot(data?.charge?.charge_price);
    }, [data?.charge?.charge_price]);

    const coinPrice = useMemo(() => {
        if (!data?.point?.point) return "-";

        return Dot(data?.point?.point);
    }, [data?.point?.point]);
  const navigate = useNavigate();
  const onRouteHome = () => {
      navigate("/main");
  };
  const onRouteMyPage = () => {
      navigate("/mypage");
  };
  return (
    <Wrapper>
      <div className="header">
        <BigCheckIcon />
        <div className="title mt-14">{title}</div>
      </div>
      <div className="divide" />
      <Divide margin={16} />
      {data.charge.payment_method === "card" ? (
        <CommonInfo
          isBoldTitle={true}
          mainInfo={{
            label: "결제 정보",
          }}
          infoList={[
            {
              label: "결제 수단",
              value: "카드 결제",
            },
            {
              label: "결제 일시",
              value: paymentDate,
            },
            {
              label: "결제 금액",
              value: chargePrice + "원",
            },
            {
              label: "코인 변환 금액",
              value: coinPrice + "코인",
            },
          ]}
        />
      ) : data.charge.payment_method === "PHONE" ? (
              <CommonInfo
                  isBoldTitle={true}
                  mainInfo={{
                      label: "결제 정보",
                  }}
                  infoList={[
                      {
                          label: "결제 수단",
                          value: "휴대폰 결제",
                      },
                      {
                          label: "결제 일시",
                          value: paymentDate,
                      },
                      {
                        label: "결제 금액",
                        value: chargePrice + "원",
                      },
                      {
                        label: "코인 변환 금액",
                        value: coinPrice + "코인",
                      },
                  ]}
              />
      ) : data.charge.payment_method === "trans" ? (
              <CommonInfo
                  isBoldTitle={true}
                  mainInfo={{
                      label: "결제 정보",
                  }}
                  infoList={[
                      {
                          label: "결제 수단",
                          value: "실시간 계좌이체",
                      },
                      {
                          label: "결제 일시",
                          value: paymentDate,
                      },
                      {
                          label: "결제 금액",
                          value: chargePrice + "원",
                      },
                      {
                          label: "코인 변환 금액",
                          value: coinPrice + "코인",
                      },
                  ]}
              />
      ) : (
        <CommonInfo
          isBoldTitle={true}
          mainInfo={{ label: "결제 정보" }}
          infoList={[
            {
              label: "결제 수단",
              value: PAYMENT_METHOD[data.charge.payment_method] ?? "-",
            },
          ]}
        />
      )}

      <Divide margin={16} />
      <FixedBottomLayout>
        <div className="buttons">
            <Button onClick={onRouteHome}>홈으로 가기</Button>
            <Button styleType="line" onClick={onRouteMyPage}>마이페이지로 가기</Button>
        </div>
      </FixedBottomLayout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 80px;

  & .header {
    padding: 40px 0;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }

  & .cards-wrapper {
  }

  & .divide {
    width: 100%;
    height: 6px;
    background-color: rgba(243, 243, 243, 1);

    width: calc(100% + 40px);
    position: relative;
    right: 20px;
  }

  & .vbank {
    text-decoration: underline;
  }

  & .buttons {
    width: 100%;
    display: flex;
    gap: 8px;
  }
`;

export default ChargeResultContainer;
