import React from "react";
import styled from "styled-components";

interface NavIconProps {
  icon: any;
  iconActive: any;
  title: string;
  isActive: boolean;
  onClick?: () => void;
}

function NavIcon({ icon, iconActive, title, isActive, onClick }: NavIconProps) {
  const Icon = icon;
  const IconActive = iconActive;

  return (
    <Wrapper onClick={onClick}>
      {isActive ? <IconActive /> : <Icon />}
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 9px;
  font-weight: 500;
  line-height: 1;
  color: ${(props) => props.theme.color.gray5};

  & > svg {
    margin-bottom: 6px;
    width: 24px;
    height: 24px;

    &.active {
      display: none;
    }
  }

  &.active {
    color: ${(props) => props.theme.color.gray1};

    & > svg {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

export default NavIcon;
