import React from "react";
import styled from "styled-components";

interface DigitalCardProps {
    imageUrl: string;
    isRare: boolean;
    isLastOne: number;
}

function DigitalCardAll({ imageUrl, isRare, isLastOne }: DigitalCardProps) {
    return (
        <Wrapper>
            <img className={isRare || (isLastOne && isLastOne == 1) ? "rare" : ""} src={imageUrl} alt="digital_card" />
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  & img {
    &.rare {
      border-radius: 8px;
      background-size: contain;
      background-image: linear-gradient(
          var(--rotate)
          , #EAEAEA, gray 43%, white);
      animation: spin 2s ease-in Infinite;
    }
    width: 70% !important;
    height: 70% !important;
    object-fit: cover;
    border-radius: inherit;
  }

  @keyframes rare {
    0% {
      box-shadow: 0px 0px 30px rgba(212, 246, 72, 1);
    }
    50% {
      box-shadow: 0px 0px 15px rgba(212, 246, 72, 0.5);
    }
    to {
      box-shadow: 0px 0px 30px rgba(212, 246, 72, 1);
    }
  }

  @keyframes spin {
    0% {
      box-shadow: 0px 0px 100px rgba(234, 234, 234, 1);
    }
    100% {
      box-shadow: 0px 0px 100px rgba(245, 235, 157, 1);
    }
    
    0% {
      --rotate: 0deg;
    }
    100%{
      --rotate: 360deg;
    }
  }
  
  @property --rotate {
    syntax: "<angle>";
    initial-value: 132deg;
    inherits: false;
  }
  
  
  &.rare {
    border-radius: 8px;
    background-image: linear-gradient(
        var(--rotate)
        , #EAEAEA, gray 43%, white);
    animation: spin 2s ease-in Infinite;
  }
`;

export default DigitalCardAll;
