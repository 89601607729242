import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";

interface ProductClassCountInfoProps {
    cardClasses: any;
    type?: string;
}

function ProductClassCountInfo({cardClasses, type}: ProductClassCountInfoProps) {
    return (
        <Wrapper>
            <div className="box">
                <label className="label">{(type && type == "gacha") ? "Opening GOODS" : "Opening Card"}</label>
            </div>
            {(type && type == "gacha") ?
                (<>
                    {cardClasses?.map((item : any) => (
                        <div className="count_box" style={{backgroundSize: (Number(item?.open_count)/Number(item?.total_count)*100).toFixed() + "% 100%" }} key={item.card_class}>
                            <div className="label">{item?.card_class ?? "-"}</div>
                            <div className="label" style={{textAlign:"right",}}>{item?.open_count}/{item?.total_count}</div>
                        </div>
                    ))}
                </>)
            :
                (<>
                    {cardClasses?.filter((item : any) => item.card_class == 'Mega Hit!').sort((a : any, b : any) => a.total_count - b.total_count).map((item : any) => (
                        <div className="count_box" style={{backgroundSize: (Number(item?.open_count)/Number(item?.total_count)*100).toFixed() + "% 100%" }} key={item.card_class}>
                            <div className="label">{item?.card_class ?? "-"}</div>
                            <div className="label" style={{textAlign:"right",}}>{item?.open_count}/{item?.total_count}</div>
                        </div>
                    ))}
                    {cardClasses?.filter((item : any) => item.card_class == 'BIG HIT').sort((a : any, b : any) => a.total_count - b.total_count).map((item : any) => (
                        <div className="count_box" style={{backgroundSize: (Number(item?.open_count)/Number(item?.total_count)*100).toFixed() + "% 100%" }} key={item.card_class}>
                            <div className="label">{item?.card_class ?? "-"}</div>
                            <div className="label" style={{textAlign:"right",}}>{item?.open_count}/{item?.total_count}</div>
                        </div>
                    ))}
                    {cardClasses?.filter((item : any) => (item.card_class == 'HIT' || item.card_class == 'Hit!')).sort((a : any, b : any) => a.total_count - b.total_count).map((item : any) => (
                        <div className="count_box" style={{backgroundSize: (Number(item?.open_count)/Number(item?.total_count)*100).toFixed() + "% 100%" }} key={item.card_class}>
                            <div className="label">{item?.card_class ?? "-"}</div>
                            <div className="label" style={{textAlign:"right",}}>{item?.open_count}/{item?.total_count}</div>
                        </div>
                    ))}
                    {cardClasses?.filter((item : any) => (item.card_class != 'BIG HIT' && item.card_class != 'HIT' && item.card_class != 'Hit!' && item.card_class != 'Mega Hit!' && item.card_class)).sort((a : any, b : any) => a.total_count - b.total_count).map((item : any) => (
                        <div className="count_box" style={{backgroundSize: (Number(item?.open_count)/Number(item?.total_count)*100).toFixed() + "% 100%" }} key={item.card_class}>
                            <div className="label">{item?.card_class ?? "-"}</div>
                            <div className="label" style={{textAlign:"right",}}>{item?.open_count}/{item?.total_count}</div>
                        </div>
                    ))}
                </>)
            }
        </Wrapper>
    );
}

const Wrapper = styled.div`
    & .box {
        margin-bottom: 20px;
        & .label {
            font-family: Noto Sans KR;
            font-size: 15px;
            font-weight: 600;
            line-height: 11px;
            letter-spacing: 0px;
            text-align: left;

            color: ${(props) => props.theme.color.gray1};
        }
    }
    
    & .count_box {
        z-index: 1;
        display: flex;
        border: 1px solid #E7E7E7;
        border-radius: 10px;
        padding: 15px 15px;
        margin-bottom: 5px;
        background-repeat: no-repeat;
        background-image: url("https://ttif.s3.ap-northeast-2.amazonaws.com/d4f648/Rectangle+104.png");
        & .label {
            width: 50%;
            font-family: Noto Sans KR;
            font-size: 14px;
            font-weight: 600;
            line-height: 11px;
            text-align: left;

            color: ${(props) => props.theme.color.gray1};
        }
    }
`;

export default ProductClassCountInfo;
