import React, { useMemo } from "react";
import styled from "styled-components";
import useOrder from "../../hooks/useOrder";
import { ReactComponent as BigCheckIcon } from "../../assets/icons/big-check-icon.svg";
import ResultCard from "../../components/result/ResultCard";
import TotalPaymentPrice from "../../components/payment/TotalPaymentPrice";
import Divide from "../../components/common/Divide";
import CommonInfo from "../../components/common/CommonInfo";
import { BANK_VARIABLES, PAYMENT_METHOD } from "../../utils/variables";
import moment from "moment";
import FixedBottomLayout from "../../components/layout/FixedBottomLayout";
import Button from "../../components/elements/Button";
import { useNavigate } from "react-router-dom";
import PromotionCard from "../../components/result/PromotionCard";
import { renderPhoneNumber } from "../../utils/utils";
interface GoodsResultContainerProps {
  orderId: string;
  type?: string;
}

function GoodsResultContainer({ orderId, type }: GoodsResultContainerProps) {
  const { data } = useOrder(orderId);

  const title = useMemo(() => {
    if (type === "promotion") return "카드브레이크 구매가 완료되었습니다.";
    if (type === "promotion_basic") return "응모가 완료되었습니다.";
    return "상품 구매가 완료되었습니다";
  }, [type]);

  const vbank = useMemo(() => {
    if (!data.order.vbank_num) return "-";

    return (
      <>
        <p className="vbank">
          {data.order.vbank_num}({data.order.vbank_bank_name})
        </p>
      </>
    );
  }, [data]);

  const expDate = useMemo(() => {
    if (!data.order?.vbank_exp_date) return "-";
    return (
      moment(data.order.vbank_exp_date).format("YYYY-MM-DD (dd) HH:mm") +
      " 까지"
    );
  }, [data.order?.vbank_exp_date]);

  const paymentDate = useMemo(() => {
    if (!data?.order?.payment_date) return "-";

    return moment(data?.order?.payment_date).format("YYYY-MM-DD (dd) HH:mm");
  }, [data?.order?.payment_date]);

  const navigate = useNavigate();

  const onRouteHome = () => {
    navigate("/main");
  };

  const onRouteDetail = () => {
    navigate(`/order/buy/${orderId}`);
  };

  return (
    <Wrapper>
      <div className="header">
        <BigCheckIcon />
        <div className="title mt-14">{title}</div>
      </div>
      {(type !== "promotion" && type === "promotion_basic") && (
        <div className="cards-wrapper">
          {(data.order?.cards || []).map((card: any) => (
            <ResultCard
              key={card.id}
              categoryName={card.category_name}
              name={card.name}
              price={card.total_price}
              nickname={card.nickname}
              imageUrl={card.image_url}
              type={card.is_pack === 1 ? "goodsPack" : "goods"}
              profileUrl={card.profile_url}
              conditions={card.card_conditions}
            />
          ))}
        </div>
      )}

      {(type === "promotion" || type === "promotion_basic") && (
        <PromotionCard promotion={data.order.promotion as any} />
      )}

      <div className="divide" />
      <TotalPaymentPrice
        couponPrice={data.order.coupon_price}
        deliveryFee={data.order.delivery_fee}
        paymentPrice={data.order.payment_price}
        money={data.order.money ?? 0}
        point={data.order.point ?? 0}
        totalPrice={data.order.product_price}
        orderDetails={data.order_details}
        disableHide
        disableBorder
        disableTotal
        isBoldTitle
      />
      <Divide margin={16} />
      {data.order.payment_method === "CARD" ? (
        <CommonInfo
          isBoldTitle={true}
          mainInfo={{
            label: "결제 정보",
          }}
          infoList={[
            {
              label: "결제 수단",
              value: "카드",
            },
            {
              label: "결제 일시",
              value: paymentDate,
            },
          ]}
        />
      ) : data.order.payment_method === "PHONE" ? (
              <CommonInfo
                  isBoldTitle={true}
                  mainInfo={{
                      label: "결제 정보",
                  }}
                  infoList={[
                      {
                          label: "결제 수단",
                          value: "휴대폰 결제",
                      },
                      {
                          label: "결제 일시",
                          value: paymentDate,
                      },
                  ]}
              />
      ) : data.order.payment_method === "tosspay" ? (
          <CommonInfo
              isBoldTitle={true}
              mainInfo={{
                  label: "결제 정보",
              }}
              infoList={[
                  {
                      label: "결제 수단",
                      value: "토스 페이",
                  },
                  {
                      label: "결제 일시",
                      value: paymentDate,
                  },
              ]}
          />
      ) : data.order.payment_method === "TRANS" ? (
              <CommonInfo
                  isBoldTitle={true}
                  mainInfo={{
                      label: "결제 정보",
                  }}
                  infoList={[
                      {
                          label: "결제 수단",
                          value: "실시간 계좌이체",
                      },
                      {
                          label: "결제 일시",
                          value: paymentDate,
                      },
                  ]}
              />
      ) : (
        <CommonInfo
          isBoldTitle={true}
          mainInfo={{ label: "결제 정보" }}
          infoList={[
            {
              label: "결제 수단",
              value: PAYMENT_METHOD[data.order.payment_method] ?? "-",
            },
            {
              label: "입금 기한",
              value: expDate,
            },
            {
              label: "입금 계좌",
              value: vbank,
            },
          ]}
        />
      )}
      <Divide margin={16} />
      {type !== "promotion_basic" &&
      <CommonInfo
        isBoldTitle={true}
        mainInfo={{
          label: "배송 주소",
        }}
        infoList={[
          {
            label: "수령인명",
            value: data.order.address_name,
          },
          {
            label: "연락처",
            value: renderPhoneNumber(data.order.address_phone),
          },
          {
            label: "주소",
            value: data.order.address + data.order.address_detail,
          },
          {
            label: "배송 요청사항",
            value: data.order.address_request,
          },
        ]}
      />
      }
      <FixedBottomLayout>
        <div className="buttons">
          <Button styleType="line" onClick={onRouteDetail}>
            구매 상세
          </Button>
          <Button onClick={onRouteHome}>홈으로 가기</Button>
        </div>
      </FixedBottomLayout>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;
  padding-bottom: 80px;

  & .header {
    padding: 40px 0;
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
  }

  & .title {
    font-family: Noto Sans KR;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
  }

  & .cards-wrapper {
  }

  & .divide {
    width: 100%;
    height: 6px;
    background-color: rgba(243, 243, 243, 1);

    width: calc(100% + 40px);
    position: relative;
    right: 20px;
  }

  & .vbank {
    text-decoration: underline;
  }

  & .buttons {
    width: 100%;
    display: flex;
    gap: 8px;
  }
`;

export default GoodsResultContainer;
