import React from "react";
import { useRecoilValue } from "recoil";
import EbayModal from "../../components/modal/detail/EbayModal";
import RecentDealModal from "../../components/modal/detail/RecentDealModal";
import {
  confirmModalState,
  digitalPackagePaymentModalState,
  ebayModalState,
  goodsPackagePaymentModalState,
  paymentModalState,
  recentDealModalState,
  saleDigitalDetailModalState,
  gachaPackagePaymentModalState
} from "../../recoil/atoms/modal";
import ConfirmModal from "../../components/modal/ConfirmModal";
import SaleKindModal from "../../components/modal/SaleKindModal";
import { saleKindModalState } from "../../utils/atom";
import SaleDigitalDetailModal from "../../components/modal/detail/SaleDigitalDetailModal";
import PaymentModal from "../../components/modal/payment/PaymentModal";
import DigitalPackagePaymentModal from "../../components/modal/payment/DigitalPackagePaymentModal";
import GachaPackagePaymentModal from "../../components/modal/payment/GachaPackagePaymentModal";
import GoodsPackagePaymentModal from "../../components/modal/payment/GoodsPackagePaymentModal";
import SelectModal from "../../components/modal/SelectModal";

// 공용 모달모음 컴포넌트
function Core() {
  const ebayModal = useRecoilValue(ebayModalState);
  const recentDealModal = useRecoilValue(recentDealModalState);
  const confirmModal = useRecoilValue(confirmModalState);
  const saleKindModal = useRecoilValue(saleKindModalState);
  const saleDigitalDetailModal = useRecoilValue(saleDigitalDetailModalState);
  const paymentModal = useRecoilValue(paymentModalState);
  const digitalPackagePaymentModal = useRecoilValue(
    digitalPackagePaymentModalState
  );
  const goodsPackagePaymentModal = useRecoilValue(
    goodsPackagePaymentModalState
  );
  const gachaPackagePaymentModal = useRecoilValue(
      gachaPackagePaymentModalState
  );

  return (
    <>
      {ebayModal && <EbayModal />}
      {recentDealModal && <RecentDealModal />}
      {confirmModal !== null && <ConfirmModal />}
      {saleKindModal !== null && <SaleKindModal />}
      {saleDigitalDetailModal && <SaleDigitalDetailModal />}
      {paymentModal !== null && <PaymentModal />}
      {digitalPackagePaymentModal !== null && <DigitalPackagePaymentModal />}
      {gachaPackagePaymentModal !== null && <GachaPackagePaymentModal />}
      {goodsPackagePaymentModal !== null && <GoodsPackagePaymentModal />}
      <SelectModal />
    </>
  );
}

export default Core;
