import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useOrder from "../../hooks/useOrder";
import OpenImage from "../../components/result/OpenImage";
import CardCollection from "../../components/result/CardCollection";

interface DigitalPackResultContainerProps {
  orderId: string;
  data: any;
}

function DigitalPackResultContainer({
  orderId,
  data,
}: DigitalPackResultContainerProps) {
  const [open, setOpen] = useState(false);
  //const { data, isLoading } = useOrder(orderId);

  const cardList = useMemo(() => {
    return (data?.user_digital_cards || []).map((card: any) => ({
      imageUrl: card.image_url,
      isRare: card.is_rare === 1,
      name: card.name,
      paybackPoint: card.payback_point,
      cardPackId: card.card_pack_id,
      isLastOne: card.is_last_one,
    }));
  }, [data]);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 3000);
/*    if(data?.order?.payment_price != 0){
      setTimeout(() => {
        setOpen(true);
      }, 2000);
    }else {
      setOpen(true);
    }*/
  }, []);

  return (
    <Wrapper>
      {!open && <OpenImage is_gacha={data?.order?.is_gacha} />}
      {open && <CardCollection cardList={cardList} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;

  background: linear-gradient(
    359.6deg,
    rgba(0, 0, 0, 0.75) 0.35%,
    #777da5 99.67%
  );
`;

export default DigitalPackResultContainer;
