import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import CommonHeader from "../../components/common/CommonHeader";
import { CommonPageWrapper } from "../../components/layout/CommonStyle";
import PaymentCore from "../../containers/base/PaymentCore";
import PaymentProvider from "../../providers/PaymentProvider";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { paymentPointState, useCouponState, paymentMoneyState } from "../../recoil/atoms/payment";
import GoodsContainer from "../../containers/payment/GoodsContainer";
import DigitalContainer from "../../containers/payment/DigitalContainer";
import GachaContainer from "../../containers/payment/GachaContainer";
import PromotionContainer from "../../containers/payment/PromotionContainer";
import TimeDealPaymentContainer from "../../containers/payment/TimeDealPaymentContainer";
import TicketContainer from "../../containers/payment/TicketContainer";

function PaymentRoute() {
  const state: any = JSON.parse(localStorage.getItem("state") || "{}");

  const { type, amount, amounts, cardId, stockIds, promotionId } = state;
  const usedCoupon = useRecoilValue(useCouponState);
  const paymentPoint = useRecoilValue(paymentPointState);
  const paymentMoney = useRecoilValue(paymentMoneyState);
  const resetUsedCoupon = useResetRecoilState(useCouponState);
  const resetPaymentPoint = useResetRecoilState(paymentPointState);
  const resetPaymentMoney = useResetRecoilState(paymentMoneyState);

  useEffect(() => {
    return () => {
      resetUsedCoupon();
      resetPaymentPoint();
      resetPaymentMoney();
    };
  }, []);

  //타임딜 결제페이지
  if (type === "time-deal") {
    return (
      <PaymentProvider
        promotionCardId={cardId}
        couponIds={usedCoupon}
        point={paymentPoint}
        money={paymentMoney}
        amount={amount}
        isTimeDeal={true}
        type={type}
      >
        <Wrapper>
          <CommonHeader title="결제" />
          <TimeDealPaymentContainer />
          <PaymentCore />
        </Wrapper>
      </PaymentProvider>
    );
  }

  //프로모션 일반유형 결제페이지
  if (type === "promotion-basic") {
    return (
        <PaymentProvider
            couponIds={usedCoupon}
            point={paymentPoint}
            money={paymentMoney}
            promotionId={promotionId}
            isPromotion={true}
            type={type}
        >
          <Wrapper>
            <CommonHeader title="결제" />
            <PromotionContainer type="promotion-basic" id={promotionId as string} />
            <PaymentCore />
          </Wrapper>
        </PaymentProvider>
    );
  }

  // 카드브레이크결제페이지
  if (type === "card-break") {
    return (
      <PaymentProvider
        couponIds={usedCoupon}
        point={paymentPoint}
        money={paymentMoney}
        promotionId={promotionId}
        isPromotion={true}
        type={type}
      >
        <Wrapper>
          <CommonHeader title="결제" />
          <PromotionContainer type="card-break" id={promotionId as string} />
          <PaymentCore />
        </Wrapper>
      </PaymentProvider>
    );
  }

  // 디지털페키지결제페이지
  if (type === "digital-pack") {
    return (
      <PaymentProvider
        cardId={cardId}
        amount={amount}
        money={paymentMoney}
        couponIds={usedCoupon}
        point={paymentPoint}
        isDigital={true}
        type={type}
      >
        <Wrapper>
          <CommonHeader title="결제" />
          <DigitalContainer isPackage={true} />
          <PaymentCore />
        </Wrapper>
      </PaymentProvider>
    );
  }

  // 가차 패키지결제페이지
  if (type === "gacha_pack") {
    return (
        <PaymentProvider
            cardId={cardId}
            amount={amount}
            money={paymentMoney}
            couponIds={usedCoupon}
            point={paymentPoint}
            isDigital={true}
            type={type}
        >
          <Wrapper>
            <CommonHeader title="결제" />
            <GachaContainer isPackage={true} />
            <PaymentCore />
          </Wrapper>
        </PaymentProvider>
    );
  }

  // 디지털페키지결제페이지
  if (type === "digital_pack_ticket") {
    return (
        <PaymentProvider
            cardId={cardId}
            amount={amount}
            money={paymentMoney}
            couponIds={usedCoupon}
            point={paymentPoint}
            isDigital={true}
            type={type}
        >
          <Wrapper>
            <CommonHeader title="결제" />
            <TicketContainer isPackage={true} />
            <PaymentCore />
          </Wrapper>
        </PaymentProvider>
    );
  }

  // 디지털카드 , 실물카드, 실물카드팩 결제페이지
  return (
    <PaymentProvider
      stockIds={stockIds}
      amounts={amounts}
      couponIds={usedCoupon}
      point={paymentPoint}
      money={paymentMoney}
      type={type}
    >
      <Wrapper>
        <CommonHeader title="결제" />
        {type === "digital" ? <DigitalContainer /> : <GoodsContainer />}
        <PaymentCore />
      </Wrapper>
    </PaymentProvider>
  );
}

const Wrapper = styled(CommonPageWrapper)``;

export default PaymentRoute;
