import { FilterType } from "../containers/usershop/UserShopContainer";

export const MONTH_VARIABLES = {
  "1": "1개월",
  "3": "3개월",
  "6": "6개월",
  "12": "12개월",
};

export const CARD_TYPE = {
  all: "전체",
  goods: "실물",
  digital: "디지털",
};

export const SALE_OR_BUY_VARIABLES = {
  sale: {
    title: "판매 유형 선택",
    goods: {
      title: "실물 상품 판매",
      description:
        "실물 상품이나 상품팩을 구매자에게 배송하여 판매하는 방식으로, 한번에 다량의 상품을 등록할 수 있습니다.",
    },
    digital: {
      title: "디지털 상품 판매",
      description:
        "보유한 MY COLLECTION의 상품을 판매하는 방식으로, 한번에 다량의 상품을 등록할 수 있습니다.",
    },
  },

  buy: {
    title: "구매 유형 선택",
    goods: {
      title: "실물 상품 구매",
      description: "판매유저에게 실물 상품을 구매하여 배송받는 방식입니다.",
    },
    digital: {
      title: "디지털 상품 구매",
      description: "구매한 상품을 디지털 상품에 추가하는 방식입니다.",
    },
  },
};

export const CARD_STATUS_VARIABLES = {
  하자없음: "하자없음",
  스크래치: "스크래치",
  휘어짐: "휘어짐",
  오염: "오염",
  "모서리(엣징)": "모서리 (엣징)",
};

export const CARD_GRADING_VARIABLES = {
  일반: "일반",
  PSA: "PSA",
  BRG: "BRG",
  ETC: "ETC",
};

export const CARD_LEVEL_BASIC_VARIABLES = {
  5: 5,
  4: 4,
  3: 3,
  2: 2,
  1: 1,
};

export const CARD_LEVEL_VARIABLES = {
  10: 10,
  9.5: 9.5,
  9: 9,
  8.5: 8.5,
  8: 8,
};


export type CardType = "goods" | "digital";

export const CARD_LABEL_VARIABLES = {
  goods: "상품",
  digital: "디지털 상품",
  goodsPack: "상품팩",
  digitalPack: "디지털 상품팩",
  promotion: "프로모션",
};

export const PAYMENT_METHOD: any = {
/*
  VBANK: "무통장 입금",
*/
  tosspay: "토스 페이",
  CARD: "카드 결제",
  //PHONE: "휴대폰 결제",
  //TRANS: "실시간 계좌이체"
};

export const CHARGE_METHOD: any = {
  card: "카드 결제",
  //phone: "휴대폰 결제",
  trans: "실시간 계좌이체"
};

export const BANK_VARIABLES: any = {
  "001": "한국은행",
  "002": "산업은행",
  "003": "기업은행",
  "004": "국민은행",
  "005": "외환은행",
  "007": "수협은행",
  "008": "수출입은행",
  "011": "농협은행",
  "012": "농협회원조합",
  "020": "우리은행",
  "023": "SC제일은행",
  "026": "서울은행",
  "027": "한국씨티은행",
  "031": "대구은행",
  "032": "부산은행",
  "034": "광주은행",
  "035": "제주은행",
  "037": "전북은행",
  "039": "경남은행",
  "045": "새마을금고연합회",
  "048": "신협중앙회",
  "050": "상호저축은행",
  "051": "기타 외국계은행",
  "052": "모건스탠리은행",
  "054": "HSBC은행",
  "055": "도이치은행",
  "056": "알비에스피엘씨은행",
  "057": "제이피모간체이스은행",
  "058": "미즈호코퍼레이트은행",
  "059": "미쓰비시도쿄UFJ은행",
  "060": "BOA",
  "061": "비엔피파리바은행",
  "062": "중국공상은행",
  "063": "중국은행",
  "064": "산림조합",
  "065": "대화은행",
  "071": "우체국",
  "076": "신용보증기금",
  "077": "기술신용보증기금",
  "081": "하나은행",
  "088": "신한은행",
  "089": "케이뱅크",
  "090": "카카오뱅크",
  "092": "토스뱅크",
  "093": "한국주택금융공사",
  "094": "서울보증보험",
  "095": "경찰청",
  "099": "금융결제원",
  "209": "동양종합금융증권",
  "218": "현대증권",
  "230": "미래에셋증권",
  "238": "대우증권",
  "240": "삼성증권",
  "243": "한국투자증권",
  "247": "NH투자증권",
  "261": "교보증권",
  "262": "하이투자증권",
  "263": "에이치엠씨투자증권",
  "264": "키움증권",
  "265": "이트레이드증권",
  "266": "SK증권",
  "267": "대신증권",
  "268": "솔로몬투자증권",
  "269": "한화증권",
  "270": "하나대투증권",
  "278": "신한금융투자",
  "279": "동부증권",
  "280": "유진투자증권",
  "287": "메리츠증권",
  "289": "엔에이치투자증권",
  "290": "부국증권",
  "291": "신영증권",
  "292": "엘아이지투자증권",
};

export const CARD_VARIABLES = {};

export const COMPANY_VARIABLES = {};

export const FILTER_VARIABLES: { [key in FilterType]: string } = {
  price_high: "가격 높은순",
  price_low: "가격 낮은순",
  recent: "최근 등록순",
};

export type RankTabType = "tif" | "digital" | "ebay" | "coin";

export const RANK_TAB_VARIABLES: { [key in RankTabType]: string } = {
  tif: "티프 랭킹",
  digital: "디지털 상품 랭킹",
  ebay: "이베이 랭킹 (Beta)",
  coin: "티퍼 실시간 랭킹",
};
