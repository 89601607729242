import React from "react";
import styled from "styled-components";
import ProductImages from "../../components/detail/ProductImages";
import ProductInfo from "../../components/detail/ProductInfo";
import ProductRecentPrice from "../../components/detail/ProductRecentPrice";
import ProductEbayDeal from "../../components/detail/ProductEbayDeal";
import { useProductDetail } from "../../providers/ProductDetailProvider";
import ProductRelation from "../../components/detail/ProductRelation";

function ProductDetailInfoContainer() {
  const data = useProductDetail();

  return (
    <Wrapper>
      <ProductImages images={data.card_images} width={"100%"} height={"100%"} />
      <ProductRelation images={data.relation_card_images} />
      <ProductInfo info={data.card} />
      <ProductRecentPrice
        recentPrice={data.last_deal_price}
        recentGap={data.last_deal_gap}
        lowCardStock={data?.low_card_stock?.price ?? ''}
        highCardStock={data?.high_card_stock?.price ?? ''}
        lowCardStockGradings={data?.low_card_stock_gradings}
        is_pack={data.card.is_pack}
        is_digital={data.card.is_digital ?? 0}
      />
      <ProductEbayDeal ebayDeals={data.card_ebays} />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default ProductDetailInfoContainer;
