import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { pictureDetailModalState } from "../../utils/atom";
import ZoomImage from "../elements/ZoomImage";
import ModalWrapper from "./ModalWrapper";
import { ReactComponent as Close } from "../../assets/icons/close-white-icon.svg";

// 사용방법
// setPictureDetailModalState({
//   start: 시작 index,
//   picture: 이미지 배열,
//   onClick: (index : 닫았을때 마지막으로 보고있던 이미지 인덱스) => {
//     이미지 index로 이동하는 소스코드 작성
//   },
// })

const PictureDetailModal = ({}) => {
  const [modal, setModal] = useRecoilState(pictureDetailModalState);

  const [index, setIndex] = useState<number>(0);

  const swiper = useRef(null);

  const [zoomStatus, setZoomStatus] = useState(false);

  useEffect(() => {
    if (swiper.current !== null && modal && modal?.start) {
      //@ts-ignore
      swiper.current.swiper.slideTo(modal?.start - 1, 0);
      setIndex(modal?.start - 1);
    }
  }, [modal, swiper]);

  const handleConfirm = () => {
    setModal(null);
  };

  useEffect(() => {
    if (swiper.current !== null && modal && modal?.start) {
      // @ts-ignore
      swiper.current.swiper.allowTouchMove = !zoomStatus;
    }
  }, [zoomStatus]);

  const [origin, setOrigin] = useState<string>("");

  useEffect(() => {
    if (modal) {
      setOrigin(location.pathname);
    }
  }, [modal]);

  useEffect(() => {
    if (origin !== "" && location.pathname !== origin) {
      handleConfirm();
    }
  }, [location.pathname]);

  if (modal === null) return null;

  return (
    <ModalWrapper>
      <Content
        onClick={(e) => {
          //@ts-ignore
          if (e.target.id !== "zoom_image") handleConfirm();
        }}
      >
        <CloseButton
          onClick={() => {
            modal?.onClick && modal?.onClick(index);
            handleConfirm();
          }}
        >
          <Close />
        </CloseButton>
        <Detail>
          <div>
            <DetailSwiper
                ref={swiper}
                //@ts-ignore
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={70}
                onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
            >
              {modal?.picture?.map((image, i) => (
                  <SwiperSlide key={"detail-image-" + i} virtualIndex={i + 1}>
                    <ZoomImage
                        image={image?.image_url}
                        disabledEncoding={modal?.disabledEncoding}
                        setZoomStatus={setZoomStatus}
                    />
                  </SwiperSlide>
              ))}
              {modal?.picture.length > 1 && (
                  <Paging>
                    {index + 1}/{modal?.picture?.length}
                  </Paging>
              )}
            </DetailSwiper>
            <span>더블 클릭하여 확대</span>
          </div>
        </Detail>
      </Content>
    </ModalWrapper>
  );
};

export default PictureDetailModal;

const CloseButton = styled.button`
  margin: 0 20px 12px auto;

  & > svg {
    width: 24px;
    height: 24px;
  }
`;

const DetailSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  & .swiper-slide {
    max-width: 280px;
  }
`;

const Detail = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > div > span {
    position: absolute;
    bottom: -30px;
    left: calc(50% - 54px);
    z-index: 1;
    padding: 4px 8px;
    border-radius: 33px;
    background: rgba(0, 0, 0, 0.4);

    font-weight: 500;
    font-size: 12px;
    line-height: 1;
    color: ${(props) => props.theme.color.white};
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 36px;
`;

const Paging = styled.div`
  position: absolute;
  bottom: 20px;
  right: 16px;
  z-index: 1;
  padding: 4px 8px;
  border-radius: 33px;
  background: rgba(0, 0, 0, 0.4);

  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: ${(props) => props.theme.color.white};
`;
