import axios from "axios";
import { endpoint } from "../utils/api";

export function kakaoLoginMutation(token: string) {
  const formData = new FormData();

  formData.append("token", token);
  return axios
    .post(`${endpoint}/kakao/login`, formData)
    .then((response) => response.data);
}

export function appleLoginMutation(code: string) {
  const formData = new FormData();

  formData.append("code", code);

  return axios
    .post(`${endpoint}/apple/login`, formData)
    .then((response) => response.data);
}
