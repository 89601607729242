import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Preview from "../../pages/Preview";
import { RouteType } from "../../types/types";
import { useSetRecoilState } from "recoil";

import TwoButtonModal from "../modal/TwoButtonModal";
import TermModal from "../modal/TermModal ";
import AlertModal from "../modal/AlertModal ";
import ToastModal from "../../pages/ToastModal";
import SelectModal from "../modal/SelectModal";
import CommunityMenuModal from "../modal/CommunityMenuModal";
import CommentMenuModal from "../modal/CommentMenuModal";
import PostModal from "../modal/PostModal";
import ReportModal from "../modal/ReportModal";
import PictureDetailModal from "../modal/PictureDetailModal";
import BaesCore from "../../containers/base/BaesCore";
import ReviewMenuModal from "../modal/ReviewMenuModal";
import ProfileModal from "../modal/ProfileModal";
import GiftModal from "../modal/GiftModal";
import ConfirmModal from "../modal/ConfirmModal";
import PageLoadingComponent from "../common/PageLoadingComponent";
import useUser from "../../hooks/useUser";
import { confirmModalState } from "../../recoil/atoms/modal";
import GiftAllModal from "../modal/GiftAllModal";

const PrivateRoute = ({ element, ...props }: RouteType) => {
  const navigate = useNavigate();

  const setConfirmModal = useSetRecoilState(confirmModalState);

  const { user, isLoading } = useUser();

  useEffect(() => {
    if (isLoading) return;
    if (user) return;

    // 로그인안되어있는 유저일 때
    setConfirmModal({
      title: "로그인 필요",
      description:
        "로그인 후 접근 가능한 페이지입니다.<br/>로그인하시겠습니까?",
      onConfirm: () => navigate("/login"),
      onCancel: () => navigate("/main"),
    });
  }, [isLoading, user]);

  if (isLoading) return <PageLoadingComponent />;

  if (!user) return <ConfirmModal />;

  return (
    <Preview>
      {element}
      <BaesCore />

      <CommunityMenuModal />
      <CommentMenuModal />
      <ReviewMenuModal />
      <ProfileModal />
      <GiftModal />
      <GiftAllModal />

      <AlertModal />
      <TwoButtonModal />
      <TermModal />
      <ToastModal />
      <SelectModal />
      <ReportModal />
      <PostModal />

      <PictureDetailModal />
      <ConfirmModal />
    </Preview>
  );
};

export default PrivateRoute;
