import React, { useMemo } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { couponModalState } from "../../recoil/atoms/modal";
import { useCouponState } from "../../recoil/atoms/payment";
import { getThousandCommaPrice } from "../../utils/utils";
import ApplyCouponComponent from "../common/ApplyCouponComponent";
import SummaryCardImage from "../card/SummaryCardImage";

interface PromotionInfoProps {
  price: number;
  id: number;
  content: string;
  type?: string;
  image_url?: string;
}

function PromotionInfo({ price, id, content, type, image_url }: PromotionInfoProps) {
  const setCouponModal = useSetRecoilState(couponModalState);
  const [coupons, setCoupons] = useRecoilState(useCouponState);

  const isApplyCoupon = useMemo(() => {
    return !!coupons[id];
  }, [coupons, id]);

  const couponText = useMemo(() => {
    return coupons[id] ? "취소" : "쿠폰선택";
  }, [coupons, id]);

  const couponPrice = useMemo(() => {
    if (!coupons[id]) return undefined;
    return coupons[id].price;
  }, [coupons, id]);

  const cancelCoupon = () => {
    const newCoupons = { ...coupons };

    delete newCoupons[id];

    setCoupons(newCoupons);
  };

  const openCouponList = () => {
    setCouponModal({
      stockId: Number(id),
      price: Number(price),
    });
  };

  const handleCoupon = () => {
    if (isApplyCoupon) {
      cancelCoupon();
    } else {
      openCouponList();
    }
  };

  return (
    <Wrapper>
      <div className="header">
        <p className="label-text">구매상품</p>
        <div className="label">프로모션</div>
      </div>

      <div className="content-wrapper">
        <PromotionImage src={image_url ?? ""} />
        <div className="content">{content}</div>
        <div className="price">{getThousandCommaPrice(price)}원</div>
      </div>

      {type !== "promotion-basic" &&
      <ApplyCouponComponent
        onApply={handleCoupon}
        price={price}
        text={couponText}
        couponPrice={couponPrice}
      />
      }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    right: 20px;
    width: calc(100% + 40px);
    height: 46px;

    padding: 0 20px;

    background: rgba(249, 249, 249, 1);
  }

  & .label-text {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .content-wrapper {
    padding: 16px 20px;
  }

  & .content {
    font-family: Noto Sans KR;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .price {
    margin-top: 7px;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 700;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(58, 58, 58, 1);
  }

  & .label {
    border: 1px solid rgba(199, 199, 199, 1);
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(93, 93, 93, 1);

    border-radius: 6px;
    padding: 6px;
  }
`;

const PromotionImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  
`
export default PromotionInfo;
