import React from "react";
import styled from "styled-components";
import Button from "../elements/Button";

interface RegisterCommentButtonProps {
  onClick?: () => void;
}

function RegisterCommentButton({ onClick }: RegisterCommentButtonProps) {
  return (
    <Wrapper>
      <Button styleType="line" onClick={onClick}>
        코멘트 등록
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 16px;
`;

export default RegisterCommentButton;
