import React from "react";
import styled from "styled-components";

interface CardConditionsProps {
  condition?: string | null;
  grading?: string | null;
  level?: string | null;
  type?: string;
}

function CardConditions({ condition, grading, level, type }: CardConditionsProps) {
  //if (!condition) return null;

  if (condition === "하자없음")
    return (
      <Wrapper>
          {(grading && grading != "null") && (
            <span className="grading active">{grading}</span>
          )}
          {(level && level != "null") && (
              <span className="level active">{grading == "일반" ? "Star." : "G "}{level}</span>
          )}
        {type !="list" && <span className="condition active">{condition}</span>}
      </Wrapper>
    );

  return (
      <Wrapper>
          {(grading && grading != "null") && (
            <span className="grading active">{grading}</span>
          )}
          {(level && level != "null") && (
              <span className="level active">{grading == "일반" ? "Star." : "G "}{level}</span>
          )}
        {type !="list" &&
        condition?.split(" ").map((condition, index) => (
            <span className="condition active" key={`${condition}_${index}`}>
          {condition}
        </span>
        ))
        }
      </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 4px;

  & .condition {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    padding: 6px;
    border-radius: 6px;

    color: rgba(93, 93, 93, 1);
    border: 1px solid rgba(231, 231, 231, 1);
  }
    
  & .condition.active {
    background: rgba(131, 131, 131, 1);
    color: rgba(255, 255, 255, 1);
  }

  & .grading {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    padding: 6px;
    border-radius: 6px;

    color: rgba(93, 93, 93, 1);
    border: 1px solid rgba(231, 231, 231, 1);
  }

  & .level {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    padding: 6px;
    border-radius: 6px;

    color: rgba(93, 93, 93, 1);
    border: 1px solid rgba(231, 231, 231, 1);
  }
    
  & .grading.active {
    background: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.gray1};
  }

  & .level.active {
    background: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.gray1};
  }
`;

export default CardConditions;
