import React from "react";
import styled from "styled-components";
import { CARD_LABEL_VARIABLES } from "../../utils/variables";
import ProfileImage from "../common/ProfileImage";

interface StockUserProfileProps {
  profileUrl?: string;
  nickname: string;
  type: "digital" | "goods" | "goodsPack" | "digitalPack" | "promotion";
}

function StockUserProfile({
  profileUrl,
  nickname,
  type,
}: StockUserProfileProps) {
  return (
    <Wrapper>
      <div className="profile-wrapper">
        <ProfileImage profileUrl={profileUrl} size="xs" />
        <span className="nickname">{nickname}</span>
      </div>
      <div className="card-label">{CARD_LABEL_VARIABLES[type]}</div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  right: 20px;

  width: calc(100% + 40px);
  height: 46px;

  padding: 0 20px;

  background: rgba(249, 249, 249, 1);

  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .profile-wrapper {
    display: flex;
    gap: 6px;

    & > .nickname {
      display: flex;
      align-items: center;
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 0px;
      text-align: left;
      color: rgba(58, 58, 58, 1);
    }
  }

  & .card-label {
    padding: 6px;
    border: 1px solid rgba(199, 199, 199, 1);

    background: #fff;

    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;

    border-radius: 6px;

    color: rgba(93, 93, 93, 1);
  }
`;

export default StockUserProfile;
