import moment from "moment";
import React from "react";
import styled from "styled-components";
import { ProdcutEbayDealType } from "../../types/product";
import { getThousandCommaPrice } from "../../utils/utils";

interface ProductEbayDealListProps {
  ebayDeals: ProdcutEbayDealType[];
}

function ProductEbayDealList({ ebayDeals }: ProductEbayDealListProps) {
  return (
    <Wrapper>
      <div className="row top">
        <div className="left">거래 일자</div>
        <div className="right">거래 금액</div>
      </div>

      {ebayDeals.map((product) => (
        <div className="row" key={product.id}>
          <div className="left">
            {moment(product.date).format("yyyy-MM-DD")}
          </div>
          <div className="right">{getThousandCommaPrice(product.price)}원</div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;

  font-family: Noto Sans KR;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;

  & .row {
    height: 36px;

    display: flex;
  }

  & .row > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & .top {
    border-top: 1px solid rgba(131, 131, 131, 1);
    border-bottom: 1px solid rgba(131, 131, 131, 1);
  }

  & .left {
    border-right: 1px solid rgba(243, 243, 243, 1);
  }

  & .left,
  .right {
    width: 50%;
  }
`;

export default ProductEbayDealList;
