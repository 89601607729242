import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import useUser from "../../hooks/useUser";
import ProfileImage from "../common/ProfileImage";

export interface Props {
  profileImage?: string | null;
  nickname: string;
  subText?: string;
  badgeImageUrl?: string | null;
  onClick?: () => void;
  userId?: number;
}

function SellerProfile({
  nickname,
  profileImage,
  subText,
  badgeImageUrl,
  userId,
}: Props) {
  const { user } = useUser();
  const navigate = useNavigate();

  const handleClick = () => {
    if (user?.user_id === userId) {
      navigate("/mypage");
      return;
    }

    navigate(`/profile/${userId}`);
  };

  return (
      <Wrapper>
        <div className="profile-image">
          <ProfileImage
              size="md"
              isBadge={!!badgeImageUrl}
              profileUrl={profileImage}
              onClick={handleClick}
          />
        </div>
        <div className="profile-info-wrapper">
          <p className="nickname" onClick={handleClick}>
            {nickname}
          </p>
          {subText && <p className="delivery-text">{subText}</p>}
        </div>
        <div className="badge-image">
          {badgeImageUrl &&
              <img src={badgeImageUrl}/>
          }
        </div>
      </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
    
  & .profile-image{
    width: 50px;    
  } 
    
  & .profile-info-wrapper {
    width: calc(80%);  
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }

  & .nickname {
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;

    color: rgba(93, 93, 93, 1);
  }

  & .delivery-text {
    font-family: Noto Sans KR;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }
    
  & .badge-image{
      width: calc(20%);
      text-align: right;
      & > img{
          width: 43px;
          height: 43px;
      }
  }  
`;

export default SellerProfile;
