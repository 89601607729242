import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";
import CardConditions from "./CardConditions";
import CardPrice from "./CardPrice";

interface CardInfoProps {
    className?: string;
    price?: number;
    condition?: string;
    grading?: string;
    contents?: string | null;
    level?: string
    categoryName?: string;
    name?: string;
    subName?: string;
}

function CardDetailInfo({ className, price, condition, grading, contents, level, categoryName, subName, name}: CardInfoProps) {
    return (
        <Wrapper className={className ? className : ""}>
            <CardContent>
                <p className="category-name">{categoryName}</p>
                <h4 className="name">{name}</h4>
                <p className="sub-name">{subName}</p>
            </CardContent>
            {price &&
                <CardPrice price={price} />
            }
            {condition && (
                <div className="mt-16">
                    <CardConditions condition={condition}
                                    grading={grading}
                                    level={level}
                    />
                </div>
            )}

            {contents &&
                (
                    <>
                        <div className="contents mt-10">
                            <h1 style={{margin: "20px 0 0 0"}}>상세 정보</h1>
                            <div
                                className="explanation ql-editor"
                                style={{padding: "12px 0px"}}
                                dangerouslySetInnerHTML={{__html: contents}}
                            />
                        </div>
                    </>)}

        </Wrapper>
    )
        ;
}

const Wrapper = styled.div`
  & .price-info {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  & .explanation {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.20000000298023224px;
      text-align: left;
      color: rgba(93, 93, 93, 1);

    & img {
      padding: 0 20px 0 0;
      width: 100%;
    }
  }
  
  & .contents {
    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
    
    & > h1{
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 600;
    }
  }
`;

const CardContent = styled.div`
  margin: 20px 0;
  
  & .category-name {
    color: rgba(93, 93, 93, 1);
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .name {
    margin-top: 5px;

    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .sub-name {
    margin-top: 4px;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }
`;


export default CardDetailInfo;
