import { atom } from "recoil";
import { v1 } from "uuid";

export type ModifyCommentType = {
  commentId: string;
  contents: string;
};

export const modifyCommentState = atom<ModifyCommentType | null>({
  key: `modifyCommentState/${v1()}`,
  default: null,
});
