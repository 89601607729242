import React, { useState, useEffect, useRef, useMemo } from "react";
import { PickListType } from "../../types/api";
import styled from "styled-components";
import useSWR from "swr";
import {ImageType, SaleProductType} from "../../types/types";
import Input from "../elements/Input";
import { Dot, toNumber } from "../../utils/CommonFunction";

import { ReactComponent as XIcon } from "../../assets/icons/box-x-icon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/sale-arrow-icon.svg";
import { ReactComponent as Check } from "../../assets/icons/apply-check-icon.svg";
import { ReactComponent as Check1 } from "../../assets/icons/apply-check-icon1.svg";
import { ReactComponent as Check2 } from "../../assets/icons/apply-check-icon2.svg";
import { ReactComponent as Check3 } from "../../assets/icons/apply-check-icon3.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-icon.svg";
import { ReactComponent as TootipIcon } from "../../assets/icons/tooltip.svg";
import { ReactComponent as Remove } from "../../assets/icons/file-image-remove-icon.svg";
import FilenameComponent from "../common/FilenameComponent";
import { Swiper, SwiperSlide } from "swiper/react";

import {
  convertLevelListVariables,
  convertListVariables,
  initialCardGrading,
  initialCardStatus
} from "../../utils/utils";
import {CARD_GRADING_VARIABLES, CARD_LEVEL_BASIC_VARIABLES, CARD_LEVEL_VARIABLES} from "../../utils/variables";
import RegisterCardImageContainer from "../../containers/transaction/RegisterCardImageContainer";
import Title from "../common/Title";
import Textarea from "../elements/Textarea";
import OutsideClickHandler from "react-outside-click-handler";

const SaleProductList = ({
  item,
  product,
  setProduct,
  index,
  option,
  setOption,
  onChange,
}: {
  item: PickListType;
  product: PickListType[];
  setProduct: (item: PickListType[]) => void;
  index: number;
  option: SaleProductType[];
  setOption: (item: SaleProductType[]) => void;
  onChange: () => void;
}) => {
  const arrowRef = useRef(null);
  const heightRef = useRef(null);
  const [errorPrice, setErrorPrice] = useState<string>("");
  const [cardStatusTitle, setCardStatusTitle] = useState<string>("");
  const [open, setOpen] = useState(true);

  const [infoOpen, setInfoOpen] = useState(false);

  const togglePopover = (toggle: boolean) => () => {
    setInfoOpen(toggle);
  };
  const handleChangeImage = (images: ImageType[]) => {
    let arr2 = [...option];
    arr2[index] = { ...arr2[index], images: images };
    setOption(arr2);
  };

  const handleRemoveImage = (image: ImageType) => {
    // @ts-ignore
    let arr = [...option[index].images];
    let arr2 = [...option];

    arr.splice(0, 1);
    arr2[index] = { ...arr2[index], images: arr };

    setOption(arr2);
  };

  useEffect(() => {
    if (arrowRef.current !== null && heightRef.current !== null) {
      if (open) {
        //@ts-ignore
        arrowRef.current.style.transform = "rotate(0deg)";
        //@ts-ignore
        heightRef.current.style.height = heightRef.current.scrollHeight + "px";
      } else {
        //@ts-ignore
        arrowRef.current.style.transform = "rotate(180deg)";
        //@ts-ignore
        heightRef.current.style.height = "0px";
      }
    }
  }, [open, option]);

  const { data } = useSWR(
    `/card?page=1&category_id=${item.category_id}&is_pack=0&is_digital=0&order=popular`
  );

  const handleCondition = (value: string) => {
    let arr = [...option];
    let str = arr[index].conditions;

    if (str?.includes(value)) {
      if (str === value) {
        str = "";
      } else {
        let split = str.split(" ");

        for (let i = 0; i < split.length; i++) {
          if (split[i] === value) {
            split.splice(i, 1);
            str = split.join(" ");
          }
        }
      }
    } else {
      str = str === "" ? value : str + " " + value;
    }

    arr[index] = { ...arr[index], conditions: str };
    setOption(arr);
  };

  const handleGrading = (value: string) => {
    let arr = [...option];
    let str = arr[index].gradings;

    if (str == value) {
      str = "";
    } else {
      str = value;
    }

    if(value == "일반"){
      arr[index] = { ...arr[index], gradings: str, levels: '5'};
      setCardStatusTitle('');
    }else {
      arr[index] = { ...arr[index], gradings: str, levels: '10'};
      setCardStatusTitle('케이스 상태');
    }

    setOption(arr);
  };

  const handleLevel = (value: string) => {
    let arr = [...option];
    let str = arr[index].levels;

    if (str == value) {
      str = "";
    } else {
      str = value;
    }

    arr[index] = { ...arr[index], levels: str };

    setOption(arr);
  };

/*  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files: any = e.target.files;
    //@ts-ignore
    let arr = [...option[index].images];

    for (const file of files) {
      arr.push({
        file: file,
        preview_url: URL.createObjectURL(file),
      });
    }

    let arr2 = [...option];
    arr2[index] = { ...arr2[index], images: arr };
    setOption(arr2);
  };

  const filename = useMemo(() => {
    return (
      option[index].images?.[0]?.file !== "" &&
      (option[index].images?.[0]?.file as any)?.name
    );
  }, [option[index]]);

  const handleRemoveImage = () => {
    //@ts-ignore
    let arr = [...option[index].images];
    let arr2 = [...option];

    arr.splice(0, 1);
    arr2[index] = { ...arr2[index], images: arr };

    setOption(arr2);
  };*/

  // @ts-ignore
  // @ts-ignore
  return (
    <ListWrapper>
      <Header>
        판매 상품 {index + 1}
        <ButtonArea>
          {index > 0 && (
            <XIcon
              onClick={() => {
                let arr = [...product];
                let arr2 = [...option];

                arr.splice(index, 1);
                arr2.splice(index, 1);

                setProduct(arr);
                setOption(arr2);
              }}
            />
          )}
          <div onClick={() => setOpen(!open)}>
            <Arrow ref={arrowRef} />
          </div>
        </ButtonArea>
      </Header>
      <Wrapper ref={heightRef}>
        <CardBox>
          <div>
            <Image>
              <img src={item?.image_url} alt="" />
            </Image>
            <CardInfo>
              <h1>{data?.category?.name}</h1>
              <p>{item?.name}</p>
            </CardInfo>
            <button type="button" onClick={onChange}>
              변경
            </button>
          </div>
        </CardBox>
        <FormBox className={product.length === index + 1 ? "last" : ""}>
          {option[index].type === 0 && (
            <>
              <RegisterCardImageContainer
                  //@ts-ignore
                  images={option[index].images}
                  onChange={handleChangeImage}
                  onRemove={handleRemoveImage}
                  index={index}
              />

              <Label key={'register_grading' + index} style={{ justifyContent: "space-between" }}>
                그레이딩 유형
              </Label>
              <Conditions>
                {convertListVariables(CARD_GRADING_VARIABLES).map(({ value , label }) => (
                    <button
                        key={'grading_btn_' + value}
                        type="button"
                        className={
                          option[index].gradings == value || (option[index].gradings === "" && value.includes("일반")) ?
                              value == "PSA" && option[index].gradings == "PSA" ? "active1" :
                              value == "BRG" && option[index].gradings == "BRG" ? "active2" :
                              value == "ETC" && option[index].gradings == "ETC" ? "active3" :
                              "active" : ""
                        }
                        onClick={() => handleGrading(value)}
                    >
                      {(value == "일반" && (option[index].gradings == "일반" || option[index].gradings == "")) && <Check/>}
                      {value == "PSA" && option[index].gradings == "PSA" && <Check1/>}
                      {value == "BRG" && option[index].gradings == "BRG" && <Check2/>}
                      {value == "ETC" && option[index].gradings == "ETC" && <Check3/>}

                      {value}
                    </button>
                ))}
              </Conditions>

              <div className="level-div">
              <Label key={'register_level' + index} style={{ justifyContent: "space-between", marginTop: "0" }}>
                상품 등급
              </Label>
              <InfoIcon className="info-icon" onClick={togglePopover(true)} />

              {infoOpen && (
                  <OutsideClickHandler onOutsideClick={togglePopover(false)}>
                    <div className="explain">
                      <TootipIcon className="tooltip-icon" />
                      <b>티프 컨디션 가이드</b>
                      {(option[index].gradings == "PSA" || option[index].gradings == "BRG" ||
                      option[index].gradings == "ETC")
                          ? (
                          <p>판매 등록할 제품의 그레이딩 등급를 선택해주세요.</p>
                      ) : (
                          <p>Star.5 : 거의 완벽한 상태, 개봉 즉시 실드 보관된 제품<br/>
                            Star.4 : 좋은 상태, 미세한 흠집 및 기스가 있을 수 있음<br/>
                            Star.3 : 양호한 상태, 약간의 흠집 및 눌림이 있을 수 있음<br/>
                            Star.2 : 손상이 있는 상태, 사용 흔적, 흠집 및 눌림이 있음<br/>
                            Star.1 : 나쁜 상태, 심각한 손상이 있는 상태<br/>
                            <br/>
                            티프에서 제공하는 상품 컨디션의 정보는 원할한 앱 내의<br/>
                            활동을 목적으로 자체적으로 설정한 정보입니다.
                          </p>
                      )
                      }
                    </div>
                  </OutsideClickHandler>
              )}
              </div>
              <LevelSwiperArea
                  //@ts-ignore
                  slidesPerView={"auto"}
                  spaceBetween={7.5}
              >
                {!cardStatusTitle ? (
                        convertLevelListVariables(CARD_LEVEL_BASIC_VARIABLES).sort((a, b) => b.label - a.label).map(({
                                                                                                                       value,
                                                                                                                       label
                                                                                                                     }) => (
                            <LevelSlide
                                key={"level_slide_" + value}
                        style={{cursor: "pointer"}}
                        onClick={() => handleLevel(value)}
                    >
                      <button
                          key={'level_btn_' + value}
                          type="button"
                          className={option[index].levels === value || (value == "5" && option[index].levels == "") ? "active" : ""}
                          onClick={() => handleLevel(value)}
                      >
                        {(value == "5" && (option[index].levels == "5" || option[index].levels == "")) && <Check/>}
                        {value == "4" && option[index].levels == "4" && <Check/>}
                        {value == "3" && option[index].levels == "3" && <Check/>}
                        {value == "2" && option[index].levels == "2" && <Check/>}
                        {value == "1" && option[index].levels == "1" && <Check/>}

                        &nbsp;Star.{label}
                      </button>
                    </LevelSlide>
                  ))
                    )
                :
                    convertLevelListVariables(CARD_LEVEL_VARIABLES).sort((a, b) => b.label - a.label).map(({ value , label }) => (
                          <LevelSlide
                              key={"level_slide_" + value}
                              style={{cursor: "pointer"}}
                              onClick={() => handleLevel(value)}
                          >
                            {/*                      <h1>star.{label}</h1>*/}
                            <button
                                key={'level_btn_' + value}
                                type="button"
                                className={option[index].levels === value || (value == "10" && option[index].levels == "") ? "active" : ""}
                                onClick={() => handleLevel(value)}
                            >
                              {(value == "10" && (option[index].levels == "10" || option[index].levels == "")) && <Check/>}
                              {value == "9.5" && option[index].levels == "9.5" && <Check/>}
                              {value == "9" && option[index].levels == "9" && <Check/>}
                              {value == "8.5" && option[index].levels == "8.5" && <Check/>}
                              {value == "8" && option[index].levels == "8" && <Check/>}

                              &nbsp;G {label}
                            </button>
                          </LevelSlide>
                      ))
                }
              </LevelSwiperArea>

              {option[index].images?.length === 0 ? (
                <Label>
                  <b>{cardStatusTitle ? cardStatusTitle : '상품 상태'}</b>
{/*                  <label>
                    이미지 첨부(필수)
                    <input
                      type="file"
                      onChange={handleImage}
                      accept="image/*"
                    />
                  </label>*/}
                </Label>
              ) : (
                <Label style={{ justifyContent: "space-between" }}>
                  {cardStatusTitle ? cardStatusTitle : '상품 상태'}
{/*                  <FilenameComponent
                    filename={filename}
                    onRemove={handleRemoveImage}
                  />*/}
                </Label>
              )}
              <Conditions>
                <button
                    type="button"
                    className={option[index].conditions === "" ? "active" : ""}
                    onClick={() => {
                      let arr = [...option];
                      arr[index] = {...arr[index], conditions: ""};
                      setOption(arr);
                    }}
                >
                  {option[index].conditions === "" && <Check/>}
                  하자없음
                </button>
                <button
                  type="button"
                  className={
                    option[index].conditions?.includes("스크래치")
                      ? "active"
                      : ""
                  }
                  onClick={() => handleCondition("스크래치")}
                >
                  {option[index].conditions?.includes("스크래치") && <Check />}
                  스크래치
                </button>
                <button
                  type="button"
                  className={
                    option[index].conditions?.includes("휘어짐") ? "active" : ""
                  }
                  onClick={() => handleCondition("휘어짐")}
                >
                  {option[index].conditions?.includes("휘어짐") && <Check />}
                  휘어짐
                </button>
                <button
                  type="button"
                  className={
                    option[index].conditions?.includes("오염") ? "active" : ""
                  }
                  onClick={() => handleCondition("오염")}
                >
                  {option[index].conditions?.includes("오염") && <Check />}
                  오염
                </button>
                <button
                  type="button"
                  className={
                    option[index].conditions?.includes("모서리(엣징)")
                      ? "active"
                      : ""
                  }
                  onClick={() => handleCondition("모서리(엣징)")}
                >
                  {option[index].conditions?.includes("모서리(엣징)") && (
                    <Check />
                  )}
                  모서리 (엣징)
                </button>
              </Conditions>
            </>
          )}
          <Label>거래 가격</Label>
          <Form>
            {option[index].type === 1 && (
              <>
                <Input
                  label="판매 수량"
                  placeholder="0"
                  //@ts-ignore
                  value={option[index].amounts}
                  borderRadius="10px 10px 0 0"
                  borderBottom="0"
                  onChange={(e) => {
                    let value = toNumber(e.target.value).slice(0, 10);

                    if (value.length <= 10) {
                      let arr = [...option];
                      arr[index] = {
                        ...arr[index],
                        amounts:
                          Dot(Number(value)) === "0" ? "" : Dot(Number(value)),
                      };
                      setOption(arr);
                    }
                  }}
                />
                <span />
              </>
            )}
            <Input
              label={option[index].type === 0 ? "판매가" : "개당 판매가"}
              placeholder={item?.price ? Dot(item?.price) : "0"}
              unit="원"
              value={option[index].prices}
              borderRadius="0 0 10px 10px"
              onChange={(e) => {
                let value = toNumber(e.target.value).slice(0, 10);

                // @ts-ignore
                if (value < 500) {
                  setErrorPrice("판매가격은 500원 이상부터 가능합니다.");
                }else {
                  setErrorPrice("");
                }

                if (value.length <= 10) {
                  let arr = [...option];
                  arr[index] = {
                    ...arr[index],
                    prices:
                      Dot(Number(value)) === "0" ? "" : Dot(Number(value)),
                  };
                  setOption(arr);
                }
              }}
            />
          </Form>
          {errorPrice !== "" && <ErrorText>{errorPrice}</ErrorText>}
            <Label style={{ justifyContent: "space-between" }}>
              세부 설명
            </Label>
            <Textarea
                // @ts-ignore
                value={option[index].contents}
                onChange={(e) => {
                  let value = e.target.value.slice(0, 200);

                  let arr = [...option];
                  arr[index] = {...arr[index], contents: value};

                  setOption(arr);

                }}
                className="line mt-14"
                placeholder="상품의 상태, 구입일 등 자세한 정보를 기재해주세요."
            />
            <div className="contents-count text-gray5">
              <span className="count">{option[index].contents?.length}</span>
              <span>/</span>
              <span>200</span>
            </div>
        </FormBox>
      </Wrapper>
    </ListWrapper>
  );
};

export default SaleProductList;

const Form = styled.form`
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.color.gray7};

  & > span {
    width: 100%;
    height: 1px;
    display: block;
    background: ${(props) => props.theme.color.gray7};
  }
`;

const ImageList = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  position: relative;
  top: 5px;

  & > div {
    width: 127px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background: ${(props) => props.theme.color.gray8};
    margin-right: 5px;
    margin-bottom: 5px;

    & > p {
      font-size: 12px;
      font-weight: 500;
      color: ${(props) => props.theme.color.gray2};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > svg {
      margin-left: 8px;
      width: 10px;
      min-width: 10px;
      height: 10px;
    }
  }
`;

const Conditions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  
  & > button {
    width: calc(50% - 5px);
    height: 42px;
    border: 1px solid ${(props) => props.theme.color.gray6};
    background: ${(props) => props.theme.color.white};
    font-size: 13px;
    font-weight: 500;
    border-radius: 10px;
    color: ${(props) => props.theme.color.gray4};

    &.active {
      font-weight: 700;
      color: ${(props) => props.theme.color.gray1};
      border-color: #000;
      
      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active1 {
      font-weight: 700;
      color: #ED3C34;
      border-color: #ED3C34;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active2 {
      font-weight: 700;
      color: #3C74AE;
      border-color: #3C74AE;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active3 {
      font-weight: 700;
      color: #838383;
      border-color: #838383;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }
    
    &:nth-of-type(n + 3) {
      margin-top: 10px;
    }
  }
`;

const Label = styled.h1`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-size: 15px;
  font-weight: 700;
  color: ${(props) => props.theme.color.gray1};
  margin-bottom: 14px;
  margin-top: 14px;

  & > b {
    flex: 1;
  }

  & > p {
    font-weight: 400;
    font-size: 13px;
    margin-right: 6px;
  }

  & label {
    font-size: 13px;
    font-weight: 400;
    text-decoration-line: underline;
    color: ${(props) => props.theme.color.gray1};

    & > input {
      display: none;
    }
  }
`;

const FormBox = styled.div`
  padding: 26px 20px 30px;

  &.last {
    padding: 26px 20px 20px;
  }

  & .contents-count {
    margin-top: 8px;
    text-align: right;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
  }

  & .count {
    color: rgba(30, 30, 30, 1);
    font-weight: 500;
  }
  
`;

const CardInfo = styled.div`
  width: 100%;
  margin-right: 12px;
  overflow: hidden;

  & > h1 {
    font-size: 13px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray3};
    margin-bottom: 8px;
  }

  & > p {
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => props.theme.color.gray2};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Image = styled.div`
  width: 64px;
  min-width: 64px;
  height: 64px;
  border-radius: 14px;
  background: ${(props) => props.theme.color.gray8};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  & > img {
    width: 25px;
    height: 36px;
    object-fit: contain;
  }
`;

const CardBox = styled.div`
  padding: 0 20px;

  & > div {
    padding: 16px 0;
    border-bottom: solid 1px ${(props) => props.theme.color.gray8};
    display: flex;
    align-items: center;

    & > button {
      padding: 7px 8px;
      white-space: nowrap;
      border: solid 1px ${(props) => props.theme.color.gray7};
      font-size: 12px;
      font-weight: 400;
      color: ${(props) => props.theme.color.gray1};
    }
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  transition: all 0.3s;

  & .level-div{
    position: relative;
    display: flex;
  }

  & .info-icon {
    margin-bottom: 10px;
    margin-left: 4px;
    justify-content: space-between;
  }

  & .explain {
    position: absolute;
    top: 30px;
    left: 1px;

    //transform: translateX(-25%);

    width: calc(100%);

    padding: 10px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 2;

    background: rgba(94, 94, 94, 0.9);

    color: #fff;

    & b {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }

    & p {
      font-family: Noto Sans KR;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  & .tooltip-icon {
    position: absolute;
    top: -10px;
    left: 1px;
  }
  
`;

const ButtonArea = styled.div`
  display: flex;
  align-items: center;

  & > svg {
  }

  & > div {
    padding: 4px;
    border-radius: 4px;
    border: solid 1px ${(props) => props.theme.color.gray7};
    cursor: pointer;
    margin-left: 8px;

    & > svg {
      transition: all 0.3s;
    }
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.color.gray2};
  border-bottom: solid 6px ${(props) => props.theme.color.gray8};
`;

const ListWrapper = styled.div`
  &:nth-of-type(n + 2) {
    border-top: solid 6px ${(props) => props.theme.color.gray8};
  }
`;

const ErrorText = styled.div`
  margin-top: 12px;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.color.system2};
`;

const LevelSwiperArea = styled(Swiper)`
  padding: 0px 20px 20px 0;

  & .swiper-pagination {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 20px;

    width: calc(100% - 40px);
    height: 2px;
    background: ${(props) => props.theme.color.gray7};

    & .swiper-pagination-progressbar-fill {
      background: ${(props) => props.theme.color.gray1};
    }
  }
`;

const LevelSlide = styled(SwiperSlide)`
  border-radius: 10px;
  background: ${(props) => props.theme.color.white};
  font-size: 13px;
  font-weight: 500;
  height: 42px;
  //line-height: 1.23;
  color: ${(props) => props.theme.color.gray3};
  text-align: center;
  //padding: 11px 10px;
  width: 100px;

  & > h1 {
    margin-top: 6px;
    font-size: 15px;
    font-weight: 700;
    color: ${(props) => props.theme.color.gray3};
  }

  & > button {
    width: 100%;
    height: 100%;
    border: 1px solid ${(props) => props.theme.color.gray6};
    background: ${(props) => props.theme.color.white};
    font-size: 13px;
    font-weight: 500;
    border-radius: 10px;
    color: ${(props) => props.theme.color.gray4};

    &.active {
      font-weight: 700;
      color: ${(props) => props.theme.color.gray1};
      border-color: #000;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active1 {
      font-weight: 700;
      color: #ED3C34;
      border-color: #ED3C34;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active2 {
      font-weight: 700;
      color: #3C74AE;
      border-color: #3C74AE;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &.active3 {
      font-weight: 700;
      color: #838383;
      border-color: #838383;

      & > svg {
        margin-right: 4px;
        width: 14px;
        height: 11px;
      }
    }

    &:nth-of-type(n + 3) {
      margin-top: 10px;
    }
  }
  
`;