import loadable from "@loadable/component";

//404페이지
export const NotFound = loadable(() => import("./NotFound"));

//main페이지
export const Main = loadable(() => import("./main/Main"));
export const PickList = loadable(() => import("./main/PickList"));
export const Promotion = loadable(() => import("./promotion/List"));
export const PromotionDetail = loadable(() => import("./promotion/Detail"));
export const PromotionBuyDetail = loadable(
  () => import("./promotion/PromotionDetail")
);

export const Apply = loadable(() => import("./create/Apply"));
export const Modify = loadable(() => import("./create/Modify"));

export const SaleProduct = loadable(() => import("./sale/Product"));
export const SaleProduct2 = loadable(() => import("./sale/Product2"));

//랭킹
export const Rank = loadable(() => import("./rank/Rank"));

//디지털 카드샵
export const Shop = loadable(() => import("./digital/Digital"));

//가챠샵
export const Gacha = loadable(() => import("./digital/Gacha"));

//커뮤니티
export const Community = loadable(() => import("./community/Community"));
export const CommunityDetail = loadable(
  () => import("./community/CommunityDetail")
);
export const CommunitySubmit = loadable(
  () => import("./community/CommunitySubmit")
);
export const CommunitySubmitV1 = loadable(
    () => import("./community/CommunitySubmitV1")
);
export const Supporters = loadable(() => import("./community/Supporters"));
export const SupportersDetail = loadable(
  () => import("./community/SupportersDetail")
);
export const SupportersSubmit = loadable(
  () => import("./community/SupportersSubmit")
);

export const TIFNotice = loadable(() => import("./community/TIFNotice"));
export const TIFNoticeDetail = loadable(
    () => import("./community/TIFNoticeDetail")
);

//프로필
export const Profile = loadable(() => import("./profile/Profile"));
export const ProfileCard = loadable(() => import("./profile/DigitalCard"));
export const ProfileCardDetail = loadable(
  () => import("./profile/DigitalCardDetail")
);
export const ProfileReview = loadable(() => import("./profile/Review"));

// 마이페이지
export const Mypage = loadable(() => import("./mypage/Mypage"));
export const MyProfile = loadable(() => import("./mypage/Profile"));
export const Badge = loadable(() => import("./mypage/Badge"));
export const Interest = loadable(() => import("./mypage/Interest"));
export const Address = loadable(() => import("./mypage/Address"));
export const Coupon = loadable(() => import("./mypage/Coupon"));
export const Point = loadable(() => import("./mypage/Point"));
export const Money = loadable(() => import("./mypage/Money"));
export const Like = loadable(() => import("./mypage/Like"));
export const Digital = loadable(() => import("./mypage/Digital"));
export const DigitalDetail = loadable(() => import("./mypage/DigitalDetail"));
export const UserSearch = loadable(() => import("./mypage/UserSearch"));
export const DigitalExchange = loadable(
  () => import("./mypage/DigitalExchange")
);
export const DigitalPayback = loadable(
    () => import("./mypage/DigitalPayback")
);
export const Exchange = loadable(() => import("./mypage/Exchange"));
export const ExchangeReview = loadable(() => import("./mypage/ExchangeReview"));
export const ExchangeReviewEdit = loadable(() => import("./mypage/ExchangeReviewEdit"));
export const ExchangeDetail = loadable(() => import("./mypage/ExchangeDetail"));
export const MyCommunity = loadable(() => import("./mypage/Community"));
export const MyComment = loadable(() => import("./mypage/Comment"));
export const MyReview = loadable(() => import("./mypage/Review"));
export const BadgeGuide = loadable(() => import("./mypage/BadgeGuide"));
export const Charge = loadable(() => import("./mypage/Charge"));
export const Follow = loadable(() => import("./mypage/Follow"));
export const SavePost = loadable(() => import("./mypage/SavePost"));
export const MyApply = loadable(() => import("./mypage/Apply"));
export const MyApplyDetail = loadable(() => import("./mypage/ApplyDetail"));
export const Notice = loadable(() => import("./mypage/Notice"));
export const NoticeDetail = loadable(() => import("./mypage/NoticeDetail"));
export const FAQ = loadable(() => import("./mypage/FAQ"));
export const FAQDetail = loadable(() => import("./mypage/FAQDetail"));
export const Qna = loadable(() => import("./mypage/Qna"));
export const QnaSubmit = loadable(() => import("./mypage/QnaSubmit"));
export const UseNotice = loadable(() => import("./mypage/UseNotice"));
export const NewCard = loadable(() => import("./mypage/NewCard"));
export const Attendance = loadable(() => import("./mypage/Attendance"));
export const GiftReceive = loadable(() => import("./mypage/GiftReceive"));
export const DdpReceive = loadable(() => import("./mypage/DdpReceive"));

// 주문내역
export const Buy = loadable(() => import("./order/Buy"));
export const BuyDetail = loadable(() => import("./order/BuyDetail"));
export const Sale = loadable(() => import("./order/Sale"));
export const SaleDetail = loadable(() => import("./order/SaleDetail"));
export const ReviewSubmit = loadable(() => import("./order/Review"));
export const ReviewEdit = loadable(() => import("./order/ReviewEdit"));
export const SaleEdit = loadable(() => import("./order/sale/Edit"));
export const SalePackEdit = loadable(() => import("./order/sale/PackEdit"));

export const SaleDelivery = loadable(() => import("./order/sale/Delivery"));

//설정
export const Setting = loadable(() => import("./setting/Setting"));
export const Block = loadable(() => import("./setting/Block"));
export const Phone = loadable(() => import("./setting/Phone"));
export const PhoneSeller = loadable(() => import("./setting/PhoneSeller"));
export const Password = loadable(() => import("./setting/Password"));
export const SellerUser = loadable(() => import("./setting/SellerUser"));
export const Withdrawal = loadable(() => import("./setting/Withdrawal"));
export const Account = loadable(() => import("./setting/Account"));
export const Company = loadable(() => import("./setting/Company"));
export const Banner = loadable(() => import("./setting/Banner"));
export const DeliveryFee = loadable(() => import("./setting/DeliveryFee"));
export const Review = loadable(() => import("./setting/Review"));

//알림
export const Alarm = loadable(() => import("./alarm/Alarm"));

//검색
export const Search = loadable(() => import("./search/Search"));
export const SearchResult = loadable(() => import("./search/SearchResult"));

//로그인페이지
export const Root = loadable(() => import("./Root"));

export const MainReady = loadable(() => import("./main/MainReady"));

//회원가입 페이지
export const JoinAgree = loadable(() => import("./user/JoinAgree"));
export const JoinStep1 = loadable(() => import("./user/JoinStep1"));
export const JoinStep2 = loadable(() => import("./user/JoinStep2"));
export const JoinStep3 = loadable(() => import("./user/JoinStep3"));

export const FindId = loadable(() => import("./user/FindId"));
export const FindPw = loadable(() => import("./user/FindPw"));
export const FindPwPhone = loadable(() => import("./user/FindPwPhone"));
export const FindPwResult = loadable(() => import("./user/FindPwResult"));

export const SaleUserChk = loadable(() => import("./user/SaleUserChk"));
export const SaleUser = loadable(() => import("./user/SaleUser"));
export const SaleUser2 = loadable(() => import("./user/SaleUser2"));
export const SaleUserCompany2 = loadable(() => import("./user/SaleUserCompany2"));
export const SaleUserCompany3 = loadable(() => import("./user/SaleUserCompany3"));

export const Cart = loadable(() => import("./cart/Cart"));

export const ProductDetail = loadable(() => import("./detail/ProductDetail"));
export const ProductCommentList = loadable(
  () => import("./detail/ProductCommentList")
);
export const ProductReviewList = loadable(
    () => import("./detail/ProductReviewList")
);
export const ProductRegisterComment = loadable(
  () => import("./detail/ProductRegisterComment")
);

//social login
export const Social = loadable(() => import("./user/Social"));

export const SaleGoods = loadable(() => import("./transaction/SlaeGoods"));
export const SaleDigital = loadable(() => import("./transaction/SaleDigital"));
export const SaleGoodsList = loadable(() => import("./transaction/SaleGoodsList"));
export const SaleGoodsPack = loadable(
  () => import("./transaction/SaleGoodsPack")
);
export const ProductDetailRoute = loadable(
  () => import("./detail/ProductDetailRoute")
);

export const GachaDetailRoute = loadable(
    () => import("./detail/GachaDetailRoute")
);

export const BuyGoods = loadable(() => import("./transaction/BuyGoods"));
export const BuyDigital = loadable(() => import("./transaction/BuyDigital"));
export const BuyGoodsList = loadable(
  () => import("./transaction/BuyGoodsList")
);
export const BuyDigitalList = loadable(
  () => import("./transaction/BuyDigitalList")
);
export const BuyGoodsPack = loadable(
  () => import("./transaction/BuyGoodsPack")
);
export const ChargeResultSuccess = loadable(
    () => import("./payment/result/ChargeResultSuccess")
);


export const DigitalPackageDetail = loadable(
  () => import("./detail/DigitalPackageDetail")
);

export const GoodsPackageDetail = loadable(
  () => import("./detail/GoodsPackageDetail")
);

export const PaymentPage = loadable(() => import("./payment/PaymentRoute"));

export const CheckWaybill = loadable(() => import("./waybill/CheckWaybill"));
export const PaymentExchange = loadable(
  () => import("./payment/PaymentExchange")
);
export const PortonePaymentExchange = loadable(
    () => import("./payment/PortonePaymentExchange")
);
export const PortonePaymentExchangeMobile = loadable(
    () => import("./payment/PortonePaymentExchangeMobile")
);
export const UserShop = loadable(() => import("./usershop/UserShop"));

export const Terms = loadable(() => import("./terms/Terms"))

export const PaymentRequest = loadable(() => import("./payment/PaymentRequest"))