import React, {useState} from "react";
import styled, { css } from "styled-components";
import {useSetRecoilState} from "recoil";
import {pictureDetailModalState} from "../../utils/atom";
import { Swiper, SwiperSlide } from "swiper/react";
import {MainRecommedType} from "../../types/api";
import {ImageType} from "../../types/types";

interface SummaryCardImageProps {
  imageUrl: string;
  size?: "md" | "lg" | "full" | "buyList";
  width?: string;
  height?: string;
  card_stock_images?:{ id: number; image_url: string }[];
}

function SummaryCardImage({
  imageUrl,
  size = "md",
  width,
  height,
  card_stock_images,
}: SummaryCardImageProps) {
  const style = {
    width: width || "",
    height: height || "",
  };

  const setPictureDetailModalState = useSetRecoilState(pictureDetailModalState);

  const [index, setIndex] = useState<number>(0);

  if(card_stock_images){
    return (
        <Wrapper size={size}>
            <CardSwiper
                //@ts-ignore
                slidesPerView={"auto"}
                centeredSlides={true}
                spaceBetween={70}
                onSlideChange={(swiper) => setIndex(swiper.activeIndex)}
            >
                {card_stock_images?.map((item, i ) => (
                <StyledSlide key={"card_stock_image_" + i}>
                  <img style={style} src={item.image_url} alt="card_image"
                       onClick={() => {
                         setPictureDetailModalState({
                           start: 1,
                           picture: card_stock_images || [],
                           disabledEncoding: true,
                           onClick: (index) => {
                           },
                         });
                       }}
                  />
                </StyledSlide>
                ))}
                {card_stock_images.length > 1 && (
                    <Paging>
                        {index + 1}/{card_stock_images?.length}
                    </Paging>
                )}
            </CardSwiper>
        </Wrapper>
    );
  } else {
    return (
        <Wrapper size={size}>
          <img style={style} src={imageUrl} alt="card_image"
          />
        </Wrapper>
    );
  }
}

const Wrapper = styled.div<{ size: "md" | "lg" | "full" | "buyList"}>`
  flex-shrink: 0;

  border-radius: 14px;
  background: rgba(243, 243, 243, 1);

  ${(props) =>
      props.size === "md" &&
      css`
        width: 86px;
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      width: 108px;
    `}

  ${(props) =>
    props.size === "full" &&
    css`
      width: 100%;
    `}

  ${(props) =>
      props.size === "buyList" &&
      css`
      width: 86px;
      height: 86px;  
    `}
  
  aspect-ratio: 1;

  overflow-y: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  & img {
    width: 50%;
    height: 60%;
    object-fit: contain;
  }
`;

const CardSwiper = styled(Swiper)``;

const StyledSlide = styled(SwiperSlide)`
    //overflow-y: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
    & img {
        width: 50%;
        height: 60%;
        object-fit: contain;
    }
`;

const Paging = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
  z-index: 1;
  padding: 4px 8px;
  border-radius: 33px;
  background: rgba(0, 0, 0, 0.4);

  font-weight: 500;
  font-size: 15px;
  line-height: 1;
  color: ${(props) => props.theme.color.white};
`;

export default SummaryCardImage;
