import React, {useEffect, useMemo, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { orderMutation, orderSaveMutation } from "../../apis/payment";
import FixedButton from "../../components/common/FixedButton";
import PaymentCardStock from "../../components/payment/PaymentCardStock";
import PaymentTerms from "../../components/payment/PaymentTerms";
import RegisterAddress from "../../components/payment/RegisterAddress";
import RegisterPoint from "../../components/payment/RegisterPoint";
import SelectPaymentMethod from "../../components/payment/SelectPaymentMethod";
import TotalPaymentPrice from "../../components/payment/TotalPaymentPrice";
import usePoint from "../../hooks/usePoint";
import { usePaymentValue } from "../../providers/PaymentProvider";
import {
  paymentMoneyState,
  paymentPayloadState,
  paymentPointState,
  useCouponState,
} from "../../recoil/atoms/payment";
import { api } from "../../utils/api";
import { alertModalState } from "../../utils/atom";
import { convertCouponIds, getThousandCommaPrice } from "../../utils/utils";
import RegisterMoney from "../../components/payment/RegisterMoney";
import useMoney from "../../hooks/useMoney";
import LoadingComponent from "../../components/common/LoadingComponent";

interface GoodsContainerProps {}

function GoodsContainer({}: GoodsContainerProps) {
  const { payment, totalPaymentPrice, totalCouponPrice } = usePaymentValue();
  const { data } = usePoint();
  const { data : money } = useMoney();

  const payload = useRecoilValue(paymentPayloadState);
  const resetPayload = useResetRecoilState(paymentPayloadState);
  const useCoupon = useRecoilValue(useCouponState);
  const paymentPoint = useRecoilValue(paymentPointState);
  const paymentMoney = useRecoilValue(paymentMoneyState);

  const navigate = useNavigate();
  const setAlertModal = useSetRecoilState(alertModalState);

  const [payLoading, setPayLoading] = useState<boolean>(false);

  const state = JSON.parse(localStorage.getItem("state") || "{}");

  const isValid = useMemo(() => {
    if (!payload?.terms?.cancelTerm) return false;
    if (!payload?.terms?.purchaseTerm) return false;
    if (totalPaymentPrice !== 0 && !payload.method) return false;
    if (payload?.method === "VBANK" && !payload.refundInfo) return false;
    if (
      !payload.deliveryInfo?.address ||
      !payload.deliveryInfo?.name ||
      !payload.deliveryInfo?.phone
    ) {
      return false;
    }

    return true;
  }, [payload, totalPaymentPrice]);

  const handleSubmit = async () => {
    setPayLoading(true);
    if (payload.method === "CARD" && totalPaymentPrice > 0 && totalPaymentPrice < 100) {
      setAlertModal({
        title: "결제금액 미달",
        text: "카드 결제 시 100원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    if (payload.method === "TRANS" && totalPaymentPrice > 0 && totalPaymentPrice < 150) {
      setAlertModal({
        title: "결제금액 미달",
        text: "실시간 계좌이체 시 150원 미만은 결제가 불가능합니다.",
      });
      setPayLoading(false);
      return;
    }

    let refundInfo;

    if (payload.method === "VBANK") {
      refundInfo = {
        refund_bank: payload.refundInfo?.bank,
        refund_account: payload.refundInfo?.account,
        refund_name: payload.refundInfo?.owner,
      };
    }

    const { data } = await orderSaveMutation({
      card_stock_ids: state.stockIds,
      card_amounts: state.amounts,
      user_coupon_ids: convertCouponIds(useCoupon),
      point: String(paymentPoint),
      money: String(paymentMoney),
      address_name: payload.deliveryInfo?.name,
      address: payload.deliveryInfo?.address,
      address_phone: payload.deliveryInfo?.phone,
      address_request: payload.deliveryInfo?.etc,
      address_detail: payload.deliveryInfo?.detailAddress,
      save_address: payload.deliveryInfo?.isSave ? 1 : 0,
      payment_method: payload?.method as any,
      is_promotion: 0,
      ...(refundInfo && { ...refundInfo }),
    });

    if (!data.success) {
      window.alert(data.alert);
      setPayLoading(false);
      return;
    }

    if (totalPaymentPrice === 0) {
      navigate(`/payment/result?success=true&id=${data?.data?.order_id}`);
      return;
    }

    orderMutation(data?.data?.order_id);
  };

  useEffect(() => {
    return resetPayload;
  }, []);

  if(payLoading) {
    return <LoadingComponent/>
  }

  return (
    <Wrapper>
      {payment.card_stocks.map((stock) => (
        <PaymentCardStock cardStock={stock} key={stock.id} isCoupon={true} paymentPrice={totalPaymentPrice} />
      ))}

      <RegisterAddress />
{/*      <RegisterPoint
        myPoint={data?.total_point || 0}
        paymentPrice={totalPaymentPrice}
        couponPrice={totalCouponPrice}
        moneyPrice={paymentMoney}
        deliveryFee={payment?.delivery_fee}
        productPrice={payment?.product_price}
      />*/}
      <RegisterMoney
          myMoney={money?.total_money || 0}
          pointPrice={paymentPoint}
          paymentPrice={totalPaymentPrice}
          couponPrice={totalCouponPrice}
          deliveryFee={payment?.delivery_fee}
          productPrice={payment?.product_price}
      />
      <TotalPaymentPrice
        couponPrice={totalCouponPrice}
        deliveryFee={payment.delivery_fee ?? 0}
        paymentPrice={totalPaymentPrice}
        point={paymentPoint}
        money={paymentMoney}
        totalPrice={payment.product_price ?? 0}
      />
      {totalPaymentPrice !== 0 && <SelectPaymentMethod />}
      <PaymentTerms />
      <FixedButton onClick={handleSubmit} disabled={!isValid}>
        {getThousandCommaPrice(totalPaymentPrice)}원 결제
      </FixedButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 0 20px;

  padding-bottom: 90px;
`;

export default GoodsContainer;
