import React from "react";
import styled from "styled-components";
import { getThousandCommaPrice } from "../../utils/utils";

interface CardPriceProps {
  price: number;
  className?: string;
}

function CardPrice({ price, className }: CardPriceProps) {
  return (
    <Wrapper className={className ? className : ""}>
      <label className="label">판매가</label>
      <h4 className="price">{getThousandCommaPrice(price)}원</h4>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  & .label {
    font-family: Noto Sans KR;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(172, 172, 172, 1);
  }

  & .price {
    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }
`;

export default CardPrice;
