import React from "react";
import styled from "styled-components";
import Title from "../../components/common/Title";
import MainCardSwiper from "../../components/swiper/MainCardSwiper";
import { useProductDetail } from "../../providers/ProductDetailProvider";

function ProductRelationContainer() {
  const data = useProductDetail();

  const length = (data?.relation_cards || []).length;

  if (length === 0) return null;

  return (
    <Wrapper>
      <Title title="관련 상품" />
      <div className="cards-wrapper">
        <MainCardSwiper
          item={(data?.relation_cards || []) as any}
          keyValue=""
        />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 40px;

  & .cards-wrapper {
    margin-top: 24px;
  }
`;

export default ProductRelationContainer;
