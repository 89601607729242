import React, { useMemo } from "react";
import styled from "styled-components";
import PageLoadingComponent from "../../../components/common/PageLoadingComponent";
import ChargeResultContainer from "../../../containers/result/ChargeResultContainer";

ChargeResultContainer
import useCharge from "../../../hooks/useCharge";
interface Props {
  chargeId: string;
}

function ChargeResultSuccess({ chargeId }: Props) {
  //let chargeData, isChargeLoading;
  const { data , isLoading } = useCharge(chargeId);

  if (isLoading) return <PageLoadingComponent />;
  if (!data) return <div>{data?.alert || "ERROR"}</div>;

  return (
      <Wrapper>
        <ChargeResultContainer chargeId={chargeId} />
      </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: scroll;
`;

export default ChargeResultSuccess;
