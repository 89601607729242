import React, { useEffect, useRef, useState } from "react";
import BackWrapper from "../../components/wrapper/BackWrapper";
import styled from "styled-components";
import  Guide  from "../../assets/images/badge-guide-detail_4.png";
const BadgeGuide = () => {

    return (
        <BackWrapper title="등급 상세보기">
            <Wrapper>
            <img src={Guide}/>
            </Wrapper>
        </BackWrapper>
    );
};

export default BadgeGuide;

const Wrapper = styled.div`
    padding: 0 20px 20px 20px;
    height: calc(100% - 69px);
    & > img{
        width: 100%;
    }
`;