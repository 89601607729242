import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Title from "../../components/common/Title";
import SelectTab from "../../components/detail/SelectTab";
import { useParams } from "react-router-dom";
import { convertListVariables } from "../../utils/utils";
import { CARD_TYPE } from "../../utils/variables";
import useSWR from "swr";
import ProductRecentDeal from "../../components/detail/ProductRecentDeal";
import { useSetRecoilState } from "recoil";
import { recentDealModalState } from "../../recoil/atoms/modal";

function ProductRecentContainer() {
  const { cardId } = useParams();
  const [selectedType, setSelectedType] = useState("all");
  const setRecentDealModal = useSetRecoilState(recentDealModalState);
  const { data, isLoading } = useSWR(
    `/card/recent/deal?card_id=${cardId}&is_detail=1${
      selectedType === "all" ? "" : `&type=${selectedType}`
    }`
  );

  const isMoreView = useMemo(() => {
    const length = data?.orders?.data?.length || 0;
    if (length < 6) return false;
    return true;
  }, [data?.orders]);

  const orderList = useMemo(
    () => (data?.orders?.data || []).slice(0, 5),
    [data?.orders]
  );

  const handleChangeType = (type: string) => {
    setSelectedType(type);
  };

  return (
    <Wrapper>
      <Title
        title="최근 거래"
        arrow={
          isMoreView
            ? {
                onClick: () => {
                  setRecentDealModal(true);
                },
              }
            : undefined
        }
      />

      <SelectTab
        selectedValue={selectedType}
        list={convertListVariables(CARD_TYPE)}
        onChange={handleChangeType}
      />

      <ProductRecentDeal products={orderList} isLoading={isLoading} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 40px;
`;

export default ProductRecentContainer;
