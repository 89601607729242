import React from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { paymentPayloadState } from "../../recoil/atoms/payment";
import TermsComponent from "../common/TermsComponent";

interface PaymentTermsProps {}

function PaymentTerms({}: PaymentTermsProps) {
  const [payload, setPayload] = useRecoilState(paymentPayloadState);

  const handleChangeChecked = (key: string) => (checked: boolean) => {
    setPayload((old) => ({
      ...old,
      terms: {
        ...old.terms,
        [key]: checked,
      },
    }));
  };

  return (
    <Wrapper>
      <TermsComponent
        checked={!!payload.terms?.cancelTerm}
        onChange={handleChangeChecked("cancelTerm")}
        title="결제 후 단순 변심이나 실수에 의한 취소가 불가함에 동의합니다."
      />

      <TermsComponent
        checked={!!payload.terms?.purchaseTerm}
        onChange={handleChangeChecked("purchaseTerm")}
        text="구매 및 결제 약관"
        title="구매 약관에 동의합니다."
        isDetailView
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 36px;
  & .term-text {
  }
`;

export default PaymentTerms;
