import React from "react";
import styled from "styled-components";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as Menu1 } from "../../assets/icons/menu1-icon.svg";
import { ReactComponent as MenuActive1 } from "../../assets/icons/menu1-active-icon.svg";
import { ReactComponent as Menu2 } from "../../assets/icons/menu2-icon.svg";
import { ReactComponent as MenuActive2 } from "../../assets/icons/menu2-active-icon.svg";
import { ReactComponent as Menu3 } from "../../assets/icons/menu3-icon.svg";
import { ReactComponent as MenuActive3 } from "../../assets/icons/menu3-active-icon.svg";
import { ReactComponent as Menu4 } from "../../assets/icons/menu4-icon.svg";
import { ReactComponent as MenuActive4 } from "../../assets/icons/menu4-active-icon.svg";
import { ReactComponent as Menu5 } from "../../assets/icons/menu5-icon.svg";
import { ReactComponent as MenuActive5 } from "../../assets/icons/menu5-active-icon.svg";
import NavIcon from "../common/NavIcon";

const Menu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (link: string) => () => {
    const href = window.location.href;
    navigate(link);
    if(href.includes("tab")){
      window.location.reload();
    }
  };

  const menu = [
    {
      title: "HOME",
      link: "/main",
      icon: Menu1,
      iconActive: MenuActive1,
      onClick: handleClick("/main"),
    },
    {
      title: "RANK",
      link: "/rank/coin",
      icon: Menu2,
      iconActive: MenuActive2,
      onClick: handleClick("/rank/coin"),
    },
    {
      title: "SHOP",
      link: "/shop",
      icon: Menu3,
      iconActive: MenuActive3,
      onClick: handleClick("/shop"),
    },
    {
      title: "COMMUNITY",
      link: "/community/community",
      icon: Menu4,
      iconActive: MenuActive4,
      onClick: handleClick("/community/community"),
    },
    {
      title: "MY",
      link: "/mypage",
      icon: Menu5,
      iconActive: MenuActive5,
      onClick: handleClick("/mypage"),
    },
  ];

  return (
    <Wrapper>
      {menu.map((item, i) => (
        <NavIcon
          key={i}
          icon={item.icon}
          iconActive={item.iconActive}
          title={item.title}
          isActive={item.link === location.pathname || (i == 3 && location.pathname.indexOf("community") != -1) || (i == 1 && location.pathname.indexOf("rank") != -1) || (i == 0 && location.pathname == '/')}
          onClick={item.onClick}
        />
      ))}
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1;
`;
