import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { giftAllModalState } from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";

import { ReactComponent as Coupon } from "../../assets/icons/giftAlert-coupon-icon.svg";
import { ReactComponent as Product } from "../../assets/icons/giftAlert-product-icon.svg";
import AlarmList from "../list/AlarmList";
import GiftList from "../list/GiftList";
import { getThousandCommaPrice } from "../../utils/utils";
import Divide from "../common/Divide";

const GiftAllModal = () => {
    const [modal, setModal] = useRecoilState(giftAllModalState);

    const wrapperRef = useRef(null);

    useEffect(() => {
        if (wrapperRef.current !== null) {
            if (modal?.item.type === "product")
                setTimeout(() => {
                    //@ts-ignore
                    wrapperRef.current.style.bottom = "0px";
                });
        }
    }, [wrapperRef, modal]);

    const handleClose = () => {
        if (modal?.item.type === "product") {
            //@ts-ignore
            wrapperRef.current.style.bottom = "-100%";

            setTimeout(() => {
                setModal(null);
            }, 200);
        } else {
            setModal(null);
        }
    };

    const [origin, setOrigin] = useState<string>("");

    useEffect(() => {
        if (modal) {
            setOrigin(location.pathname);
        }
    }, [modal]);

    useEffect(() => {
        if (origin !== "" && location.pathname !== origin) {
            handleClose();
        }
    }, [location.pathname]);

    if (modal === null) return null;
    return (
        <ModalWrapper
            position={modal?.item.type === "product" ? "bottom" : null}
            onClose={handleClose}
        >

            <Wrapper
                ref={wrapperRef}
                style={modal?.card && modal?.card?.length > 0 ? { height:"70%"} :{ height:"30%"}}
            >
                <div style={{overflowY: "scroll", height: "calc(100% - 60px)",
                    alignItems: "center", justifyContent: "center", display: "grid"
                }}>
                    <CardBox1>
                        {/*<Product />*/}

{/*                        {modal?.item?.coupon_cnt > 0  &&(
                            <>
                                <CouponBox>
                                    <Coupon />
                                    <h1> 쿠폰 {modal?.item?.coupon_cnt}장이 지급되었어요!</h1>
                                </CouponBox>
                            </>
                        )
                        }
                        {modal?.item?.point > 0  &&(
                            <>
                                <PointBox>
                                    <h1>{getThousandCommaPrice(modal?.item.point)}코인 <br/> TIF COIN이 지급되었어요!</h1>
                                </PointBox>
                                <Divide/>
                            </>
                        )}*/}

                            <>
                                <PointBox>
                                    {/*<Coupon />*/}
                                    {modal?.item?.coupon_cnt > 0 ?
                                    <h1>쿠폰 {modal?.item?.coupon_cnt}장이 지급되었어요!</h1>
                                        : "-"}
                                    {modal?.item.point > 0 ?
                                        <h1>{getThousandCommaPrice(modal?.item.point)}코인이 지급되었어요!</h1>
                                        :
                                        ""
                                    }
                                </PointBox>
                            </>

                    </CardBox1>
                {modal?.card && modal?.card?.length > 0 && (
                    <>
                    <CardBox>
                            <h1>상품 컬렉션이 추가되었어요!</h1>
                        {modal?.card?.map((item, i) =>
                                <>
                                    <CardImage>
                                        <img src={item.image_url} alt="" />
                                    </CardImage>
                                    <CardInfo>
                                        <p>{item.category_name}</p>
                                        <h1>{item.name}</h1>
                                    </CardInfo>
                                    <Divide/>
                                </>
                        )}
                    </CardBox>
                            </>
                )}
                </div>
                <Button onClick={handleClose}>확인</Button>
            </Wrapper>
        </ModalWrapper>
    );
};

export default GiftAllModal;

const CardInfo = styled.div`
  margin-bottom: 25px;
  text-align: center;

  & > p {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 6px;
    color: ${(props) => props.theme.color.gray5};
  }

  & > h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.37;
    color: ${(props) => props.theme.color.gray2};
  }
`;

const CardImage = styled.div`
    width: 242px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 18px auto;

    & > img {
        width: 200px;
        height: 200px;
        object-fit: contain;
    }
`;

const CardBox = styled.div`
    //display: flex;
    align-items: center;
    //overflow-y: scroll;
    justify-content: center;
    //padding: 20px 0;
    margin-top: 12px;
  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};
    //margin-top: 10px;
    text-align: center;
  }
`;

const CardBox1 = styled.div`
    display: flex;
    align-items: center;
    //overflow-y: scroll;
    justify-content: center;
    padding: 20px 0;
  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};
    //margin-top: 10px;
    text-align: center;
  }
`;

const PointBox = styled.div`
  text-align: center;
    align-items: center;
    //overflow-y: scroll;
    justify-content: center;
  & > h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.45;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};

    &:nth-of-type(n + 2) {
      margin: 20px 0 0 0;
    }
  }
`;

const CouponBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;  
    & > h1 {
    padding: 20px 0;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.45;
    letter-spacing: -0.6px;
    color: ${(props) => props.theme.color.gray1};
  }
    & > svg{
    }
`;

const Wrapper = styled.div`
  max-width: calc(100% - 80px);
  width: 100%;
  //padding: 30px 20px 20px;
  padding: 10px 20px 0 ;
  background: #ffffff;
  border-radius: 20px;
 // height: 70%;
    &.product {
    position: relative;
    max-width: 100%;
    border-radius: 20px 20px 0 0;
    bottom: -100%;
    padding: 38px 20px 15px;
    transition: all 0.3s;
  }
    
`;
