import React, { useEffect } from "react";
import ResultFail from "./ResultFail";
import Button from "../../../components/elements/Button";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {api} from "../../../utils/api";

function PaymentConfirm() {
    const navigate = useNavigate();
    const param = new URLSearchParams(window.location.search);
    const type= param.get("type");
    const m_uid= param.get("m_uid");

    const handleClick = async () => {
/*        if(m_uid && type){
            const formData = new FormData();
            formData.append("merchant_uid", m_uid);

            const {
                data: {success, alert, data},
            } = await api.post("/portone/exchange/payment/complete", formData);

            if (success) {
            } else {
            }
        }*/
    };


    return(
        <Wrapper>
            <Content className="ql-snow">
                <Button onClick={() => {handleClick()}} style={{width:"100%"}}>
                    결제 완료
                </Button>
            </Content>
        </Wrapper>
    )
}

export default PaymentConfirm;

const Wrapper = styled.div`
    display: flex;
    height: 100%;
`;

const Content = styled.div`
    width: calc(100% - 100px);
    margin: auto;
    align-items: center;
`;
