import React from "react";
import styled from "styled-components";
import { ProductCardType } from "../../types/product";

interface ProductInfoProps {
  info: ProductCardType;
}

function ProductInfo({ info }: ProductInfoProps) {
  return (
    <Wrapper>
      <p className="category-name">{info.category_name}</p>
      <h4 className="name">{info.name}</h4>
      <p className="sub-name">{info.sub_name}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;

  & .category-name {
    color: rgba(93, 93, 93, 1);
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }

  & .name {
    margin-top: 10px;

    font-family: Noto Sans KR;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(30, 30, 30, 1);
  }

  & .sub-name {
    margin-top: 4px;

    font-family: Noto Sans KR;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(93, 93, 93, 1);
  }
`;

export default ProductInfo;
